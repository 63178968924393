import cx from 'classnames';
import React from 'react';

import { getClickAccessibilityProps } from '../../../legacy/organisms/TopNav/util';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './NavMenuItem.module.scss';
import usePresenter from './NavMenuItem.presenter';
import getProps from './NavMenuItem.props';
import { NavMenuItemCombinedProps } from './types';

const NavMenuItem: React.FC<NavMenuItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    divider,
    backButton,
    title,
    divider1,
    nextButton,
    onClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`navMenuItem${variantName}`];

  const accessibilityProps = getClickAccessibilityProps({ onClick }, true);

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Back': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          {/* MANUAL OVERRIDE - added accessibility props */}
          <div className={cx(styles.content, classes?.content)} onClick={onClick} {...accessibilityProps}>
            <Button
              className={cx(styles.backButton, classes?.backButton)}
              {...internalProps.backButton}
              {...backButton} />
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
          </div>
        </div>
      );
      break;
    }
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          {/* MANUAL OVERRIDE - added accessibility props */}
          <div className={cx(styles.content, classes?.content)} onClick={onClick} {...accessibilityProps}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1} />
        </div>
      );
      break;
    }
    case 'Next': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          {/* MANUAL OVERRIDE - added accessibility props */}
          <div className={cx(styles.content, classes?.content)} onClick={onClick} {...accessibilityProps}>
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
            <Button
              className={cx(styles.nextButton, classes?.nextButton)}
              {...internalProps.nextButton}
              {...nextButton} />
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NavMenuItem;
