import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { typedDeepMerge } from '../../../lib/objectUtils';
import { useWindowSize } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';
import { AuthContext } from '../../../modules/auth';
import { TopNavContext } from '../../../modules/topNav';
import type { AccountMenuBlockCombinedProps } from '../../blocks/AccountMenuBlock/types';
import { AccountCards } from '../AccountCards';
import styles from './AccountMenu.module.scss';
import type { AccountMenuCombinedProps } from './types';

export enum UserStatus {
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
}

const usePresenter = (props: AccountMenuCombinedProps): AccountMenuCombinedProps => {
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const { setAccountMenuDropdownState, accountMenuDropdownState } = props;
  const { t } = useTranslation();

  const { account } = useContext(AuthContext);

  const { trackEvent } = useAnalyticsManager();

  const { activeTopNavMenuType, setActiveTopNavMenuType } = useContext(TopNavContext);
  const isAccountMenuActive: boolean = useMemo(() => activeTopNavMenuType === 'AccountMenu', [activeTopNavMenuType]);

  const handleDropdownToggle = useCallback((params: { isOpen: boolean; clickText?: string; }) => {
    const { isOpen, clickText } = params;

    const processedClickText: string | undefined = clickText || (isOpen ? t('topnav.expand') : t('topnav.collapse'));

    trackSelectContentEvent(
      trackEvent,
      'Header',
      'AccountMenu',
      processedClickText,
    );

    setAccountMenuDropdownState?.(isOpen ? UserStatus.Expanded : UserStatus.Collapsed);
    setActiveTopNavMenuType(isOpen ? 'AccountMenu' : undefined);
  }, [t, setAccountMenuDropdownState, setActiveTopNavMenuType, trackEvent]);

  const accountMenuBlock: AccountMenuBlockCombinedProps = {
    isModalOpen: isAccountMenuActive && !isDesktop,
    accountMenu: {
      state: isAccountMenuActive ? 'Expanded' : 'Collapsed',
      accountMenuDropdownState,
      setAccountMenuDropdownState,
      closeButton: {
        onClick: () => handleDropdownToggle({ isOpen: false, clickText: t('topnav.close') }),
        classes: {
          icon: styles.menuButtonIcon,
        },
        ariaLabel: t('topnav.close'),
      },
    },
  };

  const isDropdownOpen: boolean = isAccountMenuActive && isDesktop;

  return {
    ...typedDeepMerge(props, {
      state: isAccountMenuActive ? 'Expanded' : 'Collapsed',
      greetingFirstName: {
        value: `${t('topnav.welcome', { accountFirstName: account?.first_name })}`,
      },
      expandButton: {
        onClick: () => handleDropdownToggle({ isOpen: true }),
        classes: { icon: styles.menuButtonIcon },
        ariaLabel: t('topnav.expandAriaLabel'),
      },
      collapseButton: {
        icon: { asset: accountMenuDropdownState === 'Expanded' ? 'ChevronUp' : 'ChevronDown' },
        onClick: () => handleDropdownToggle({ isOpen: false }),
        classes: { icon: styles.menuButtonIcon },
        ariaLabel: t('topnav.collapseAriaLabel'),
      },
      closeButton: {
        onClick: () => handleDropdownToggle({ isOpen: false, clickText: t('topnav.close') }),
        classes: { icon: styles.menuButtonIcon },
        ariaLabel: t('topnav.closeAriaLabel'),
      },
      accountMenuBlock: accountMenuBlock,
      handleToggle: (isOpen: boolean) => {
        // Handle dropdown toggle only on desktops
        if (isDesktop) {
          handleDropdownToggle({ isOpen });
        }
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        // Handle dropdown toggle only on desktops
        if (isDesktop && ['Enter', 'Space'].includes(event.code)) {
          event.preventDefault();
          handleDropdownToggle({ isOpen: !isAccountMenuActive });
        }
      },
      myAccount: {
        title: {
          value: t('topnav.accountMenu.myAccount'),
          colour: 'SubduedDark',
        },
        type: 'Collapsed',
        dividerPosition: 'Bottom',
        expansionProps: {
          direction: 'down',
          // eslint-disable-next-line react/react-in-jsx-scope
          expandedContent: <AccountCards />,
        },
      },
      myTickets: {
        title: {
          value: t('topnav.accountMenu.myTickets'),
          colour: 'SubduedDark',
        },
        type: 'Default',
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'AccountMenu',
            'MenuItem',
            t('topnav.accountMenu.myTickets'),
          );
          navigate('/mytickets');
        },
      },
      logOut: {
        title: {
          value: t('topnav.accountMenu.logOut'),
        },
        type: 'Critical',
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'AccountMenu',
            'MenuItem',
            t('topnav.accountMenu.logOut'),
          );
          navigate('/logout');
        },
      },
      isDropdownOpen,
      dropdownToggleAriaLabel: isDropdownOpen ? t('topnav.collapseAriaLabel') : t('topnav.expandAriaLabel'),
    }),
  };
};

export default usePresenter;
