import { useTranslation } from 'react-i18next';
import { URLs } from '../../../lib/constants';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { EXCLUSIVE_ID } from '../../../modules/navigation/Navigation.constants';
import type { ButtonCombinedProps } from '../../atoms/Button';
import type { SubmenuCombinedProps } from './types';

const usePresenter = (props: SubmenuCombinedProps): SubmenuCombinedProps => {
  const { data, onItemClicked } = props;
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  const exclusiveObject = data?.find(
    (exclusive) => exclusive.id === EXCLUSIVE_ID,
  );

  const exclusiveItems: ButtonCombinedProps[] | undefined =
    exclusiveObject?.performers?.map((event): ButtonCombinedProps => {
      const exclusivesCheck = event.slug === URLs.ExclusiveEventsPage;
      return {
        type: 'Text',
        ariaLabel:
          t('submenu.screenReaderSubMenuText', {
            subCategoryName: event.name,
          }) || undefined,
        style: exclusivesCheck ? 'Outline' : 'TextWhite',
        size: 'Small',
        text: {
          size: 'Medium',
          value: event.name,
        },
        newTab: event.url ? true : false,
        href: event.slug || event.url,
        onClick: () => {
          if (event.slug?.startsWith(URLs.ExclusiveEventsPage)) {
            trackEvent('select_content_c1x_navigation-menu');
          }
          onItemClicked?.();
        },
      };
    });

  return {
    submenuList: {
      buttons: exclusiveItems,
    },
  };
};

export default usePresenter;
