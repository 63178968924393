import type { PresetFilterOption } from '../../components/organisms/PresetFilter';
import type { RegionFilterState } from '../../components/organisms/RegionFilter';
import { buildAllEventsPageUrlWithParams, type AllEventsTabKey } from '../../components/pages/AllEventsPage';
import { getPerformerUrl } from '../../lib/performerUtils';
import { CATEGORIES, THEATER_SUB_CATEGORIES } from '../categories';
import i18n from '../locale/i18n';
import type { Event, Performer, TopNavPerformersResponse, Venue } from '../partnership';
import { CATEGORIES_WITH_VIEW_EVENTS_LINK, TOP_NAV_PERFORMERS_DATA } from './Navigation.constants';
import type { CategoryMenuItemState, PerformerMenuItemState, SubCategoryMenuItemState } from './Navigation.types';

export const getItemIfMatch = (
  text: string,
  results: Venue[] | Performer[] | Event[] | undefined,
): Venue | Performer | Event | undefined => {
  type SearchResult = { id: number; name: string; };
  const searchResult: SearchResult[] | undefined = results;
  const lowercaseText = text.toLowerCase();
  const result = searchResult?.find(
    (element) => element.name.toLowerCase() === lowercaseText,
  );
  return result;
};

export const buildViewEventsLink = (params: {
  tabKey: AllEventsTabKey;
  regionFilterState: RegionFilterState | undefined;
  categoryFilterState?: PresetFilterOption;
}): PerformerMenuItemState => {
  return {
    name: i18n.t('topnav.viewEvents'),
    slug: buildAllEventsPageUrlWithParams(params),
    teamCode: '',
    primaryColor: '',
    secondaryColor: '',
    isBoldText: true,
  };
};

/**
 * Adds performers for Comedy sub-category.
 * @returns {CategoryMenuItemState} - Updated category menu item state with added Comedy performers.
 */
const updatePerformersForComedySubCategory = (params: {
  /** Initial category menu item state without performers */
  categoryMenuItemState: CategoryMenuItemState;
  /** API response with all TopNav performers */
  topNavPerformersResponse: TopNavPerformersResponse;
  /** Region filter state for View Events link */
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState => {
  const { categoryMenuItemState, topNavPerformersResponse, regionFilterState } = params;
  const { id: comedySubCategoryId } = categoryMenuItemState;

  const newPerformerMenuItemStates: PerformerMenuItemState[] = [];

  // Check if we need to prepend View Events link
  if (CATEGORIES_WITH_VIEW_EVENTS_LINK[comedySubCategoryId]) {
    newPerformerMenuItemStates.push(buildViewEventsLink({
      tabKey: 'comedy',
      regionFilterState,
    }));
  }

  // Add comedy performers from API response
  const apiComedyPerformers: readonly Performer[] | undefined = topNavPerformersResponse[CATEGORIES.theater]?.[comedySubCategoryId];
  if (apiComedyPerformers?.length) {
    apiComedyPerformers.forEach((apiComedyPerformer: Performer) => {
      newPerformerMenuItemStates.push({
        name: apiComedyPerformer.name,
        slug: getPerformerUrl(apiComedyPerformer.id),
        primaryColor: '',
        secondaryColor: '',
        teamCode: '',
      });
    });
  }

  const newCategoryMenuItemState: CategoryMenuItemState = { ...categoryMenuItemState, performers: newPerformerMenuItemStates };
  return newCategoryMenuItemState;
};

/**
  * Adds performers for non-Comedy sub-category.
  * @returns {CategoryMenuItemState} - Updated category menu item state with added performers.
  */
const updatePerformerForSubCategories = (params: {
  /** Initial category menu item state without performers */
  categoryMenuItemState: CategoryMenuItemState;
  /** API response with all TopNav performers */
  topNavPerformersResponse: TopNavPerformersResponse;
  /** Region filter state for View Events link */
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState => {
  const { categoryMenuItemState, topNavPerformersResponse, regionFilterState } = params;

  const categoryId = Number(categoryMenuItemState.id) as keyof typeof CATEGORIES_WITH_VIEW_EVENTS_LINK;

  const newSubCategoryMenuItemStates: SubCategoryMenuItemState[] = [];

  categoryMenuItemState.subCategories.forEach((subCategoryMenuItemState: SubCategoryMenuItemState) => {
    const { subCategoryId, name: subCategoryName, performers: existingPerformerMenuItemStates } = subCategoryMenuItemState;

    const newPerformerMenuItemStates: PerformerMenuItemState[] = [...existingPerformerMenuItemStates];

    // Check if we need to prepend View Events link
    if (CATEGORIES_WITH_VIEW_EVENTS_LINK[categoryId]) {
      newPerformerMenuItemStates.unshift(buildViewEventsLink({
        tabKey: CATEGORIES_WITH_VIEW_EVENTS_LINK[categoryId],
        regionFilterState,
        categoryFilterState: {
          id: subCategoryId,
          name: subCategoryName,
        },
      }));
    }

    // Add unique sub-category performers from API response
    const apiSubCategoryPerformers: readonly Performer[] | undefined = topNavPerformersResponse[categoryId]?.[subCategoryId];
    if (apiSubCategoryPerformers?.length) {
      apiSubCategoryPerformers.forEach((apiSubCategoryPerformer: Performer) => {
        // Check if performer already exists
        const isExistingPerformer: boolean = newPerformerMenuItemStates.some(({ name }: PerformerMenuItemState) => name === apiSubCategoryPerformer.name);

        // Add performer only if it doesn't exist already
        if (!isExistingPerformer) {
          newPerformerMenuItemStates.push({
            name: apiSubCategoryPerformer.name,
            slug: getPerformerUrl(apiSubCategoryPerformer.id),
            teamCode: '',
            primaryColor: '',
            secondaryColor: '',
          });
        }
      });
    }

    // Add sub-category with updated performers
    newSubCategoryMenuItemStates.push({
      ...subCategoryMenuItemState,
      performers: newPerformerMenuItemStates,
    });
  });

  const newCategoryMenuItemState: CategoryMenuItemState = { ...categoryMenuItemState, subCategories: newSubCategoryMenuItemStates };
  return newCategoryMenuItemState;
};

/**
  * Updates the top navigation performers based on the response from getTopNavPerformers.
  * @returns {CategoryMenuItemState[]}
  */
export const updateCategoryMenuItemStates = (params: {
  topNavPerformersResponse: TopNavPerformersResponse;
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState[] => {
  const { topNavPerformersResponse, regionFilterState } = params;

  // If topNavPerformersResponse is an empty object, return TOP_NAV_PERFORMERS_DATA as static data for categories and sub-categories.
  if (!Object.keys(topNavPerformersResponse).length) {
    return TOP_NAV_PERFORMERS_DATA;
  }

  // Map through each category in TOP_NAV_PERFORMERS_DATA array
  const newCategoryMenuItemStates = TOP_NAV_PERFORMERS_DATA.map((categoryMenuItemState: CategoryMenuItemState) => {
    if (categoryMenuItemState.id === THEATER_SUB_CATEGORIES.comedy.toString()) {
      return updatePerformersForComedySubCategory({ categoryMenuItemState, topNavPerformersResponse, regionFilterState });
    } else {
      return updatePerformerForSubCategories({ categoryMenuItemState, topNavPerformersResponse, regionFilterState });
    }
  });

  return newCategoryMenuItemStates;
};
