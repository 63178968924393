import type { CSSProperties } from 'react';
import { toHtmlId } from '../../../lib/util';
import { getTransform } from '../../../modules/map';
import type { SvgMapSection } from '../../../modules/partnership';
import { FillOverrides } from './SvgMapSections.constants';
import type { SvgMapSectionProps } from './SvgMapSections.types';

// TODO: AK: Add unit tests
/**
 * Gets the array of SVG map section props for rendering on the map
 * @returns {SvgMapSectionProps[]} Array of SVG map section props for rendering on the map
 */
export const getSvgMapSectionProps = (params: {
  svgMapSections: SvgMapSection[];
}): SvgMapSectionProps[] => {
  const { svgMapSections } = params;

  return svgMapSections.map((svgMapSection: SvgMapSection, svgMapSectionIndex: number) => {
    const {
      path,
      fill,
      stroke,
      'fill-opacity': fillOpacity,
      opacity,
      transform,
      id: svgMapSectionId = '',
    } = svgMapSection;

    // Override fill colour for the first 2 SVG map sections with #F5F5F5 colour
    // For all other SVG map sections override fill colours #000000 or #999999 with orange colour
    const fillOverride: string | undefined = svgMapSectionIndex <= 1 ? '#F5F5F5' : FillOverrides[fill?.toLowerCase() ?? ''];

    const propsWithSectionId = !svgMapSectionId
      ? {}
      : {
        id: toHtmlId(svgMapSectionId), // Required for hovering/selection functionality
        'data-testid': svgMapSectionId, // Required for automation tests, do not change accidentally
        'data-for': svgMapSectionId,
      } as const;

    return {
      key: `${svgMapSectionIndex}-${svgMapSectionId}`,
      d: path,
      path,
      fill: fillOverride || fill,
      stroke,
      strokeWidth: 0.125,
      fillOpacity,
      style: {
        opacity: svgMapSectionId ? '1' : opacity,
        pointerEvents: svgMapSectionId ? undefined : 'none',
      } as CSSProperties,
      transform: getTransform(transform),
      'data-tip': true,
      ...propsWithSectionId,
    };
  });
};
