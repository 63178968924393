import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import styles from './HeroBanner.module.scss';
import { usePresenter } from './HeroBanner.presenter';
import type { HeroBannerProps } from './HeroBanner.types';

export const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const {
    titleKey,
    descriptionKey,
    backgroundImageUrl,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div
      className={styles.heroBanner}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={styles.heroBannerContent}>
        <div className={styles.titleContainer}>
          <Text
            type='NewBannerHeading'
            size='Large'
            style='Regular'
            colour='BaseLight'
            value={t(titleKey)}
          />
        </div>
        <Text
          type='NewBannerBody'
          size='Medium'
          style='Regular'
          colour='BaseLight'
          value={t(descriptionKey)}
        />
      </div>
    </div>
  );
};
