import { useNavigate } from 'react-router-dom';
import { SubmenuTabItemCombinedProps } from './types';

const usePresenter = (props: SubmenuTabItemCombinedProps): SubmenuTabItemCombinedProps => {
  const { onItemClicked, linkPath } = props;
  const navigate = useNavigate();
  const clickAction = () => {
    if (linkPath) {
      navigate(linkPath);
    }
    if (onItemClicked) {
      onItemClicked();
    }
  };
  return {
    ...props,
    onItemClicked: clickAction,
  };
};

export default usePresenter;
