import { checkIsEventValid } from '../../../lib/eventUtils';
import type { ListingDetailMetadata } from '../../../lib/types';
import { getFiveDigitsZipCode, getTwoDigitsCountryCode, handleCountryCodeUS, isNumber, parseNumber, round } from '../../../lib/util';
import type { Account } from '../../../modules/auth/types';
import type { CardData, ListingDetailsQueryParams, Order } from '../../../modules/partnership';
import { NON_US_POSTAL_CODE_MAX_LENGTH, REQUIRED_ADDRESS_FIELDS, US_POSTAL_CODE_MAX_LENGTH, type BillingAddress } from '../../organisms/AddressInfo';
import poBoxZipCodesJson from '../../organisms/AddressInfo/InvalidPostalCodes.json';
import { RewardPaymentOptions, type PaymentInfoType, type RewardPaymentOption } from '../../organisms/PaymentInfo';
import { INVALID_DECIMAL_CHARS_REGEX, INVALID_INTEGER_CHARS_REGEX, PHONE_NUMBER_LENGTH, PO_BOX_ZIP_CODES_MAP, Steps } from './CheckoutPage.constants';
import type { CheckoutValidityState, Step } from './CheckoutPage.types';

// TODO: AK: Add unit tests
export const getListingDetailQueryParams = (params: {
  quantity: number | undefined;
  exclusiveListings: 'true' | 'false' | undefined;
  deliveryId: number | undefined;
}): ListingDetailsQueryParams | undefined => {
  const { quantity, exclusiveListings, deliveryId } = params;

  if (!quantity || !exclusiveListings || deliveryId === undefined) {
    return undefined;
  }

  return {
    quantity,
    exclusive_listings: exclusiveListings === 'true',
    delivery_id: deliveryId,
  };
};

// TODO: AK: Add unit tests
export const checkCanFetchListingDetail = (params: {
  account: Account | undefined;
  eventId: number | undefined;
  listingId: string | undefined;
  listingDetailQueryParams: ListingDetailsQueryParams | undefined;
  step: Step | undefined;
}): boolean => {
  const { account, eventId, listingId, listingDetailQueryParams, step } = params;
  return !!account && !!eventId && !!listingId && !!listingDetailQueryParams && !!step;
};

// TODO: AK: Add unit tests
/**
 * Gets checkout validity state:
 * - 'processing' if API requests are in flight.
 * - 'failure' if checkout is invalid, e.g. required data is missing or there are API errors.
 * - 'success' if checkout is valid, i.e. all required data has been successfully loaded and validated.
 * @returns {CheckoutValidityState} Checkout validity state (processing, failure or success)
 */
export const getCheckoutValidityState = (params: {
  isAccountLoading: boolean | undefined;
  account: Account | undefined;
  canFetchListingDetail: boolean;
  isListingDetailLoading: boolean;
  isListingDetailError: boolean;
  listingDetailMetadata: ListingDetailMetadata | undefined;
}): CheckoutValidityState => {
  const {
    isAccountLoading,
    account,
    canFetchListingDetail,
    isListingDetailLoading,
    isListingDetailError,
    listingDetailMetadata,
  } = params;

  // Firstly, we need to fetch user account
  // Wait while it is being fetched
  if (isAccountLoading) {
    return 'processing';
  }

  // If we do not have user account at this point then it is an error
  // Also return false if required parameters for fetching listing detail are invalid, e.g. eventId, listingId, quantity, etc.
  if (!account || !canFetchListingDetail) {
    return 'failure';
  }

  // Wait while listing detail is being fetched
  if (isListingDetailLoading) {
    return 'processing';
  }

  // In case of API error return false
  if (isListingDetailError || !listingDetailMetadata) {
    return 'failure';
  }

  // Finally, check if the event is valid, e.g. if it has all required tags
  const { eventMetadata } = listingDetailMetadata;
  const isEventValid: boolean = checkIsEventValid({
    eventId: eventMetadata.id,
    isEventError: false,
    eventMetadata,
    account,
  });

  return isEventValid ? 'success' : 'failure';
};

/**
 * Checks if the right content (Total Charge and Listing Detail Info sections) is shown.
 * True on desktops for any step or on mobiles for 'Payment info' step only.
 */
export const checkIsRightContentShown = (params: {
  /** True on desktops */
  isDesktop: boolean;
  /** Validated 1-based step index. Undefined if step index is invalid, e.g. when it is non-numeric or it is not in range of 1-4. */
  step: Step | undefined;
}): boolean => {
  const { isDesktop, step } = params;
  return isDesktop || (!!step && step === Steps.PaymentInfo);
};

// TODO: ND: Add unit tests
/**
 * Validates whether a given phone number string is valid.
 *
 * A valid phone number must:
 * - Be exactly 10 digits long.
 * - Contain only numeric characters (0-9).
 * @returns {boolean} - Returns `true` if the phone number is valid, `false` otherwise.
 *
 * @example
 * // Returns true
 * checkIsPhoneNumberValid('1234567890');
 *
 * @example
 * // Returns false (contains non-numeric characters)
 * checkIsPhoneNumberValid('123-456-7890');
 *
 * @example
 * // Returns false (incorrect length)
 * checkIsPhoneNumberValid('123456789');
 */
export const checkIsPhoneNumberValid = (phoneNumber: string): boolean => {
  // Check phone number has the exact required length
  // Check phone number contains only digits (0-9)
  return new RegExp(`^\\d{${PHONE_NUMBER_LENGTH}}$`).test(phoneNumber);
};

/**
 * Formats a given phone number string by removing any non-numeric characters
 * and formatting it as XXX-XXX-XXXX if it has exactly 10 digits.
 *
 * @param {string} phoneNumber - The phone number to format, which may contain non-numeric characters.
 * @returns {string} - The formatted phone number as XXX-XXX-XXXX if it contains 10 digits,
 *                     or the cleaned-up numeric string if it doesn't have exactly 10 digits.
 *
 * @example
 * // Returns '123-456-7890'
 * getFormattedPhoneNumber('(123) 456-7890');
 *
 * @example
 * // Returns '123456789'
 * getFormattedPhoneNumber('123.456.789');
 */
export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  // Remove any non-numeric characters from the input
  const formattedPhoneNumber: string = phoneNumber.replace(INVALID_INTEGER_CHARS_REGEX, '');

  // A valid phone number must be exactly 10 digits long
  if (formattedPhoneNumber.length === PHONE_NUMBER_LENGTH) {
    // Format phone number as XXX-XXX-XXXX
    return `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6)}`;
  }

  return formattedPhoneNumber;
};

// TODO: ND: Add unit tests
/**
 * Gets the maximum number of reward units that can be used.
 * It is a minimum of rewardUnitsTotal and totalPriceInRewardUnits.
 */
export const getMaxRewardUnitsToBeUsed = (params: {
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Validated total number of available reward units, e.g. 123456. 0 if the value is not a number. */
  rewardUnitsTotal: number;
  /** Total price in reward units */
  totalPriceInRewardUnits: number;
}): number => {
  const { isPayWithRewardsOnly, rewardUnitsTotal, totalPriceInRewardUnits } = params;
  return isPayWithRewardsOnly ? totalPriceInRewardUnits : Math.min(rewardUnitsTotal, totalPriceInRewardUnits);
};

// TODO: AK: Add unit tests
/**
 * Validates user input value for edited reward units to apply, e.g. '1,234.567':
 * - Returns 0 if user input is invalid or a negative number has been entered.
 * - Limits the max value to the max number of reward units that user can use.
 * - Rounds the value and removes decimal places if units must be integer, e.g. miles or points.
 */
export const validateEditedRewardUnitsToApply = (params: {
  /**
   * Current string representation of edited reward units to apply, e.g. '1,234.567'.
   * This value is edited by user.
   */
  editedRewardUnitsToApplyStr: string;
  /** True only for miles and points units */
  isIntegerRewardUnit: boolean;
  /** Maximum number of reward units that can be used */
  maxRewardUnitsToBeUsed: number;
}): number => {
  const { editedRewardUnitsToApplyStr, isIntegerRewardUnit, maxRewardUnitsToBeUsed } = params;

  // Return 0 for empty values
  if (!editedRewardUnitsToApplyStr.trim()) {
    return 0;
  }

  let newEditedRewardUnitsToApply: number | undefined = parseNumber(
    editedRewardUnitsToApplyStr.replace(INVALID_DECIMAL_CHARS_REGEX, ''),
    { allowDecimals: true },
  );

  // Value may be undefined, e.g. if it exceeds max integer 2147483647
  if (!isNumber(newEditedRewardUnitsToApply)) {
    return maxRewardUnitsToBeUsed;
  }

  if (newEditedRewardUnitsToApply < 0) {
    return 0;
  }

  newEditedRewardUnitsToApply = round({ amount: newEditedRewardUnitsToApply, rounding: 'up', isInteger: isIntegerRewardUnit });
  newEditedRewardUnitsToApply = Math.min(maxRewardUnitsToBeUsed, newEditedRewardUnitsToApply);
  return newEditedRewardUnitsToApply;
};

// TODO: AK: Add unit tests
/** Get payment info type that determines what components to render */
export const getPaymentInfoType = (params: {
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Validated total number of available reward units, e.g. 123456. 0 if the value is not a number. */
  rewardUnitsTotal: number;
  /** Reward payment option: 'ApplyRewards' or 'DoNotApplyRewards' */
  rewardPaymentOption: RewardPaymentOption | undefined;
  /** Cash value of applied reward units, e.g. 60.00. Reset to 0 if 'DoNotApplyRewards' option is selected. */
  cashValueOfAppliedRewardUnits: number;
  /** Total price in cash */
  totalPriceInCash: number;
}): PaymentInfoType => {
  const {
    isPayWithRewardsOnly,
    rewardUnitsTotal,
    rewardPaymentOption,
    cashValueOfAppliedRewardUnits,
    totalPriceInCash,
  } = params;

  if (isPayWithRewardsOnly) {
    // Indicates whether we need to skip reward unit selection and show "Heads up. No need for your card number" component.
    // True if event only allows payment with rewards.
    return 'payWithRewardsOnly';
  }

  // Show only credit card information form if there are no reward units
  if (!rewardUnitsTotal) {
    return 'creditCardOnly';
  }

  // Show reward selection only if user has not selected reward payment option.
  // Or user applied enough reward units to pay for the tickets.
  if (!rewardPaymentOption || (rewardPaymentOption === RewardPaymentOptions.ApplyRewards && cashValueOfAppliedRewardUnits >= totalPriceInCash)) {
    return 'rewardSelectionOnly';
  }

  // In all other cases show reward selection and credit card info
  return 'rewardSelectionAndCreditCard';
};

// TODO: AK: Add unit tests
/** Checks if value of the provided shipping address field is valid */
export const checkIsShippingAddressFieldValid = (params: {
  fieldName: keyof BillingAddress;
  shippingAddress: BillingAddress;
}): boolean => {
  const { fieldName, shippingAddress } = params;

  const value: string | null | undefined = shippingAddress[fieldName];

  // If required field is blank then return false
  if (REQUIRED_ADDRESS_FIELDS.includes(fieldName as typeof REQUIRED_ADDRESS_FIELDS[number]) && !value?.trim()) {
    return false;
  }

  // Special validation for some fields
  switch (fieldName) {
    case 'phoneNumber': return checkIsPhoneNumberValid(value);
    case 'postalCode': {
      const validPostalCodeLength: number = handleCountryCodeUS(shippingAddress.country)
        ? US_POSTAL_CODE_MAX_LENGTH
        : NON_US_POSTAL_CODE_MAX_LENGTH;

      return value.length === validPostalCodeLength;
    }
    default: return true;
  }
};

/** Builds a map of PO box zip codes where key is a PO box zip code and value is always true */
export const buildPOBoxZipCodesMap = (): Record<string, true> => {
  const poBoxZipCodes: string[] = JSON.parse(JSON.stringify(poBoxZipCodesJson));
  return poBoxZipCodes.reduce((acc, poBoxZipCode: string) => {
    acc[poBoxZipCode] = true;
    return acc;
  }, {});
};

export const checkIsPOBoxZipCode = (zipCode: string): boolean => {
  return !!PO_BOX_ZIP_CODES_MAP[zipCode];
};

// TODO: AK: Add unit tests
export const buildOrderRequest = (params: {
  account: Account;
  listingDetailMetadata: ListingDetailMetadata | undefined;
  editedPhoneNumber: string;
  creditCardData: CardData | null;
  appliedRewardUnits: number;
  shippingAddress: BillingAddress | undefined;
}): Order => {
  const {
    account,
    listingDetailMetadata,
    editedPhoneNumber,
    creditCardData,
    appliedRewardUnits,
    shippingAddress,
  } = params;

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    email = '',
    phone: accountPhoneNumber = '',
    address: {
      address_line1: addressLine1 = '',
      address_line2: addressLine2,
      address_line3: addressLine3,
      city = '',
      state_code: stateCode = '',
      postal_code: postalCode = '',
      country_code: countryCode = '',
    } = {},
    loyalty_program: {
      loyalty_unit_name: rewardUnitName = '',
      redeem_per_dollar: dollarsPerRewardUnit = 1,
    } = {},
  } = account;

  const { listingMetadata, pricing } = listingDetailMetadata ?? {};

  const {
    event_id: eventId = 0,
    id: listingId = '',
    price_per: pricePerTicket = 0,
    hasCp1BrokerId = false,
  } = listingMetadata ?? {};

  const {
    id: quoteId = '',
    quantity = 0,
    total: priceTotal = 0,
    currency = 'USD',
    delivery: {
      id: deliveryId = 0,
    } = {},
  } = pricing ?? {};

  const { payload: creditCardDataPayload, ioBlackBox } = creditCardData ?? {};

  let newAppliedRewardUnits: number = appliedRewardUnits;
  if (newAppliedRewardUnits > 0) {
    // Sometimes there are rounding issues.
    // We need to take the smaller value.
    const totalPriceInRewardUnits: number = round({ amount: priceTotal / dollarsPerRewardUnit, rounding: 'half-up', unitName: rewardUnitName });
    newAppliedRewardUnits = Math.min(appliedRewardUnits, totalPriceInRewardUnits);
  }

  const orderRequest: Order = {
    id: 0,
    account: {
      email_address: email,
      first_name: firstName,
      last_name: lastName,
      marketing_optin: false,
      phone_number: accountPhoneNumber,
    },
    assumed_price_per: pricePerTicket,
    billing: {
      billing_address: {
        first_name: firstName,
        last_name: lastName,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_line_3: addressLine3,
        city,
        region: stateCode,
        postal_code: getFiveDigitsZipCode({ countryCode, postalCode }),
        country: getTwoDigitsCountryCode(countryCode),
        phone_number: editedPhoneNumber.replace(INVALID_INTEGER_CHARS_REGEX, '') || accountPhoneNumber,
      },
      is_default: false,
      payment_nonce: creditCardDataPayload?.nonce || '',
    },
    delivery_id: deliveryId,
    exclusive_listings: hasCp1BrokerId,
    insurance: {
      declined: true,
    },
    io_black_box: ioBlackBox || '',
    loyalty: undefined,
    offer: undefined,
    order_total: {
      amount: priceTotal,
      currency: currency as 'USD' | 'CAD',
    },
    override_order_email: false,
    payment_method: undefined,
    production_id: eventId,
    quantity,
    quote_id: quoteId,
    redeem: {
      amount: newAppliedRewardUnits,
      loyalty_unit_name: rewardUnitName,
    },
    shipping_address: shippingAddress
      ? {
        first_name: shippingAddress.firstName,
        last_name: shippingAddress.lastName,
        address_line_1: shippingAddress.streetAddress,
        address_line_2: shippingAddress.streetAddressLine2,
        city: shippingAddress.city,
        region: shippingAddress.state,
        postal_code: getFiveDigitsZipCode({ countryCode: shippingAddress.country, postalCode: shippingAddress.postalCode }),
        country: getTwoDigitsCountryCode(shippingAddress.country),
        phone_number: shippingAddress.phoneNumber.replace(INVALID_INTEGER_CHARS_REGEX, ''),
      }
      : undefined,
    ticket_id: listingId,
  };

  return orderRequest;
};
