import { matchPath } from 'react-router-dom';
import { SPORTS_ROUTES, URLs } from '../../../lib/constants';

/**
 * Checks if the current path matches a given path or paths.
 *
 * @param currentPathName - The current path name to check against.
 * @param pathName - A single path or an array of paths to match.
 * @param exact - Whether the match should be exact.
 * @returns True if the path matches, otherwise false.
 */
export const checkIfPathMatch = (currentPathName: string, pathName: string | string[], exact: boolean): boolean => {
  const paths = typeof pathName === 'string' ? [pathName] : pathName;
  return paths.some((path) => {
    const pattern = path.endsWith('/*') ? path : `${path}/*`;
    return !!matchPath({ path: pattern, end: exact }, currentPathName);
  });
};

/**
 * Determines whether the updated top navigation should be shown based on the current path.
 * @param pathname - The current path name.
 * @returns True if the updated top navigation should be shown, otherwise false.
 */
export const checkShouldShowUpdatedTopNav = (pathname: string): boolean => {
  const isHomePage: boolean = checkIfPathMatch(pathname, '/', true);
  if (isHomePage) return true;

  const isAllEventsPage: boolean = checkIfPathMatch(pathname, URLs.AllEventsPage, true);
  if (isAllEventsPage) return true;

  const isC1ExclusivePage: boolean = checkIfPathMatch(pathname, URLs.ExclusiveEventsPage, true);
  if (isC1ExclusivePage) return true;

  const isSportsTeamPage: boolean = checkIfPathMatch(pathname, SPORTS_ROUTES, true);
  if (isSportsTeamPage) return true;

  const isPerformerPage: boolean = checkIfPathMatch(pathname, '/performers/:performerId', true);
  if (isPerformerPage) return true;

  const isEventPage: boolean = checkIfPathMatch(pathname, '/events/:eventId', true);
  if (isEventPage) return true;

  const isVenuePage: boolean = checkIfPathMatch(pathname, '/venues/:venueId', true);
  if (isVenuePage) return true;

  const isSearchResultsPage: boolean = checkIfPathMatch(pathname, URLs.SearchResultsPage, true);
  if (isSearchResultsPage) return true;

  const isCheckoutPage: boolean = checkIfPathMatch(pathname, '/checkout/:listingId', true);
  if (isCheckoutPage) return true;

  const isOrderConfirmationPage: boolean = checkIfPathMatch(pathname, URLs.OrderConfirmation, true);
  if (isOrderConfirmationPage) return true;

  return false;
};
