import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { CssVariableNameEnum } from '../../../lib/types';
import { useHtmlElementSize, useScrollOffset, useWindowSize } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackSelectContentEvent } from '../../../modules/analytics/util';
import { TopNavContext } from '../../../modules/topNav';
import type { AccountMenuStateEnum } from '../../molecules/AccountMenu/types';
import { useSearchQueryFromUrl } from '../../pages/SearchResultsPage';
import type { TopNavPresenterProps, TopNavProps, TopNavTypeEnum } from './TopNav.types';
import { getTopNavType } from './TopNav.utils';

export const usePresenter = (props: TopNavProps): TopNavPresenterProps => {
  const { topNavType: initialTopNavType } = props;

  const {
    /** State for the previous and current URL paths */
    pathHistory,
    setActiveTopNavMenuType,
    setSearchQuery,
    addTopFixedHeight,
  } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const { scrollY } = useScrollOffset();

  const topNavType: TopNavTypeEnum = useMemo(
    () => initialTopNavType || getTopNavType({ pathname, scrollY, isMobile }),
    [initialTopNavType, pathname, scrollY, isMobile],
  );

  const topNavRef = useRef<HTMLDivElement>(null);

  const { height: topNavHeight } = useHtmlElementSize({ htmlElement: topNavRef.current });

  // Store TopNav height.
  // Explanation: TopNav is rendered with a fixed position which means it floats above the rest of the content.
  // To avoid layout issues (e.g. some content may get hidden behind the TopNav) we also render a spacer div of the same height as the TopNav.
  useEffect(() => {
    addTopFixedHeight(CssVariableNameEnum.topNavHeight, topNavHeight);
    return () => addTopFixedHeight(CssVariableNameEnum.topNavHeight, 0);
  }, [topNavHeight, addTopFixedHeight]);

  const [accountMenuDropdownState, setAccountMenuDropdownState] = useState<AccountMenuStateEnum>('Collapsed');

  const searchQueryFromUrl: string = useSearchQueryFromUrl();

  // Sync search query from URL with local state.
  // This is required for Search Results page.
  useEffect(() => {
    if (searchQueryFromUrl) {
      setSearchQuery(searchQueryFromUrl);
    }
  }, [searchQueryFromUrl, pathname, search, setSearchQuery]);

  // Clear search query and close all menus when navigating away to a different page
  useEffect(() => {
    setAccountMenuDropdownState('Collapsed');
    setActiveTopNavMenuType(undefined);

    if (pathname !== URLs.SearchResultsPage) {
      setSearchQuery('');
    }
  }, [pathname, setActiveTopNavMenuType, setSearchQuery]);

  const onLogoClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Logo',
      t('logo.capitalOneEntertainment'),
    );
  }, [t, trackEvent]);

  const onMenuButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'AccountMenu',
      t('topnav.menuButtonReaderText'),
    );

    setAccountMenuDropdownState('Expanded');
    setActiveTopNavMenuType('AccountMenu');
  }, [t, trackEvent, setActiveTopNavMenuType]);

  const onCloseButtonClick = useCallback(() => {
    // Previous path will be defined if user has visited at least one more page in the application prior to the current page
    // In this case go back
    // Otherwise, navigate to the home page
    navigate(pathHistory?.previousPath || '/');
  }, [pathHistory?.previousPath, navigate]);

  return {
    ...props,
    topNavType,
    topNavRef,
    onLogoClick,
    onMenuButtonClick,
    onCloseButtonClick,
    accountMenu: {
      accountMenuDropdownState,
      setAccountMenuDropdownState,
      expandButton: {
        icon: {
          asset: 'ChevronDown',
          style: 'White',
        },
      },
    },
  };
};
