import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import { ExclusiveBadge } from '../ExclusiveBadge';
import { IconText } from '../IconText';
import { CardholderExclusiveBadgeThemesByVariant } from './EventCard.constants';
import styles from './EventCard.module.scss';
import { usePresenter } from './EventCard.presenter';
import type { EventCardProps } from './EventCard.types';

const InternalEventCard: React.FC<EventCardProps> = (props) => {
  const {
    variant,
    eventMetadata: {
      eventUrl,
      eventName,
      eventDateTimeAndVenueDetails,
      isExclusiveEvent,
      isPayWithRewardsOnly,
    },
    eventImageUrl,
    eventDescription,
    shouldSplitEventDateAndVenueDetails,
    eventStatus,
    getTicketsLabelKey,
    className,
    classes,
    onEventCardClick,
  } = usePresenter(props);

  const { t } = useTranslation();

  // Event image
  const eventImage: ReactNode = !!eventImageUrl && (
    <Image
      imageSrc={eventImageUrl}
      className={styles.eventImage}
    />
  );

  // Cardholder Exclusive badge
  const cardholderExclusiveBadge: ReactNode = isExclusiveEvent && (
    <ExclusiveBadge
      icon={{
        iconAsset: 'Sparkles',
        iconStyle: 'Yellow',
      }}
      textKey='exclusiveBadge.cardholderExclusive'
      theme={CardholderExclusiveBadgeThemesByVariant[variant]}
      className={styles.cardholderExclusiveBadge}
    />
  );

  // Sold Out / Low Tickets badge
  const eventStatusBadge: ReactNode = !!eventStatus && (
    <Text
      size='Small'
      style='SemiBold'
      colour={eventStatus.statusColor}
      value={t(eventStatus.statusLabel!)}
      className={styles.eventStatusBadge}
    />
  );

  // Pay With Rewards Only badge
  const payWithRewardsOnlyBadge: ReactNode = isPayWithRewardsOnly && (
    <div className={styles.payWithRewardsOnlyBadge}>
      <Icon
        asset='Trophy'
        style='CustomBlue20'
      />
      <Text
        size='ExtraSmall'
        style='Regular'
        colour='CoreBlue50'
        value={t('event.payWithRewardsOnly')}
      />
    </div>
  );

  // Event name
  const eventNameView: ReactNode = (
    <Text
      size='Large'
      style='SemiBold'
      colour='BaseDark'
      value={eventName}
    />
  );

  // Event description
  const eventDescriptionView: ReactNode = !!eventDescription && (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedLight'
      value={eventDescription}
      className={styles.eventDescription}
    />
  );

  // Event date time and venue
  // Use NonNullable<ReactNode> to ensure we return a value for each variant
  const getEventDateTimeAndVenue = (): NonNullable<ReactNode> => {
    switch (variant) {
      case 'horizontal': return (
        <Text
          size='Small'
          style='Regular'
          colour='Charcoal30'
          value={t('event.shortDateTimeVenueNameCityStateCode', eventDateTimeAndVenueDetails)}
        />
      );
      case 'squareOnLight':
      case 'squareOnDark': return shouldSplitEventDateAndVenueDetails
        ? (<>
          <IconText
            iconAsset='CalenderNew'
            text={t('event.shortWeekDayLongDateTime', eventDateTimeAndVenueDetails)}
            color='SubduedLight'
          />
          <IconText
            iconAsset='LocationPinFilled'
            text={t('event.venueNameCityStateCode', eventDateTimeAndVenueDetails)}
            color='SubduedLight'
          />
        </>)
        : (
          <Text
            size='Medium'
            style='Regular'
            colour='SubduedLight'
            value={t('event.shortWeekDayShortDateTimeVenueCityVenueName', eventDateTimeAndVenueDetails)}
          />
        );
    }
  };

  // Get Tickets button
  const getTicketsButton: ReactNode = (
    // Button doesn't need "href" prop because it is already wrapped by Link component
    <Button
      type='Text'
      style='Contained'
      size='Medium'
      text={{ value: t(getTicketsLabelKey) }}
    />
  );

  // Use NonNullable<ReactNode> to ensure we return a value for each variant
  const getEventCard = (): NonNullable<ReactNode> => {
    switch (variant) {
      case 'horizontal': return (
        <div className={styles.eventCardInnerContainer}>
          {eventImage}
          <div className={styles.eventContent}>
            <div className={styles.eventLeftSubContent}>
              {cardholderExclusiveBadge}
              {eventStatusBadge}
              {eventNameView}
              {eventDescriptionView}
              {getEventDateTimeAndVenue()}
              {payWithRewardsOnlyBadge}
            </div>
            <div className={styles.eventRightSubContent}>
              {getTicketsButton}
            </div>
          </div>
        </div>
      );
      case 'squareOnLight':
      case 'squareOnDark': return (<>
        <div className={cx(styles.topContent, classes?.topContent)}>
          {eventImage}
          {cardholderExclusiveBadge}
          {payWithRewardsOnlyBadge}
        </div>
        <div className={cx(styles.bottomContent, classes?.bottomContent)}>
          {eventStatusBadge}
          {eventNameView}
          {eventDescriptionView}
          {getEventDateTimeAndVenue()}
        </div>
        <div className={styles.overlay} />
      </>);
    }
  };

  return (
    <Link
      to={eventUrl}
      className={cx(styles.eventCard, styles[`${variant}EventCard`], { [styles.noEventImage]: !eventImageUrl }, className)}
      onClick={onEventCardClick}
    >
      {getEventCard()}
    </Link>
  );
};

/** Memoize event card since a lot of pages render a large number of event cards, e.g. Home, Exclusive Events, All Events, Performer, Venue, Search Results pages, etc. */
export const EventCard = React.memo(InternalEventCard);
