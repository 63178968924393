import type { ButtonCombinedProps, ButtonProps } from '../../atoms/Button';
import type { LogoProps } from '../../atoms/Logo';
import type { TextProps } from '../../atoms/Text';
import type { AccountMenuProps } from '../../molecules/AccountMenu';
import type { SearchFieldProps } from '../../molecules/SearchField';
import type { NavMenuListCombinedProps } from '../NavMenuList/types';

type TopNavInternalProps = {
  logo?: LogoProps;
  accountMenu?: AccountMenuProps;
  searchButton?: ButtonProps;
  backButton?: ButtonCombinedProps; // MANUAL OVERRIDE
  title?: TextProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
  description?: TextProps;
  searchField?: SearchFieldProps;
  navMenuList?: NavMenuListCombinedProps; // MANUAL OVERRIDE
};

type TopNavPropsValues = {
  'Checkout': TopNavInternalProps;
  'SearchInNav': TopNavInternalProps;
  'SignedOut': TopNavInternalProps;
  'WithoutSearchInNav': TopNavInternalProps;
};

const propValues: TopNavPropsValues = {
  'Checkout': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'SearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    searchButton: {
      type: 'Icon',
      style: 'Text',
      size: 'Small',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
  'SignedOut': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
  },
  'WithoutSearchInNav': {
    logo: {
      asset: 'LogoC1EntertainmentWhite',
    },
    navMenuList: {
      type: 'Tabs',
    },
    accountMenu: {
      state: 'Collapsed',
    },
  },
};

const getProps = (type: string): TopNavInternalProps => {
  const values: TopNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
