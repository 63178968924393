import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAX_PRICE_FILTER_PARAM, MIN_PRICE_FILTER_PARAM, QUANTITY_FILTER_PARAM } from '../../../lib/constants';
import { areObjectsEqual } from '../../../lib/objectUtils';
import { addQueryParam, isNumber, parseNumber, useHtmlElementSize, useNumericQueryParamFromUrl, useQueryParamFromUrl, useWindowSize } from '../../../lib/util';
import { useModalState } from '../../../modules/modals';
import { TopNavContext } from '../../../modules/topNav';
import type { SliderRange } from '../../molecules/Slider';
import type { NonGuestListEventPagePresenterProps, NonGuestListEventPageProps } from './NonGuestListEventPage.types';
import { checkHasMinMaxPriceFilter, checkIsSvgMapPanZoomEnabled } from './NonGuestListEventPage.utils';

export const usePresenter = (props: NonGuestListEventPageProps): NonGuestListEventPagePresenterProps => {
  const {
    eventMetadata: {
      isExclusiveEvent,
      isPayWithRewardsOnly,
    },
    listingsMetadata,
    resetFilters,
  } = props;

  const {
    minPrice,
    maxPrice,
  } = listingsMetadata ?? {};

  const { setShouldShowTopNav, setShouldShowFooter } = useContext(TopNavContext);

  const navigate = useNavigate();
  const { search } = useLocation();

  const { isDesktop } = useWindowSize();

  // Hide TopNav on mobiles
  // Always hide footer
  useEffect(() => {
    setShouldShowTopNav(isDesktop);
    setShouldShowFooter(false);

    return () => {
      setShouldShowTopNav(true);
      setShouldShowFooter(true);
    };
  }, [isDesktop, setShouldShowTopNav, setShouldShowFooter]);

  // TODO: AK: Set correct value
  /** Flag to enforce AIP pricing */
  const shouldShowAipOverride: boolean = useMemo(() => false, []);


  /** Currently selected quantity. Undefined if quantity is invalid, e.g. when it is non-numeric or it is less than 1. */
  const quantity: number | undefined = useNumericQueryParamFromUrl(QUANTITY_FILTER_PARAM, { minValue: 1 });

  /** True if event is not exclusive, not a Pay With Rewards Only event and min/max prices are set for listings */
  const hasMinMaxPriceFilter: boolean = useMemo(
    () => checkHasMinMaxPriceFilter({ isExclusiveEvent, isPayWithRewardsOnly, listingsMetadata }),
    [isExclusiveEvent, isPayWithRewardsOnly, listingsMetadata],
  );

  const selectedMinPriceParam: string | undefined = useQueryParamFromUrl(MIN_PRICE_FILTER_PARAM);
  const selectedMinPrice: number | undefined = useMemo(
    () => hasMinMaxPriceFilter && isNumber(minPrice) && isNumber(maxPrice)
      ? parseNumber(selectedMinPriceParam, { minValue: minPrice, maxValue: maxPrice }) ?? minPrice
      : undefined,
    [hasMinMaxPriceFilter, selectedMinPriceParam, minPrice, maxPrice],
  );

  const selectedMaxPriceParam: string | undefined = useQueryParamFromUrl(MAX_PRICE_FILTER_PARAM);
  const selectedMaxPrice: number | undefined = useMemo(
    () => hasMinMaxPriceFilter && isNumber(selectedMinPrice) && isNumber(maxPrice)
      ? parseNumber(selectedMaxPriceParam, { minValue: selectedMinPrice, maxValue: maxPrice }) ?? maxPrice
      : undefined,
    [hasMinMaxPriceFilter, selectedMinPrice, selectedMaxPriceParam, maxPrice],
  );

  /** Currently selected price slider range */
  const priceRange: SliderRange | undefined = useMemo(
    () => hasMinMaxPriceFilter && isNumber(selectedMinPrice) && isNumber(selectedMaxPrice)
      ? { minValue: selectedMinPrice, maxValue: selectedMaxPrice }
      : undefined,
    [hasMinMaxPriceFilter, selectedMinPrice, selectedMaxPrice],
  );

  /** Minimum and maximum values for the price slider */
  const priceMinMaxRange: SliderRange | undefined = useMemo(
    () => hasMinMaxPriceFilter && isNumber(minPrice) && isNumber(maxPrice)
      ? ({ minValue: minPrice, maxValue: maxPrice })
      : undefined,
    [hasMinMaxPriceFilter, minPrice, maxPrice],
  );

  // Reset invalid filter parameters
  useEffect(() => {
    if (listingsMetadata &&
      (
        (selectedMinPriceParam && (!isNumber(selectedMinPrice) || selectedMinPriceParam !== selectedMinPrice.toString()))
        || (selectedMaxPriceParam && (!isNumber(selectedMaxPrice) || selectedMaxPriceParam !== selectedMaxPrice.toString()))
      )
    ) {
      resetFilters();
    }
  }, [listingsMetadata, selectedMinPriceParam, selectedMinPrice, selectedMaxPriceParam, selectedMaxPrice, resetFilters]);

  /** Callback when quantity or price slider range change */
  const onListingsFiltersChanged = useCallback((params: { newQuantity: number | undefined; newPriceRange: SliderRange | undefined; }) => {
    const { newQuantity, newPriceRange } = params;

    const hasPriceRangeChanged: boolean = !areObjectsEqual(newPriceRange, priceMinMaxRange);

    const newQueryString: string = addQueryParam(search, {
      [QUANTITY_FILTER_PARAM]: newQuantity ? newQuantity.toString() : '',
      [MIN_PRICE_FILTER_PARAM]: hasPriceRangeChanged && newPriceRange ? newPriceRange.minValue.toString() : '',
      [MAX_PRICE_FILTER_PARAM]: hasPriceRangeChanged && newPriceRange ? newPriceRange.maxValue.toString() : '',
    }, { skipEmptyParams: true });

    navigate({ search: newQueryString }, { replace: true });
  }, [priceMinMaxRange, search, navigate]);

  const {
    isModalOpen: isListingsFiltersModalOpen,
    openModal: openListingsFiltersModal,
    closeModal: closeListingsFiltersModal,
  } = useModalState();

  // Close listings filters modal on desktops
  useEffect(() => {
    if (isDesktop) {
      closeListingsFiltersModal();
    }
  }, [isDesktop, closeListingsFiltersModal]);

  /** Ref to left sub-content div */
  const leftSubContentRef = useRef<HTMLDivElement>(null);
  const { height: leftSubContentHeight } = useHtmlElementSize({ htmlElement: leftSubContentRef.current });

  /**
   * Indicates if pan-zoom functionality is enabled for SVG map.
   * On desktops it is always true.
   * On mobiles it is true only when map container remains fixed to the top, i.e. there is enough screen height so that page does not scroll.
   * This is to prevent page scroll from interfering with pan-zoom functionality.
   */
  const isSvgMapPanZoomEnabled: boolean = useMemo(
    () => leftSubContentHeight ? checkIsSvgMapPanZoomEnabled({ isDesktop, mapContainerDiv: leftSubContentRef.current }) : false,
    [leftSubContentHeight, isDesktop],
  );

  // TODO: AK: Check where this should go
  // useEffect(() => {
  //   trackEvent('view_item', {
  //     ecommerce: {
  //       content_type: 'view_production',
  //       items: [],
  //     },
  //   });
  // }, [trackEvent]);

  return {
    ...props,
    shouldShowAipOverride,
    quantity,
    priceRange,
    priceMinMaxRange,
    onListingsFiltersChanged,
    isListingsFiltersModalOpen,
    openListingsFiltersModal,
    closeListingsFiltersModal,
    leftSubContentRef,
    isSvgMapPanZoomEnabled,
  };
};
