import i18n from '../../../modules/locale/i18n';

// TODO: AK: Add unit tests
/** Function to get ARIA value text to apply to the price thumbs */
export const getPriceThumbAriaValueText = (params: { index: number; valueNow: number; }): string => {
  const { index, valueNow } = params;
  switch (index) {
    case 0: return i18n.t('listingsFilters.minPriceThumbValue', { minPrice: valueNow });
    case 1: return i18n.t('listingsFilters.maxPriceThumbValue', { maxPrice: valueNow });
    default: return '';
  }
};
