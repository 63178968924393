import { useCallback, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { AuthContext } from '../auth';
import { ApiError } from '../error/types';
import type { EventKeys, UseAnalyticsManagerResponse } from './types';
import { trackErrorEvent } from './util';

const NEW_DESIGN = 'homepage';

export const useAnalyticsManager = (): UseAnalyticsManagerResponse => {
  const { account, hashedEmail } = useContext(AuthContext);

  const trackEvent = useCallback((event: EventKeys, extraData?: Record<string, unknown>) => {
    const accountId: string | number | undefined = account?.id;

    if (accountId && hashedEmail) {
      const eventData = {
        event,
        variation: NEW_DESIGN,
        userId: accountId,
        user: {
          user_id: accountId,
          crm_id: hashedEmail,
        },
        ...extraData,
      };

      TagManager.dataLayer({ dataLayer: eventData });
    }
  }, [account?.id, hashedEmail]);

  const onError = useCallback((error: unknown): void => {
    if (error && ApiError.isApiError(error)) {
      trackErrorEvent(trackEvent, error.code, error.message);
    }
  }, [trackEvent]);

  return {
    trackEvent,
    onError,
  };
};
