import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './EventInfoModal.module.scss';
import { usePresenter } from './EventInfoModal.presenter';
import type { EventInfoModalProps } from './EventInfoModal.types';

export const EventInfoModal: React.FC<EventInfoModalProps> = (props) => {
  const {
    eventMetaData: {
      eventName,
      eventModalMobileImageUrl,
      eventModalDesktopImageUrl,
      eventDateTimeAndVenueDetails,
    },
    processedEventDescriptionHtml,
    closeInfoModal,
  } = usePresenter(props);

  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const closeButton: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: 'Close',
        style: 'SubduedDark',
      }}
      onClick={closeInfoModal}
      autoFocus={true}
      ariaLabel={t('event.closeEventInfoModal')}
      className={styles.closeButton}
    />
  );

  const eventImage: ReactNode = (
    <Image
      imageSrc={isMobile ? eventModalMobileImageUrl : eventModalDesktopImageUrl}
      className={styles.eventImage}
    />
  );

  const eventDateTimeAndVenue: ReactNode = (
    <div className={styles.eventDateTimeAndVenue}>
      <Text
        type='NewEventDetailsTitle'
        size='Large'
        style='SemiBold'
        colour='SubduedDark'
        value={eventDateTimeAndVenueDetails.venueName}
      />
      <div className={styles.eventDateTimeAndVenueContent}>
        <Text
          size='Large'
          style='Regular'
          colour='SubduedLight'
          value={t('event.shortWeekDayShortDateTime', eventDateTimeAndVenueDetails)}
          className={styles.greyColour}
        />
        <Text
          size='Large'
          style='Regular'
          colour='SubduedLight'
          value={eventDateTimeAndVenueDetails.venueName}
          className={styles.greyColour}
        />
        <Text
          size='Large'
          style='Regular'
          colour='SubduedLight'
          value={t('event.venueCityStateCode', eventDateTimeAndVenueDetails)}
          className={styles.greyColour}
        />
      </div>
    </div>
  );

  /** Event description with HTML being handled */
  const processedEventDescription: ReactNode = typeof processedEventDescriptionHtml === 'string'
    ? <span dangerouslySetInnerHTML={{ __html: processedEventDescriptionHtml }} />
    : processedEventDescriptionHtml;

  const eventNameAndDescription: ReactNode = (
    <div className={styles.eventNameAndDescription}>
      <Text
        type='NewEventDetailsTitle'
        size='Large'
        style='SemiBold'
        colour='SubduedLight'
        value={eventName}
        className={styles.lineHeight31px}
      />
      <Text
        size='Large'
        style='Regular'
        colour='SubduedLight'
        value={processedEventDescription}
        className={cx(styles.eventDescription, styles.greyColour, styles.lineHeight21px)}
      />
    </div>
  );

  return (
    <Modal
      show={true}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={closeInfoModal}
    >
      <div className={styles.eventInfoModalContent}>
        {closeButton}
        {eventImage}
        {eventDateTimeAndVenue}
        {eventNameAndDescription}
      </div>
    </Modal>
  );
};
