import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { typedDeepMerge } from '../../../lib/objectUtils';
import { AuthContext } from '../../../modules/auth';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import type { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { TabCombinedProps } from '../../atoms/Tab/types';
import type { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import type { TopNavCombinedProps } from './types';
import { getTopNavType, SEARCH_ICON_ON_TAB_BAR } from './util';

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const searchBlockTextFieldActive = useRef(false);

  const data: CategoryMenuItemState[] = JSON.parse(JSON.stringify(categoryEvents)) as CategoryMenuItemState[];
  const { account } = useContext(AuthContext);

  // manage tabs start
  const [openDropdownId, setOpenDropdownId] = useState<string>();
  const [focusState, setFocusState] = useState<string>();
  const onTabHover = (id: string | undefined) => {
    setOpenDropdownId(id);
  };
  const onTabFocus = (id: string | undefined) => {
    if (id !== openDropdownId) {
      setOpenDropdownId(undefined);
    }
    setFocusState(id);
  };
  const onTabLeave = () => {
    setOpenDropdownId(undefined);
  };
  const onKeyPress = () => {
    if (document.activeElement?.getAttribute('aria-haspopup')) {
      setOpenDropdownId(
        openDropdownId === focusState ? undefined : focusState,
      );
    }
  };
  // manage tabs end

  // manage search focus starts
  const topNavType = props.type || getTopNavType(pathname, account);
  const onSearchIconFocus = (id?: string) => {
    if (id !== openDropdownId) {
      setOpenDropdownId(undefined);
    }
    setFocusState(id);
  };
  const onSearchIconHover = () => {
    setOpenDropdownId(SEARCH_ICON_ON_TAB_BAR);
  };

  const onSearchIconLeave = () => {
    setOpenDropdownId(undefined);
  };
  // manage search focus ends

  // sets navmenu category tabs
  const headerTabs: TabCombinedProps[] = [];
  const menuListItems: NavMenuItemCombinedProps[] = [];

  data?.forEach((category) => {
    headerTabs.push({
      id: category.id,
      text: {
        value: category.name,
      },
    });

    menuListItems.push({
      title: {
        value: category.name,
      },
    });
  });

  return typedDeepMerge(props, {
    type: topNavType,
    logo: {
      linkPath: '/',
      assetAlt: t('logo.capitalOneEntertainment'),
    },
    navMenuList: {
      tabs: headerTabs,
      navMenuItems: menuListItems,
      type: 'Tabs',
    },
    searchButton: {
      icon: {
        asset: 'Search',
        style: 'White',
        assetAlt: t('search.searchIconScreenReaderText'),
      },
    },
    searchBlock: {
      closeSearchBlock: () => setOpenDropdownId(undefined),
      searchResult: {
        // contains search suggestions
        onClick: () => {
          setOpenDropdownId(undefined);
        },
      },
      searchContainer: {
        searchField: {
          textInput: {
            onFocusChanged: () => {
              searchBlockTextFieldActive.current = true;
            },
            onBlurChanged: () => {
              searchBlockTextFieldActive.current = false;
            },
          },
        },
        state: 'Default',
      },
    },
    onSearchIconHover,
    onSearchIconLeave,
    openDropdownId,
    onTabLeave,
    onTabHover,
    onTabFocus,
    onKeyPress,
    onSearchIconFocus,
  });
};

export default usePresenter;
