import { useCallback, useMemo } from 'react';
import { TAB_KEY_PARAM } from '../../../lib/constants';
import { useQueryParamFromUrl } from '../../../lib/util';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { getValidTabKeyFromUrl } from './TabBar.utils';

/** Hook to extract tab key from query parameters in the current URL and check it is valid */
export const useTabKeyFromUrl = <TTabKey extends string>(params: {
  allowedTabKeys: readonly TTabKey[];
}): TTabKey => {
  const { allowedTabKeys } = params;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const clearTabKeyParamInUrl = useCallback(() => {
    appendQueryParamsToUrl({ [TAB_KEY_PARAM]: '' }, { replace: true });
  }, [appendQueryParamsToUrl]);

  const tabKeyParam: TTabKey | undefined = useQueryParamFromUrl<TTabKey>(TAB_KEY_PARAM);

  const tabKeyFromUrl: TTabKey = useMemo(() => {
    return getValidTabKeyFromUrl({ tabKeyParam, allowedTabKeys, clearTabKeyParamInUrl });
  }, [tabKeyParam, allowedTabKeys, clearTabKeyParamInUrl]);

  return tabKeyFromUrl;
};
