import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import type { MobileSearchHeaderPresenterProps, MobileSearchHeaderProps } from './MobileSearchHeader.types';

export const usePresenter = (props: MobileSearchHeaderProps): MobileSearchHeaderPresenterProps => {
  const { isInsideModal } = props;

  const { pathHistory, setActiveTopNavMenuType, setSearchQuery } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Search',
      t('topnav.close'),
    );

    setSearchQuery('');
    setActiveTopNavMenuType(undefined);

    // When back button is inside a modal then we do not need to navigate anywhere.
    // We just need to close the modal which will be done by calling 'setActiveTopNavMenuType(undefined)'.
    if (!isInsideModal) {
      // Previous path will be defined if user has visited at least one more page in the application prior to the current page
      // In this case go back
      // Otherwise, navigate to the home page
      if (pathHistory?.previousPath) {
        navigate(-1);
      } else {
        navigate('/');
      }
    }
  }, [t, isInsideModal, pathHistory?.previousPath, trackEvent, setSearchQuery, setActiveTopNavMenuType, navigate]);

  return {
    ...props,
    onBackButtonClick,
    isSearchBarFocusedOnMount: isInsideModal,
    isSearchBarBlurredOnFocus: !isInsideModal,
  };
};
