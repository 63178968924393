import type { AriaRole } from 'react';
import { URLs } from '../../../lib/constants';
import { checkIfPathMatch } from '../../layout/MainLayout/MainLayout.utils';
import type { AccessibilityActions, AccessibilityProps, TopNavTypeEnum } from './TopNav.types';

/**
 * Generates accessibility properties for clickable elements.
 * @param {boolean} [addIndex] -
 * @param {AriaRole} [role='button'] - ARIA role attribute to be assigned to the element.
 * @returns {AccessibilityProps}
 */
export const getClickAccessibilityProps = (params: {
  /** Object containing action handlers for various key events. */
  actions: AccessibilityActions;
  /** If true, sets the tabIndex to 0 to make the element focusable. */
  addIndex?: boolean;
  /** Accessibility properties including ARIA role and key event handlers. */
  role?: AriaRole;
}): AccessibilityProps => {
  const { actions, addIndex, role } = params;
  // Initialize the element properties with default role 'button' or provided role
  const elementProps: AccessibilityProps = {
    role: role || 'button',
    onKeyDown: (e) => {
      // Handle different key events
      switch (e.key) {
        case 'Enter':
        case ' ':
        case 'Spacebar':
          // Trigger onClick action if defined
          if (actions.onClick) {
            actions.onClick();
          }
          break;
        case 'Escape':
          // Trigger onEsc action if defined
          if (actions.onEsc) {
            actions.onEsc();
          }
          break;
        case 'Tab':
          // Trigger onTab action if defined, passing a boolean indicating the direction of tabbing
          if (actions.onTab) {
            actions.onTab(!e.shiftKey);
          }
          break;
      }
    },
  };

  // Add tabIndex property if addIndex is true to make the element focusable
  if (addIndex) {
    elementProps.tabIndex = 0;
  }

  // Return the constructed accessibility properties
  return elementProps;
};


/**
 * Determines the type of top navigation bar to display based on the provided parameters.
 * @returns {TopNavTypeEnum} The type of top navigation bar to display.
 */
export const getTopNavType = (params: {
  /**  The current path of the application. */
  pathname: string;
  /** The current vertical scroll position. */
  scrollY: number;
  /** Indicates if the device is a mobile device. */
  isMobile: boolean;
}): TopNavTypeEnum => {

  const { pathname, scrollY, isMobile } = params;

  // Checkout page should render a header with logo and close button
  const isCheckoutPage: boolean = checkIfPathMatch(pathname, '/checkout/:listingId', true);
  if (isCheckoutPage) {
    return 'LogoWithClose';
  }

  // Order Confirmation page should render a header with logo only
  const isConfirmationPage: boolean = checkIfPathMatch(pathname, URLs.OrderConfirmation, true);
  if (isConfirmationPage) {
    return 'LogoOnly';
  }

  if (isMobile) {
    // Home page on mobiles should render a taller header with the search bar if page is not scrolled
    const isHomePage: boolean = checkIfPathMatch(pathname, '/', true);
    if (isHomePage) {
      // On mobile home page, render a taller header with search bar if the page is not scrolled
      return scrollY ? 'Mobile' : 'Mobile2Rows';
    }

    // Search Results page on mobiles should render the header with the search bar and the back button
    const isSearchResultsPage: boolean = checkIfPathMatch(pathname, URLs.SearchResultsPage, true);
    if (isSearchResultsPage) {
      // On mobile search results page, render the header with search bar and back button
      return 'MobileWithSearchAndBack';
    }

    // Default to a basic mobile header
    return 'Mobile';
  }

  // Default to desktop header for non-mobile devices
  return 'Desktop';
};
