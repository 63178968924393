import { useCallback, useMemo } from 'react';
import { DATE_RANGE_PARAM, END_DATE_PARAM, START_DATE_PARAM } from '../../../lib/constants';
import { useQueryParamFromUrl } from '../../../lib/util';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import type { DateFilterState } from './DateFilter.types';
import { extractDateFilterStateFromParams } from './DateFilter.utils';

/**
 * Hook to extract date filter state from query parameters in the current URL.
 * Examples:
 * - /search?date_range=today
 * - /search?date_range=tomorrow
 * - /search?date_range=thisWeekend
 * - /search?date_start=2024-01-01&date_end=2024-12-31
 */
export const useDateFilterStateFromUrl = (): DateFilterState | undefined => {
  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const clearDateParamsInUrl = useCallback(() => {
    appendQueryParamsToUrl({ [DATE_RANGE_PARAM]: '', [START_DATE_PARAM]: '', [END_DATE_PARAM]: '' }, { replace: true });
  }, [appendQueryParamsToUrl]);

  const dateRangeParam: string | undefined = useQueryParamFromUrl(DATE_RANGE_PARAM);
  const startDateParam: string | undefined = useQueryParamFromUrl(START_DATE_PARAM);
  const endDateParam: string | undefined = useQueryParamFromUrl(END_DATE_PARAM);

  const dateFilterStateFromUrl: DateFilterState | undefined = useMemo(
    () => extractDateFilterStateFromParams({ dateRangeParam, startDateParam, endDateParam, clearDateParamsInUrl }),
    [dateRangeParam, startDateParam, endDateParam, clearDateParamsInUrl],
  );

  return dateFilterStateFromUrl;
};
