import cx from 'classnames';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '../../atoms/Button';
import Logo from '../../atoms/Logo';
import SearchBlock from '../../blocks/SearchBlock';
import AccountMenu from '../../molecules/AccountMenu';
import NavMenuList from '../../organisms/NavMenuList';
import customStyles from './Custom.module.scss';
import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import type { TopNavCombinedProps } from './types';
import { getClickAccessibilityProps, SEARCH_ICON_ON_TAB_BAR } from './util';

const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    logo,
    accountMenu,
    searchButton,
    navMenuList,
    searchBlock,
    openDropdownId,
    onTabHover,
    onTabFocus,
    onSearchIconFocus,
    onTabLeave,
    onKeyPress,
    data,
    onSearchIconHover,
    onSearchIconLeave,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`topNav${variantName}`];

  // for use in pages other than homepage
  const accessibilityProps = getClickAccessibilityProps({
    onClick: onKeyPress,
    onEsc: onSearchIconLeave,
  });

  const searchButtonView = (
    <>
      <Dropdown
        className={customStyles.dropdown}
        onMouseOver={onSearchIconHover}
        onMouseLeave={onSearchIconLeave}
        onKeyUp={() =>
          onSearchIconFocus && onSearchIconFocus(SEARCH_ICON_ON_TAB_BAR)
        }
        show={openDropdownId === SEARCH_ICON_ON_TAB_BAR}
        {...accessibilityProps}
      >
        <Dropdown.Toggle>
          <Button
            className={cx(styles.searchButton, classes?.searchButton)}
            {...internalProps.searchButton}
            {...searchButton} />
        </Dropdown.Toggle>
        {openDropdownId === SEARCH_ICON_ON_TAB_BAR ? (
          <Dropdown.Menu className={customStyles.dropdownMenu} align='right'>
            <SearchBlock {...searchBlock} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>

      {/* Hover dropdown for desktop view */}
      <Dropdown
        className={customStyles.desktopDropdown}
        onMouseOver={onSearchIconHover}
        onMouseLeave={onSearchIconLeave}
        onKeyUp={() =>
          onSearchIconFocus && onSearchIconFocus(SEARCH_ICON_ON_TAB_BAR)
        }
        show={openDropdownId === SEARCH_ICON_ON_TAB_BAR}
        {...accessibilityProps}
      >
        <Dropdown.Toggle>
          <Button
            className={cx(styles.searchButton, classes?.searchButton)}
            {...internalProps.searchButton}
            {...searchButton} />
        </Dropdown.Toggle>
        {openDropdownId === SEARCH_ICON_ON_TAB_BAR ? (
          <Dropdown.Menu className={customStyles.dropdownMenu} align='right'>
            <SearchBlock {...searchBlock} />
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
    </>
  );

  let componentView: JSX.Element;

  switch (variantName) {
    // MANUAL OVERRIDE - separate case for Checkout
    case 'Checkout': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo} />
          </div>
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu} />
        </div>
      );
      break;
    }
    case 'WithoutSearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo} />
          </div>
          {/* MANUAL OVERRIDE STARTS */}
          <NavMenuList
            className={styles.navMenuList}
            {...internalProps.navMenuList}
            {...navMenuList}
            data={data}
            openDropdownId={openDropdownId}
            onTabHover={onTabHover}
            onTabLeave={onTabLeave}
            onTabFocus={onTabFocus}
            onKeyPress={onKeyPress}
          />
          {/* MANUAL OVERRIDE ENDS */}
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu} />
        </div>
      );
      break;
    }
    case 'SearchInNav': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo} />
          </div>
          {/* MANUAL OVERRIDE STARTS */}
          <NavMenuList
            className={styles.navMenuList}
            {...internalProps.navMenuList}
            {...navMenuList}
            data={data}
            openDropdownId={openDropdownId}
            onTabHover={onTabHover}
            onTabLeave={onTabLeave}
            onTabFocus={onTabFocus}
            onKeyPress={onKeyPress}
          />
          {/* MANUAL OVERRIDE ENDS */}
          {searchButtonView}
          <AccountMenu
            className={cx(styles.accountMenu, classes?.accountMenu)}
            {...internalProps.accountMenu}
            {...accountMenu} />
        </div>
      );
      break;
    }
    case 'SignedOut': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <Logo
              className={cx(styles.logo, classes?.logo)}
              {...internalProps.logo}
              {...logo} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TopNav;
