import React, { createContext, useCallback, useEffect, useState } from 'react';
import type { Props } from '../../lib/types';
import { startTimer } from './sessionTimeout';

export type UserIdleContextValue = {
  showWarning?: boolean,
  closeWarning: () => void;
  startSession: () => void;
};

const initialContext: UserIdleContextValue = {
  showWarning: false,
  closeWarning: () => { },
  startSession: () => { },
};

export const UserIdleContext = createContext<UserIdleContextValue>(initialContext);

export const UserIdleProvider: React.FC<Props> = ({ children }) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const closeWarning = useCallback(() => {
    setShowWarning(false);
  }, []);

  const onSessionWarning = useCallback(() => {
    setShowWarning(true);
  }, []);

  const onSessionLogout = useCallback(() => {
    window.location.assign('/logout');
  }, []);

  useEffect(() => {
    if (showWarning) {
      const logout = setTimeout(() => {
        onSessionLogout();
      }, 5 * 60 * 1000);
      return () => clearTimeout(logout);
    }
  }, [showWarning, onSessionLogout]);

  const startSession = useCallback(() => {
    startTimer(onSessionWarning, onSessionLogout);
  }, [onSessionWarning, onSessionLogout]);

  return (
    <UserIdleContext.Provider value={{
      showWarning,
      closeWarning,
      startSession,
    }}>
      {children}
    </UserIdleContext.Provider>
  );
};
