import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ButtonCombinedProps } from '../../atoms/Button';
import type { FooterPresenterProps, FooterProps, FooterTheme } from './Footer.types';
import { getFooterButtons, getFooterTheme } from './Footer.utils';

export const usePresenter = (props: FooterProps): FooterPresenterProps => {
  const { shouldExcludeFooterButtons = false } = props;

  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const theme: FooterTheme = useMemo(() => getFooterTheme({ pathname }), [pathname]);

  const footerButtons: ButtonCombinedProps[] = useMemo(
    () => getFooterButtons({ shouldExcludeFooterButtons, theme, search, navigate }),
    [shouldExcludeFooterButtons, theme, search, navigate],
  );

  return {
    ...props,
    theme,
    footerButtons,
  };
};
