import printJS from 'print-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateToMonthDayYear, formatNumberToLocaleString, isAndroidDevice, isFirefoxBrowser, isNumber, isSafariBrowser } from '../../../lib/util';
import { trackPageViewEvent, trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { MarketingOptinMlbProps } from '../../molecules/MarketingOptinMlb';
import type { DeliveryNote, OrderConfirmationPagePresenterProps, OrderConfirmationPageProps, OrderConfirmationPageState } from './OrderConfirmationPage.types';
import { getDeliveryNotes, getMarketingOptinMlbProps } from './OrderConfirmationPage.utils';

export const usePresenter = (props: OrderConfirmationPageProps): OrderConfirmationPagePresenterProps => {
  const location = useLocation();
  const navigate = useNavigate();

  const orderConfirmationPageState: OrderConfirmationPageState | undefined = location.state as OrderConfirmationPageState | undefined;

  useEffect(() => {
    if (!orderConfirmationPageState) {
      navigate('/');
    }
  }, [orderConfirmationPageState, navigate]);

  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (orderConfirmationPageState) {
      trackPageViewEvent(
        trackEvent,
        'Order Details/Confirmation Page',
      );
    }
  }, [orderConfirmationPageState, trackEvent]);

  const {
    accountCardImageUrl,
    accountCardLastFourDigits,
    listingDetailMetadata,
    orderResponse,
    remainingCashPrice: paidCashPrice,
    formattedRemainingCashPriceWithCents: formattedPaidCashPriceWithCents,
  } = orderConfirmationPageState ?? {};

  const {
    id: orderId,
    customer,
    event,
    ticket,
    loyalty,
    payment,
    has_integrated_inventory: hasIntegratedInventory,
    inventory_integration_details: inventoryIntegrationDetails,
  } = orderResponse ?? {};

  const {
    email_address: email,
    billing_address: billingAddress,
    shipping_address: shippingAddress,
  } = customer ?? {};

  const {
    paid: redeemedRewardUnits,
    currency: rewardUnitName,
  } = loyalty ?? {};

  const formattedRedeemedRewardUnitsWithUnitName: string | undefined = useMemo(
    () => isNumber(redeemedRewardUnits) ? formatNumberToLocaleString({ num: redeemedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true }) : undefined,
    [redeemedRewardUnits, rewardUnitName],
  );

  const deliveryNotes: DeliveryNote[] = useMemo(
    () => getDeliveryNotes({ listingDetailMetadata, email, billingAddress, shippingAddress }),
    [listingDetailMetadata, email, billingAddress, shippingAddress],
  );

  const { fulfillment } = ticket ?? {};
  const { estimated_delivery_date: apiEstimatedDeliveryDate } = fulfillment ?? {};

  const estimatedDeliveryDate: string | undefined = useMemo(
    () => apiEstimatedDeliveryDate ? formatDateToMonthDayYear(new Date(apiEstimatedDeliveryDate)) : undefined,
    [apiEstimatedDeliveryDate],
  );

  const marketingOptinMlbProps: MarketingOptinMlbProps | undefined = useMemo(
    () => getMarketingOptinMlbProps({ orderId, email, performers: event?.performers, hasIntegratedInventory, inventoryIntegrationDetails }),
    [orderId, email, event?.performers, hasIntegratedInventory, inventoryIntegrationDetails],
  );

  const [pdfUrl, setPdfUrl] = useState<string>('');

  const shouldDownloadPdf: boolean = useMemo(() => isAndroidDevice() || isSafariBrowser() || isFirefoxBrowser(), []);

  const onPrintButtonClicked = useCallback(() => {
    if (pdfUrl) {
      trackSelectContentEvent(
        trackEvent,
        'Order Details Page',
        'Order Detials',
        t('orderConfirmation.print'),
      );

      // On Android devices the blob PDF url will not open a print dialog in the same browser tab.
      // Also, in Safari and Firefox browsers print button shows empty as that is a known issue.
      // In order to make it work we are downloading the PDF file so user can open that and print using native print dialog.
      if (shouldDownloadPdf) {
        const a = document.createElement('a');
        a.href = pdfUrl;
        a.download = `VividSeats_Receipt_${orderResponse?.id}`;
        a.style.position = 'fixed';
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        // For the rest of the platforms (desktop, iOS)
        printJS(pdfUrl);
      }
    }
  }, [t, pdfUrl, shouldDownloadPdf, orderResponse?.id, trackEvent]);

  return {
    ...props,
    orderId,
    billingAddress,
    accountCardImageUrl,
    accountCardLastFourDigits,
    accountCardType: payment?.cc_type,
    listingDetailMetadata,
    paidCashPrice,
    formattedPaidCashPriceWithCents,
    redeemedRewardUnits,
    formattedRedeemedRewardUnitsWithUnitName,
    deliveryNotes,
    estimatedDeliveryDate,
    marketingOptinMlbProps,
    setPdfUrl,
    onPrintButtonClicked,
  };
};
