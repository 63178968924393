/* eslint-disable max-classes-per-file,class-methods-use-this */
import Cookie from 'js-cookie';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getAccountCardDetails } from '../../lib/accountUtils';
import { CLIENT_ID, DOMAIN, SESSION_COOKIE_NAME, SIGN_OUT_URL, UNAUTHORIZED_URL } from '../../lib/config';
import { areObjectsEqual } from '../../lib/objectUtils';
import type { Props } from '../../lib/types';
import { addQueryParam } from '../../lib/util';
import { UserIdleContext } from '../userIdle';
import { getAuthData, logoutUser, verifyToken } from './AuthApi';
import type { Account, AccountCardDetail, AuthContextValue, VerifyTokenPayload } from './types';

export const Logout: React.FC<{}> = () => {
  async function logout() {
    await logoutUser();
    // handle removing cookie & fallback cookie
    // https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Cookie.remove(SESSION_COOKIE_NAME, {
      domain: DOMAIN,
      secure: true,
    });
    Cookie.remove(`${SESSION_COOKIE_NAME}-legacy`, {
      domain: DOMAIN,
      secure: false,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.location.assign(SIGN_OUT_URL);
  }
  void logout();
  return null;
};

const getHashRedirectUrl = (url: string): number => {
  let hash = 0;
  let char, i;
  for (i = 0; i < url.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    char = url.charCodeAt(i);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
};

export const UnauthorizedRedirect: React.FC<{ redirectUrl: string; }> = ({ redirectUrl }) => {
  const originUrl = window.location.origin;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Cookie.remove(SESSION_COOKIE_NAME, {
    domain: DOMAIN,
    secure: true,
  });
  Cookie.remove(`${SESSION_COOKIE_NAME}-legacy`, {
    domain: DOMAIN,
    secure: false,
  });
  Cookie.set('redirect_url', redirectUrl);
  let unauthorizedUrl = UNAUTHORIZED_URL;
  const clientId = CLIENT_ID;
  const hashRedirectUrl = getHashRedirectUrl(originUrl);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  unauthorizedUrl = addQueryParam(unauthorizedUrl,
    {
      redirect_uri: encodeURIComponent(originUrl),
      state: hashRedirectUrl.toString(),
      client_id: clientId,
      scope: 'openid',
      response_type: 'code',
    });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  window.location.assign(unauthorizedUrl);
  return null;
};

const initialAuthContext: AuthContextValue = {
  error: undefined,
  loading: false,
  account: undefined,
  refetchAccount: () => { },
  setAccountExternally: () => { },
  verifyAccessToken: () => { },
  accountCardDetails: [],
  selectedAccountCardIndex: 0,
  selectAccountCardByIndex: () => { },
  selectedAccountCardDetail: undefined,
};

type AccountState = {
  account?: Account;
  loading?: boolean;
  initialLoading?: boolean;
  error?: Error;
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

const getSha1Hash = async (email: string): Promise<string> => {
  // Convert email to lowercase and remove spaces
  const formattedEmail = email.toLowerCase().replace(/\s/g, '');

  // In non-https contexts (e.g. local development) crypto.subtle is unavailable.
  // In these cases return formatted email.
  if (location.protocol === 'http:' && !crypto.subtle) {
    return formattedEmail;
  }

  // Generate SHA-1 hash
  const encoder = new TextEncoder();
  const data = encoder.encode(formattedEmail);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const cookie = Cookie.get(SESSION_COOKIE_NAME);

  const { startSession } = useContext(UserIdleContext);

  const [accountState, setAccountState] = useState<AccountState>({
    initialLoading: cookie ? true : undefined,
  });

  // Hash of the account email
  const [hashedEmail, setHashedEmail] = useState<string>('');

  useEffect(() => {
    const getAndSetHashedEmail = async () => {
      const email: string | undefined = accountState.account?.email;
      if (email) {
        const newHashedEmail: string = await getSha1Hash(email);
        setHashedEmail(newHashedEmail);
      }
    };

    void getAndSetHashedEmail();
  }, [accountState.account?.email]);

  const fetchAccount = useCallback(async () => {
    setAccountState((prevAccountState: AccountState) => {
      return !prevAccountState.initialLoading
        ? { account: prevAccountState.account, loading: true }
        : prevAccountState;
    });

    try {
      const account: Account = await getAuthData();

      setAccountState((prevAccountState: AccountState) => {
        const newAccountState: AccountState = { account };
        return areObjectsEqual(prevAccountState, newAccountState)
          ? prevAccountState
          : newAccountState;
      });

      startSession();
    } catch (e) {
      setAccountState({ error: e as Error });
    }
  }, [startSession]);

  const verifyAccessToken = useCallback(async (payload: VerifyTokenPayload) => {
    try {
      setAccountState({ loading: true });
      await verifyToken(payload);
      await fetchAccount();
    } catch (e) {
      setAccountState({ error: e as Error });
    }
  }, [fetchAccount]);

  useEffect(() => {
    const shouldFetch = accountState.initialLoading ||
      (!accountState.error && !accountState.loading && !accountState.account);
    if (cookie && shouldFetch) {
      void fetchAccount();
    }
  }, [cookie, accountState, fetchAccount]);

  const refetchAccount = useCallback(() => {
    void fetchAccount();
  }, [fetchAccount]);

  const setAccountExternally = useCallback((account: Account) => {
    setAccountState({ account });
  }, []);

  const accountCardDetails: AccountCardDetail[] = useMemo(
    () => getAccountCardDetails({ account: accountState.account }),
    [accountState.account],
  );

  // TODO: AK: Use selectedAccountCardIndex for multicard experience in the future
  // 0-based index of the selected account card
  const [selectedAccountCardIndex, setSelectedAccountCardIndex] = useState<number>(0);

  /** Function to select account card by 0-based index */
  const selectAccountCardByIndex = useCallback((newSelectedAccountCardIndex: number) => {
    setSelectedAccountCardIndex(newSelectedAccountCardIndex);
  }, []);

  // Reset 0-based index of the selected account card whenever account card details change
  useEffect(() => {
    setSelectedAccountCardIndex(0);
  }, [accountCardDetails]);

  /** Selected account card detail */
  const selectedAccountCardDetail: AccountCardDetail | undefined = useMemo(
    () => accountCardDetails[selectedAccountCardIndex],
    [accountCardDetails, selectedAccountCardIndex],
  );

  return (
    <AuthContext.Provider value={{
      ...accountState,
      loading: accountState.initialLoading || accountState.loading,
      refetchAccount,
      hashedEmail,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      verifyAccessToken,
      setAccountExternally,
      accountCardDetails,
      selectedAccountCardIndex,
      selectAccountCardByIndex,
      selectedAccountCardDetail,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
