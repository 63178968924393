import cx from 'classnames';
import React from 'react';

import Text from '../../atoms/Text';
import ButtonPairList from '../../molecules/ButtonPairList';
import SearchField from '../../molecules/SearchField';

import styles from './SearchContainer.module.scss';
import usePresenter from './SearchContainer.presenter';
import getProps from './SearchContainer.props';
import { SearchContainerCombinedProps } from './types';

const SearchContainer: React.FC<SearchContainerCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    searchField,
    label,
    buttonPairList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchContainer${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchField
            className={cx(styles.searchField, classes?.searchField)}
            {...internalProps.searchField}
            {...searchField} />
        </div>
      );
      break;
    }
    case 'ShowRecentSearches': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchField
            className={cx(styles.searchField, classes?.searchField)}
            {...internalProps.searchField}
            {...searchField} />
          <div className={cx(styles.recentSearch, classes?.recentSearch)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label} />
            <ButtonPairList
              className={cx(styles.buttonPairList, classes?.buttonPairList)}
              {...internalProps.buttonPairList}
              {...buttonPairList} />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchContainer;
