import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { CONTENT_ID, SPORTS_ROUTES, URLs } from '../../../lib/constants';
import { Logout } from '../../../modules/auth/AuthContext';
import Button from '../../atoms/Button';
import { TopDisclaimer } from '../../atoms/TopDisclaimer';
import AlertModal from '../../organisms/AlertModal';
import { Footer } from '../../organisms/Footer';
import TermsAndPrivacyModal from '../../organisms/TermsAndPrivacy';
import { TopNav } from '../../organisms/TopNav';
import { AllEventsPage } from '../../pages/AllEventsPage';
import { CheckoutPage } from '../../pages/CheckoutPage';
import { EventPage } from '../../pages/EventPage';
import { ExclusiveEventsPage } from '../../pages/ExclusiveEventsPage';
import { HomePage } from '../../pages/HomePage';
import { OrderConfirmationPage } from '../../pages/OrderConfirmationPage';
import { PerformerPage } from '../../pages/PerformerPage';
import { SearchResultsPage } from '../../pages/SearchResultsPage';
import SportsLandingPage from '../../pages/SportsLandingPage';
import { VenuePage } from '../../pages/VenuePage';

import OnBoardingModalLegacy from '../../../legacy/organisms/OnBoardingModal';
import TopNavLegacy from '../../../legacy/organisms/TopNav';
import ErrorPage from '../../../legacy/pages/ErrorPage';
import HelpPage from '../../../legacy/pages/HelpPage';
import MaintenancePage from '../../../legacy/pages/MaintenancePage';
import MyTicketsPage from '../../../legacy/pages/MyTicketsPage';

import customStyles from './Custom.module.scss';
import styles from './MainLayout.module.scss';
import { usePresenter } from './MainLayout.presenter';
import type { MainLayoutProps } from './MainLayout.types';
import stylesLegacy from './MainLayoutLegacy.module.scss';

export const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const {
    isAccountLoading,
    shouldShowUpdatedTopNav,
    shouldShowTopNav,
    shouldShowFooter,
    inMaintenanceMode,
    locationMaintenance,
    isTermsAndPrivacyModalOpen,
    termsAndPrivacyModalContent,
    closeTermsAndPrivacyModal,
    isOnBoardingModalOpen,
    closeOnBoardingModal,
    errorBlock,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const [topDisclaimer, setTopDisclaimer] = useState<string>();

  // Maintenance mode
  if (inMaintenanceMode) {
    return (
      <div className={cx(styles.mainLayout, className)}>
        <TopNav topNavType='LogoOnly' />
        <Routes>
          <Route path='/' element={<MaintenancePage maintenanceBlock={locationMaintenance} className={styles.page} />} />
        </Routes>
        <Footer shouldExcludeFooterButtons={true} />
      </div>
    );
  }

  if (isAccountLoading) {
    return null;
  }

  return <>
    <Button
      type='Text'
      style='ContainedWhite'
      size='Large'
      href={`#${CONTENT_ID}`}
      text={{ value: t('mainLayout.skipToMainContent') }}
      className={customStyles.skipToMainContent}
    />
    <div className={cx(stylesLegacy.mainLayout, styles.mainLayout, className)}>
      {/* Header */}
      {shouldShowTopNav && (
        shouldShowUpdatedTopNav
          ? (<>
            <TopDisclaimer topDisclaimer={topDisclaimer} />
            <TopNav />
            <div className={styles.topFixedSpacer} />
          </>)
          : (
            <div className={stylesLegacy.navContainer}>
              <TopNavLegacy className={stylesLegacy.topNav} />
            </div>
          )
      )}

      {/* All routes */}
      <Routes>
        <Route path='/' element={<HomePage className={styles.page} setTopDisclaimer={setTopDisclaimer} />} />

        <Route path={URLs.AllEventsPage} element={<AllEventsPage className={styles.page} />} />

        <Route path={URLs.ExclusiveEventsPage} element={<ExclusiveEventsPage className={styles.page} />} />

        {SPORTS_ROUTES.map(path => (
          <Route key={path} path={path} element={<SportsLandingPage className={styles.page} />} />
        ))}

        <Route path='/performers/:performerId' element={<PerformerPage className={styles.page} setTopDisclaimer={setTopDisclaimer} />} />

        <Route path='/events/:eventId' element={<EventPage className={styles.page} />} />

        <Route path='/venues/:venueId' element={<VenuePage className={styles.page} setTopDisclaimer={setTopDisclaimer} />} />

        <Route path={URLs.SearchResultsPage} element={<SearchResultsPage className={styles.page} />} />

        <Route path='/mytickets' element={<MyTicketsPage className={stylesLegacy.homePage} />} />

        <Route path='/checkout/:listingId' element={<CheckoutPage className={styles.page} />} />

        <Route path={URLs.OrderConfirmation} element={<OrderConfirmationPage className={styles.page} />} />

        <Route path='/logout' element={<Logout />} />

        <Route path='/help' element={<HelpPage className={stylesLegacy.homePage} />} />

        {/* Catch-all route for undefined paths */}
        <Route path='*' element={<ErrorPage errorBlock={errorBlock} className={stylesLegacy.homePage} />} />
      </Routes>

      {/* Footer */}
      {shouldShowFooter && <Footer />}
    </div>
    <AlertModal />
    <TermsAndPrivacyModal
      show={isTermsAndPrivacyModalOpen}
      modalBodyContent={termsAndPrivacyModalContent}
      handleClose={closeTermsAndPrivacyModal}
    />
    <OnBoardingModalLegacy
      show={isOnBoardingModalOpen}
      handleClose={closeOnBoardingModal}
    />
  </>;
};
