import React from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundErrorAsset from '../../../resources/legacyIcons/NotFoundError.svg';
import type { ErrorBlockCombinedProps } from './types';

const usePresenter = (props: ErrorBlockCombinedProps): ErrorBlockCombinedProps => {
  const { t } = useTranslation();
  const { title, message, button, image } = props;

  const content: React.ReactNode[] = [
    t('errors.notFound.message1'),
    // eslint-disable-next-line react/jsx-key
    <br />,
    t('errors.notFound.message2'),
  ];

  return {
    ...props,
    title: {
      ...props.title,
      value: title?.value || t('errors.notFound.title'),
    },
    message: {
      value: message?.value || content,
    },
    button: {
      ...props.button,
      text: {
        value: button?.text?.value ? button?.text?.value : t('common.backToHome'),
      },
      href: button?.href ? button.href : '/',
    },
    image: {
      imageSrc: image?.imageSrc || NotFoundErrorAsset,
    },
  };
};

export default usePresenter;
