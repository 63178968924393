import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ErrorBlockCombinedProps } from '../../legacy/blocks/ErrorBlock/types';
import { TERMS_MODAL } from '../../lib/constants';
import { getTranslation } from '../../lib/reactIUtils';
import CautionErrorAsset from '../../resources/icons/Caution.svg';
import GenericErrorAsset from '../../resources/icons/GenericError.svg';
import { termsHtml } from '../../resources/termsAndPrivacy/terms';
import { CP1ContactUsLink } from './constants';
import styles from './ErrorHandler.module.scss';
import { ErrorHandlerProps } from './types';

const usePresenter = (props: ErrorHandlerProps): ErrorHandlerProps => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  // when set to true it will log out user
  const [shouldLogout, setShouldLogout] = useState(false);

  const orderErrorBlockProps: ErrorBlockCombinedProps = {
    image: {
      imageSrc: GenericErrorAsset,
    },
    title: {
      value: t('notRedeemAbleAccount.title'),
    },
    message: {
      value: t('notRedeemAbleAccount.message'),
    },
    button: {
      className: styles.hideErrorButton,
    },
  };
  const notAccessibleErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: getTranslation('notAccessibleAccount.title'),
    },
    showMessage: false,
    showSignOutButton: true,
    button: {
      text: {
        value: t('notAccessibleAccount.button'),
      },
      href: CP1ContactUsLink,
    },
    signOutButton: {
      text: {
        value: t('notAccessibleAccount.signOutButton'),
      },
      onClick: () => {
        setShouldLogout(true);
      },
    },
  };
  const notEligibleCardErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: t('notEligibleCard.title'),
    },
    showMessage: false,
    showSignOutButton: false,
    button: {
      className: styles.hideErrorButton,
    },
  };
  const notAccessibleEmailErrorBlockProps: ErrorBlockCombinedProps = {
    classes: {
      image: styles.errorIcon,
      title: styles.notAccessibleErrorBlockTitle,
    },
    image: {
      imageSrc: CautionErrorAsset,
    },
    title: {
      value: t('notAccessibleEmailForAccount.title'),
    },
    showMessage: false,
    showSignOutButton: true,
    button: {
      className: styles.hideErrorButton,
    },
    signOutButton: {
      text: {
        value: t('notAccessibleAccount.signOutButton'),
      },
      onClick: () => {
        setShouldLogout(true);
      },
    },
  };
  const notFountErrorBlockProps: ErrorBlockCombinedProps = {
    ...orderErrorBlockProps,
    message: {
      value: t('notFountError.message'),
    },
  };
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const modalQuery = query.get('modal');
  let modalContent = '';
  if (modalQuery === TERMS_MODAL)
    modalContent = termsHtml;
  const [openModal, setModalOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    if (modalQuery) {
      query.delete('modal');
      navigate({
        search: query.toString(),
      });
    }
    setModalOpen(false);
  }, [history, modalQuery, query]);

  useEffect(() => {
    if (modalQuery === TERMS_MODAL) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [modalQuery]);

  const termsAndPrivacyProps = {
    modalBodyContent: modalContent,
    handleClose: handleClose,
    show: openModal,
  };

  return {
    ...props,
    shouldLogout,
    errorBlockProps: orderErrorBlockProps,
    termsAndPrivacyModalProps: termsAndPrivacyProps,
    notFountErrorBlockProps: notFountErrorBlockProps,
    notAccessibleEmailErrorBlockProps,
    notAccessibleErrorBlockProps,
    notEligibleCardErrorBlockProps,
  };
};

export default usePresenter;
