import i18n from '../locale/i18n';
import type { EventKeys } from './types';

/**
 * Tracks a content selection event.
 *
 * @param trackEvent - Function to track the event.
 * @param contentType - Type of the content selected.
 * @param clickLocation - Location where the content was clicked.
 * @param clickText - Optional text of the clicked content.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackSelectContentEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  contentType: string,
  clickLocation: string,
  clickText?: string,
  extraData?: Record<string, unknown>,
) => {
  const data = {
    content_type: contentType,
    click_location: clickLocation,
    click_text: clickText,
    ...extraData,
  };
  trackEvent('select_content', data);
};

/**
 * Tracks an error event.
 *
 * @param trackEvent - Function to track the event.
 * @param code - Error code.
 * @param message - Error message.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackErrorEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  code: string | number,
  message: string,
  extraData?: Record<string, unknown>,
) => {
  const errorPageUrl = window.location.href;
  const data = {
    error_code: code,
    error_message: message,
    error_url: errorPageUrl,
    ...extraData,
  };
  trackEvent('error', data);
};

/**
 * Tracks a page view event.
 *
 * @param trackEvent - Function to track the event.
 * @param pageType - Type of the page viewed.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackPageViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('page_view', data);
};

/**
 * Tracks a screen view event.
 *
 * @param trackEvent - Function to track the event.
 * @param pageType - Type of the page viewed.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackScreenViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('screen_view', data);
};

/** Tracks select_item and select_content analytics events on card click */
export const trackCardClickEvent = (params: {
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
  /** Page name to use for analytics on card click */
  pageName: string;
  /** Optional translation key for section title (e.g. carousel title) to use for analytics on card click */
  sectionTitleKey: string | undefined;
  /** Optional 1-based section index (e.g. carousel index) to use for analytics on card click */
  sectionIndex: number | undefined;
  /** 0-based card index to use for analytics on card click */
  cardIndex: number;
  /** Card name to use for analytics on card click */
  cardName: string;
}): void => {
  const { trackEvent, pageName, sectionTitleKey, sectionIndex, cardIndex, cardName } = params;

  trackEvent('select_item');

  trackSelectContentEvent(
    trackEvent,
    pageName,
    sectionTitleKey ? i18n.t(sectionTitleKey) : '',
    cardName,
    {
      carousel_location: sectionIndex,
      click_tile_location: cardIndex,
    },
  );
};
