import i18n from '../../../modules/locale/i18n';
import { Steps, type Step } from '../../pages/CheckoutPage';

// TODO: AK: Add unit tests
/** Gets formatted total cash price (with cents) and loyalty price for selected quantity in Quantity dropdown, e.g. $123,456.00 or 123,456 miles */
export const getFormattedTotalCashAndLoyaltyPrices = (params: {
  /** Validated 1-based step index. Undefined if step index is invalid, e.g. when it is non-numeric or it is not in range of 1-4. */
  step: Step | undefined;
  /** Formatted total price in cash (with cents), e.g. '$12,345.00' */
  formattedTotalPriceInCash: string;
  /** Formatted total price in reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  formattedTotalPriceInRewardUnits: string;
  /** Formatted string for the remaining cash price (with cents) as total cash price less cash value of applied reward units, e.g. '$12,345.00', '$12,345.67', etc. */
  formattedRemainingCashPriceWithCents: string;
  /** Formatted string for the number of applied reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  formattedAppliedRewardUnits: string;
  /** Lower cased loyalty unit name, e.g. 'miles', 'points', etc. */
  loyaltyUnitName: string;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
}): string => {
  const {
    step,
    formattedTotalPriceInCash,
    formattedTotalPriceInRewardUnits,
    formattedRemainingCashPriceWithCents,
    formattedAppliedRewardUnits,
    loyaltyUnitName,
    isPayWithRewardsOnly,
  } = params;

  if (isPayWithRewardsOnly) {
    return i18n.t('checkoutTotal.totalPriceInRewardUnits', { formattedTotalPriceInRewardUnits, loyaltyUnitName });
  }

  // For 'Address' and 'Confirm Order' steps display 'remaining cash price + applied reward units', e.g. '$80.00 + 2,000 miles'
  if (step && step > Steps.PaymentInfo) {
    return i18n.t('checkoutTotal.remainingCashPricePlusAppliedRewardUnits', { formattedRemainingCashPriceWithCents, formattedAppliedRewardUnits, loyaltyUnitName });

  }

  // For 'Contact Info' and 'Payment Info' steps display 'total price in cash or total price in reward units', e.g. '$80.00 or 2,000 miles'
  return i18n.t('checkoutTotal.totalPriceInCashOrTotalPriceInRewardUnits', { formattedTotalPriceInCash, formattedTotalPriceInRewardUnits, loyaltyUnitName });
};
