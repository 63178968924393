/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useMemo, useState } from 'react';
import type { SubCategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { SportsMenuTabListCombinedProps } from '../../molecules/SportsMenuTabList/types';
import type { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import type { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import type { SubmenuTabItemCombinedProps, SubmenuTabItemProps } from '../../molecules/SubmenuTabItem/types';
import { MenuItemPositions } from '../TopNav';
import styles from './SubmenuWithTabs.module.scss';
import type { SubmenuWithTabsCombinedProps } from './types';

const useSubmenuWithTabsPresenter = (props: SubmenuWithTabsCombinedProps): SubmenuWithTabsCombinedProps => {
  const { data, id, onItemClicked } = props;

  // Memoized current category and subcategories
  const currentCategory = useMemo(() => data?.find((category) => category.id === id), [id, data]);
  const subCategoryList = useMemo(() => currentCategory?.subCategories, [currentCategory]);
  const firstSubCategory = useMemo(() => currentCategory?.subCategories[0]?.name, [currentCategory]);

  const [hoveredSubCategory, setHoveredSubCategory] = useState<string | undefined>(firstSubCategory);
  const [focusPending, setFocusPending] = useState<boolean>(false);

  // Array to store subcategory refs
  const arrayOfSubCategoryRefs: HTMLDivElement[] = [];

  // Function to set subcategory ref
  const setSubCategoryRef = (element) => {
    if (element !== null) {
      arrayOfSubCategoryRefs.push(element);
    }
  };

  const arrayOfPerformerRefs: HTMLDivElement[] = [];
  const setPerformerRef = (element) => {
    if (element !== null) {
      arrayOfPerformerRefs.push(element);
    }
  };

  // Function to select subcategory
  const selectSubCategory = (subCatName: string | undefined) => {
    if (subCatName) {
      setHoveredSubCategory(subCatName);
    }
  };

  // Set the first subcategory to "Selected" state on category hover
  useEffect(() => {
    setHoveredSubCategory(firstSubCategory);
  }, [firstSubCategory]); // Trigger when firstSubCategory changes

  // Get the selected subcategory
  const selectedSubCategory: SubCategoryMenuItemState | undefined = subCategoryList?.find((league) => league.name === hoveredSubCategory);

  // Performers list for selected subcategory
  const performerList: SubmenuItemListCombinedProps = {
    submenuItems: selectedSubCategory?.performers?.map((performer, index): SubmenuItemCombinedProps => {
      const position: MenuItemPositions | undefined =
        index === (selectedSubCategory.performers?.length || 1) - 1 ? MenuItemPositions.LAST : undefined;
      return {
        text: {
          value: performer.name,
          colour: 'SubduedDark',
        },
        linkPath: performer.slug,
        elementRef: setPerformerRef,
        onItemClicked: onItemClicked,
        // SHIFT handler
        handleTab: (forward) => {
          if (forward && position === MenuItemPositions.LAST) {
            const selectedIndex = subCategoryList?.findIndex((league) => league.name === selectedSubCategory.name) || 0;
            if ((subCategoryList || [])[selectedIndex + 1]) {
              selectSubCategory((subCategoryList || [])[selectedIndex + 1].name);
              // using timeout asserts that the league tab is active before focusing it
              setTimeout(() => arrayOfSubCategoryRefs[selectedIndex + 1].focus());
            }
          }
        },
        classes: {
          text: performer.isBoldText ? styles.boldText : '',
        },
      } as SubmenuItemCombinedProps;
    }),
  };

  // Subcategory tab items
  const subCategoryTabItems: SubmenuTabItemCombinedProps[] | undefined =
    currentCategory?.subCategories.map((sport, index): SubmenuTabItemProps => {
      const position: MenuItemPositions | undefined = index === 0 ? MenuItemPositions.FIRST : undefined;
      const item: SubmenuTabItemCombinedProps = {
        state: hoveredSubCategory === sport.name ? 'Selected' : 'Default',
        text: {
          value: sport.name,
          colour: 'SubduedDark',
        },
        linkPath: sport.slug && sport.slug?.length > 0 ? sport.slug : undefined,
        onItemClicked: onItemClicked,
        id: sport.name, // TODO: replace with slug?
        elementRef: setSubCategoryRef,
        // SHIFT+TAB handler
        handleTab: (forward) => {
          if (!forward && position !== MenuItemPositions.FIRST && selectedSubCategory) {
            // have to re-enable previous tab when shift+tabbing
            const selectedIndex = subCategoryList?.findIndex((league) => league.name === selectedSubCategory.name) || 0;
            selectSubCategory((subCategoryList || [])[selectedIndex - 1].name);
            // set state to make next render focus on last element
            setFocusPending(true);
          }
        },
      };
      return item;
    });

  // Focus handling for shift+tabbing
  useEffect(() => {
    // forces focus to go to the last element, to be triggered when shift+tabbing
    if (focusPending && performerList.submenuItems && arrayOfPerformerRefs.length > 0) {
      setFocusPending(false);
      arrayOfPerformerRefs[(arrayOfPerformerRefs.length || 1) - 1].focus();
    }
  }, [focusPending]);

  return {
    ...props,
    sportsMenuTabList: {
      submenuTabItems: subCategoryTabItems,
      activeTab: hoveredSubCategory,
    } as SportsMenuTabListCombinedProps,
    submenuItemList: performerList,
    hoveredLeague: hoveredSubCategory,
    selectLeague: selectSubCategory,
  };
};

export default useSubmenuWithTabsPresenter;
