import { useCallback, useContext, useEffect, useRef, type ChangeEvent, type FocusEvent, type KeyboardEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import { useFocusState } from '../../atoms/TextInput';
import type { SearchBarPresenterProps, SearchBarProps } from './SearchBar.types';
import { handleClearButtonClick, handleTextInputClick, handleTextInputFocus, handleTextInputKeyDown } from './SearchBar.utils';

export const usePresenter = (props: SearchBarProps): SearchBarPresenterProps => {
  const { isTextInputFocusedOnMount, isTextInputFocusedOnClear, isTextInputBlurredOnFocus } = props;

  const { setActiveTopNavMenuType, searchQuery, setSearchQuery, searchSuggestions } = useContext(TopNavContext);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { trackEvent } = useAnalyticsManager();

  const { focusState: textInputFocusClassName, onClick, onFocus, onBlur } = useFocusState();

  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isTextInputFocusedOnMount && textInputRef.current) {
      textInputRef.current.focus();
      textInputRef.current.click();
    }
  }, [isTextInputFocusedOnMount]);

  // Callback for handling text input click events
  const onTextInputClick = useCallback(() => {
    handleTextInputClick({ onClick, trackEvent, setActiveTopNavMenuType, isTextInputBlurredOnFocus });
  }, [onClick, trackEvent, setActiveTopNavMenuType, isTextInputBlurredOnFocus]);

  // Callback for handling changes in text input value
  const onTextInputChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, [setSearchQuery]);

  // Callback for handling key down events in the text input
  const onTextInputKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    handleTextInputKeyDown({ event, searchQuery, searchSuggestions, setActiveTopNavMenuType, navigate, pathname, search });
  }, [searchQuery, setActiveTopNavMenuType, searchSuggestions, pathname, search, navigate]);

  // Callback for handling focus events on the text input
  const onTextInputFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    handleTextInputFocus({ isTextInputBlurredOnFocus, onFocus, event, setActiveTopNavMenuType });
  }, [onFocus, setActiveTopNavMenuType, isTextInputBlurredOnFocus]);

  // Callback for handling the clear button click event
  const onClearButtonClick = useCallback(() => {
    handleClearButtonClick({ setSearchQuery, setActiveTopNavMenuType, isTextInputFocusedOnClear, textInputRef });
  }, [setSearchQuery, setActiveTopNavMenuType, isTextInputFocusedOnClear, textInputRef]);

  return {
    ...props,
    textInputRef,
    textInputState: searchQuery ? 'Filled' : 'Empty',
    textInputValue: searchQuery,
    textInputClassName: textInputFocusClassName,
    onTextInputClick,
    onTextInputChanged,
    onTextInputKeyDown,
    onTextInputFocus,
    onTextInputBlur: onBlur,
    onClearButtonClick,
  };
};
