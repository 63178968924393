import { useCallback, useContext, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';
import { CHECKOUT_BRAINTREE_FORM } from '../../../../lib/constants';
import type { ListingDetailMetadata } from '../../../../lib/types';
import { delay, formatNumberToLocaleString, getNonExhaustiveCasesInSwitchStatementError, round } from '../../../../lib/util';
import { AuthContext } from '../../../../modules/auth';
import type { CardData } from '../../../../modules/partnership';
import { RewardPaymentOptions, type PaymentInfoType, type RewardPaymentOption, type RewardPaymentOptionErrorScope } from '../../../organisms/PaymentInfo';
import { INVALID_DECIMAL_CHARS_REGEX, INVALID_INTEGER_CHARS_REGEX } from '../CheckoutPage.constants';
import type { CheckoutPaymentInfoProps } from '../CheckoutPage.types';
import { getMaxRewardUnitsToBeUsed, getPaymentInfoType, validateEditedRewardUnitsToApply } from '../CheckoutPage.utils';

export const useCheckoutPaymentInfo = (params: {
  listingDetailMetadata: ListingDetailMetadata | undefined;
}): CheckoutPaymentInfoProps => {
  const {
    selectedAccountCardDetail: {
      accountCardImageUrl = '',
      accountCardLastFourDigits = '',
      rewardUnitsTotal = 0,
      rewardUnitsPerDollar = 1,
      rewardUnitName = '',
      isIntegerRewardUnit = false,
      formattedRewardUnitsTotal = '',
    } = {},
  } = useContext(AuthContext);

  const {
    eventMetadata: {
      isPayWithRewardsOnly = false,
    } = {},
    pricing: {
      total: totalPriceInCash = 0,
      currency = 'USD',
    } = {},
  } = params.listingDetailMetadata ?? {};

  /** Formatted total price in cash (with cents), e.g. '$12,345.00' */
  const formattedTotalPriceInCash: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInCash, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [totalPriceInCash],
  );

  /** Total price in reward units */
  const totalPriceInRewardUnits: number = useMemo(
    () => round({ amount: totalPriceInCash * rewardUnitsPerDollar, rounding: 'up', unitName: rewardUnitName }),
    [totalPriceInCash, rewardUnitsPerDollar, rewardUnitName],
  );

  /** Formatted total price in reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  const formattedTotalPriceInRewardUnits: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [totalPriceInRewardUnits, rewardUnitName],
  );

  /** Formatted total price in reward units (with unit name), e.g. '12,345 miles', '$12,345.67 cash rewards', etc. */
  const formattedTotalPriceInRewardUnitsWithUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true }),
    [totalPriceInRewardUnits, rewardUnitName],
  );

  /**
   * Maximum number of reward units that can be used.
   * It is a minimum of rewardUnitsTotal and totalPriceInRewardUnits.
   */
  const maxRewardUnitsToBeUsed: number = useMemo(
    () => getMaxRewardUnitsToBeUsed({ isPayWithRewardsOnly, rewardUnitsTotal, totalPriceInRewardUnits }),
    [isPayWithRewardsOnly, rewardUnitsTotal, totalPriceInRewardUnits],
  );

  /** Reward payment option: 'ApplyRewards' or 'DoNotApplyRewards' */
  const [rewardPaymentOption, setRewardPaymentOption] = useState<RewardPaymentOption | undefined>(undefined);

  /** Translation key for reward payment option error */
  const [rewardPaymentOptionErrorKey, setRewardPaymentOptionErrorKey] = useState<string | undefined>(undefined);

  /** Error scope for reward payment options: 'all', 'applyRewards' or 'doNotApplyRewards' */
  const [rewardPaymentOptionErrorScope, setRewardPaymentOptionErrorScope] = useState<RewardPaymentOptionErrorScope | undefined>(undefined);

  /** Number of reward units to apply. Preserved even if 'DoNotApplyRewards' option is selected. */
  const [rewardUnitsToApply, setRewardUnitsToApply] = useState<number>(0);
  const prevRewardUnitsToApplyRef = useRef<number>(0);

  /** Formatted number of reward units to apply (without unit name), e.g. '123,456' for miles or points, '$123,456' for cash rewards */
  const formattedRewardUnitsToApply: string = useMemo(
    () => formatNumberToLocaleString({ num: rewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [rewardUnitsToApply, rewardUnitName],
  );

  /** Number of applied reward units. Reset to 0 if 'DoNotApplyRewards' option is selected. */
  const [appliedRewardUnits, setAppliedRewardUnits] = useState<number>(0);

  useEffect(() => {
    setRewardUnitsToApply(maxRewardUnitsToBeUsed);

    if (isPayWithRewardsOnly) {
      setAppliedRewardUnits(maxRewardUnitsToBeUsed);
    }
  }, [maxRewardUnitsToBeUsed, isPayWithRewardsOnly]);

  /** Indicates whether user is editing the number of reward units to apply */
  const [isEditingRewardUnits, setIsEditingRewardUnits] = useState<boolean>(false);

  /**
   * Formatted string for the number of reward units to apply.
   * Used only in edit mode as a value for the text input.
   */
  const [editedRewardUnitsToApplyStr, setEditedRewardUnitsToApplyStr] = useState<string>('');

  /** Regex to remove invalid characters from the number of reward units to apply in edit mode */
  const editedRewardUnitsToApplyInvalidCharsRegex: RegExp = useMemo(
    () => isIntegerRewardUnit ? INVALID_INTEGER_CHARS_REGEX : INVALID_DECIMAL_CHARS_REGEX,
    [isIntegerRewardUnit],
  );

  /** Function to select reward payment option: 'ApplyRewards' or 'DoNotApplyRewards' */
  const selectRewardPaymentOption = useCallback((newRewardPaymentOption: RewardPaymentOption) => {
    if (newRewardPaymentOption === RewardPaymentOptions.DoNotApplyRewards && isEditingRewardUnits) {
      setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
      setRewardPaymentOptionErrorScope('applyRewards');
    } else if (rewardPaymentOption !== newRewardPaymentOption) {
      setRewardPaymentOption(newRewardPaymentOption);
      setRewardPaymentOptionErrorKey(undefined);
      setRewardPaymentOptionErrorScope(undefined);
      setAppliedRewardUnits(newRewardPaymentOption === RewardPaymentOptions.ApplyRewards ? rewardUnitsToApply : 0);
    }
  }, [isEditingRewardUnits, rewardPaymentOption, rewardUnitsToApply]);

  /**
   * Function to start editing of the number of reward units to apply.
   * Used as onClick handler for Edit button.
   */
  const startEditingRewardUnits = useCallback(() => {
    prevRewardUnitsToApplyRef.current = rewardUnitsToApply;
    setEditedRewardUnitsToApplyStr(formatNumberToLocaleString({ num: rewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }));
    setIsEditingRewardUnits(true);
  }, [rewardUnitsToApply, rewardUnitName]);

  /**
   * Function to store the edited version of the number of reward units to apply.
   * Used as onChange handler for the text input.
   */
  const onEditedRewardUnitsToApplyChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEditedRewardUnitsToApplyStr(event.target.value);
  }, []);

  /**
   * Function to validate and update the number of reward units to apply.
   * This updates Total Charge component to show remaining cash price and the number of reward units to be applied.
   * Change can still be cancelled by clicking on Cancel button.
   * Used as onBlur handler for the text input.
   */
  const onEditedRewardUnitsToApplyBlur = useCallback(() => {
    const newEditedRewardUnitsToApply: number = validateEditedRewardUnitsToApply({ editedRewardUnitsToApplyStr, isIntegerRewardUnit, maxRewardUnitsToBeUsed });
    setRewardUnitsToApply(newEditedRewardUnitsToApply);
    setEditedRewardUnitsToApplyStr(formatNumberToLocaleString({ num: newEditedRewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }));
  }, [editedRewardUnitsToApplyStr, isIntegerRewardUnit, maxRewardUnitsToBeUsed, rewardUnitName]);

  /**
   * Function to update applied reward units and exit edit mode.
   * This updates Total Charge component to show remaining cash price and the number of applied reward units.
   * Used as onClick handler for Save button.
   */
  const saveEditedRewardUnits = useCallback(() => {
    setAppliedRewardUnits(rewardUnitsToApply);
    setIsEditingRewardUnits(false);
    setRewardPaymentOptionErrorKey(undefined);
    setRewardPaymentOptionErrorScope(undefined);
  }, [rewardUnitsToApply]);

  /**
   * Function to revert changes to reward units to apply and exit edit mode.
   * This updates Total Charge component to show remaining cash price and the number of applied reward units.
   * Used as onClick handler for Cancel button.
   */
  const cancelEditingRewardUnits = useCallback(() => {
    setRewardUnitsToApply(prevRewardUnitsToApplyRef.current);
    setAppliedRewardUnits(prevRewardUnitsToApplyRef.current);
    setIsEditingRewardUnits(false);
    setRewardPaymentOptionErrorKey(undefined);
    setRewardPaymentOptionErrorScope(undefined);
  }, []);

  /** Cash value of reward units to apply, e.g. 60.00 */
  const cashValueOfRewardUnitsToApply: number = useMemo(
    () => round({ amount: Math.min(rewardUnitsToApply / rewardUnitsPerDollar, totalPriceInCash), rounding: 'down', isInteger: false }),
    [rewardUnitsToApply, rewardUnitsPerDollar, totalPriceInCash],
  );

  /** Formatted cash value of reward units to apply (with cents), e.g. $60.00 */
  const formattedCashValueOfRewardUnitsToApply: string = useMemo(
    () => formatNumberToLocaleString({ num: cashValueOfRewardUnitsToApply, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [cashValueOfRewardUnitsToApply],
  );

  /** Cash value of applied reward units, e.g. 60.00. Reset to 0 if 'DoNotApplyRewards' option is selected. */
  const cashValueOfAppliedRewardUnits: number = useMemo(
    () => round({ amount: Math.min(appliedRewardUnits / rewardUnitsPerDollar, totalPriceInCash), rounding: 'down', isInteger: false }),
    [appliedRewardUnits, rewardUnitsPerDollar, totalPriceInCash],
  );

  /** Remaining cash price as total cash price less cash value of applied reward units */
  const remainingCashPrice: number = useMemo(
    () => Math.max(0, totalPriceInCash - (isEditingRewardUnits ? cashValueOfRewardUnitsToApply : cashValueOfAppliedRewardUnits)),
    [totalPriceInCash, isEditingRewardUnits, cashValueOfRewardUnitsToApply, cashValueOfAppliedRewardUnits],
  );

  /**
   * Formatted string for the remaining cash price as total cash price less cash value of applied reward units, e.g. '$12,345', '$12,345.67', etc.
   * For whole dollar amounts it has no decimal places.
   * For non-whole dollar amounts it has 2 decimal places.
   */
  const formattedRemainingCashPrice: string = useMemo(
    () => formatNumberToLocaleString({ num: remainingCashPrice, unitName: 'dollars', shouldIncludeUnitName: false }),
    [remainingCashPrice],
  );

  /** Formatted string for the remaining cash price (with cents) as total cash price less cash value of applied reward units, e.g. '$12,345.00', '$12,345.67', etc. */
  const formattedRemainingCashPriceWithCents: string = useMemo(
    () => formatNumberToLocaleString({ num: remainingCashPrice, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [remainingCashPrice],
  );

  /** Formatted string for the number of applied reward units (with unit name), e.g. '12,345 miles', '$12,345.67 cash rewards', etc. */
  const formattedAppliedRewardUnitsWithUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: isEditingRewardUnits ? rewardUnitsToApply : appliedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true }),
    [isEditingRewardUnits, rewardUnitsToApply, appliedRewardUnits, rewardUnitName],
  );

  /** Formatted string for the number of applied reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  const formattedAppliedRewardUnitsWithoutUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: isEditingRewardUnits ? rewardUnitsToApply : appliedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [isEditingRewardUnits, rewardUnitsToApply, appliedRewardUnits, rewardUnitName],
  );

  /** Payment info type that determines what components to render */
  const paymentInfoType: PaymentInfoType = useMemo(
    () => getPaymentInfoType({
      isPayWithRewardsOnly,
      rewardUnitsTotal,
      rewardPaymentOption,
      cashValueOfAppliedRewardUnits,
      totalPriceInCash,
    }),
    [isPayWithRewardsOnly, rewardUnitsTotal, rewardPaymentOption, cashValueOfAppliedRewardUnits, totalPriceInCash],
  );

  /** Credit card data after Braintree validation */
  const [creditCardData, setCreditCardData] = useState<CardData | null>(null);

  /** Indicates whether user has entered valid credit card information (matching card number, expiry, CVV) */
  const isCreditCardInfoValidRef = useRef<boolean | undefined>(undefined);

  const validateCreditCardInfo = useCallback(async (): Promise<boolean> => {
    const creditCardInfoForm = document.getElementById(CHECKOUT_BRAINTREE_FORM);

    if (!creditCardInfoForm) {
      return false;
    }

    // Reset isCreditCardInfoValidRef flag before submitting credit card information form to Braintree
    isCreditCardInfoValidRef.current = undefined;

    // Submit credit card information form to Braintree
    (creditCardInfoForm as HTMLFormElement).requestSubmit();

    // Wait for Braintree to complete validation of the credit card information form
    for (let i = 0; i < 10; i += 1) {
      await delay(250);

      if (isCreditCardInfoValidRef.current === undefined) {
        continue;
      }

      return isCreditCardInfoValidRef.current;
    }

    return false;
  }, []);

  /** Function to check if the payment information is valid */
  const checkIsPaymentInfoValid = useCallback(async () => {
    switch (paymentInfoType) {
      case 'payWithRewardsOnly': return true;
      case 'creditCardOnly': {
        const isCreditCardInfoValid: boolean = await validateCreditCardInfo();
        return isCreditCardInfoValid;
      }
      case 'rewardSelectionOnly': {
        if (!rewardPaymentOption) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.requiredField');
          setRewardPaymentOptionErrorScope('all');
          return false;
        }

        if (isEditingRewardUnits) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
          setRewardPaymentOptionErrorScope('applyRewards');
          return false;
        }

        return true;
      }
      case 'rewardSelectionAndCreditCard': {
        if (isEditingRewardUnits) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
          setRewardPaymentOptionErrorScope('applyRewards');
          return false;
        }

        const isCreditCardInfoValid: boolean = await validateCreditCardInfo();
        return isCreditCardInfoValid;
      }
      default: {
        // If the code does not build here then it means that there are missing cases in the switch statement
        throw getNonExhaustiveCasesInSwitchStatementError(paymentInfoType);
      }
    }
  }, [paymentInfoType, rewardPaymentOption, isEditingRewardUnits, validateCreditCardInfo]);

  return {
    paymentInfoType,
    formattedRewardUnitsTotal,
    rewardUnitName,
    rewardPaymentOption,
    selectRewardPaymentOption,
    rewardPaymentOptionErrorKey,
    rewardPaymentOptionErrorScope,
    formattedRewardUnitsToApply,
    formattedCashValueOfRewardUnitsToApply,
    appliedRewardUnits,
    isEditingRewardUnits,
    editedRewardUnitsToApplyStr,
    editedRewardUnitsToApplyInvalidCharsRegex,
    startEditingRewardUnits,
    onEditedRewardUnitsToApplyChanged,
    onEditedRewardUnitsToApplyBlur,
    saveEditedRewardUnits,
    cancelEditingRewardUnits,
    formattedTotalPriceInCash,
    formattedTotalPriceInRewardUnits,
    formattedTotalPriceInRewardUnitsWithUnitName,
    remainingCashPrice,
    formattedRemainingCashPrice,
    formattedRemainingCashPriceWithCents,
    currency,
    formattedAppliedRewardUnitsWithUnitName,
    formattedAppliedRewardUnitsWithoutUnitName,
    accountCardImageUrl,
    accountCardLastFourDigits,
    creditCardData,
    setCreditCardData,
    isCreditCardInfoValidRef,
    checkIsPaymentInfoValid,
  };
};
