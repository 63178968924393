import React from 'react';
import { Navigate } from 'react-router-dom';
import { UnableToAccessEventErrorProps, URLs } from '../../../lib/constants';
import { MapProvider } from '../../../modules/map';
import { Spinner } from '../../atoms/Spinner';
import { GuestListEventPage } from '../GuestListEventPage';
import { NonGuestListEventPage } from '../NonGuestListEventPage';
import { usePresenter } from './EventPage.presenter';
import type { EventPagePresenterProps, EventPageProps } from './EventPage.types';

export const EventPage: React.FC<EventPageProps> = (props) => {
  const presenterProps: EventPagePresenterProps = usePresenter(props);

  const {
    isRequiredDataLoading,
    isEventValid,
    eventMetadata,
    areListingsLoading,
    listingsMetadata,
    unfilteredListings,
  } = presenterProps;

  if (isRequiredDataLoading) {
    return <Spinner />;
  }

  if (!isEventValid || !eventMetadata) {
    return (
      <Navigate
        to={URLs.ErrorPage}
        state={UnableToAccessEventErrorProps}
      />
    );
  }

  if (areListingsLoading && !listingsMetadata) {
    return <Spinner />;
  }

  return eventMetadata.shouldShowGuestListEventPage
    ? (
      <GuestListEventPage
        {...presenterProps}
        eventMetadata={eventMetadata}
        listingsMetadata={listingsMetadata}
      />
    )
    : (
      <MapProvider
        svgMapJsonUrl={listingsMetadata?.svgMapJsonUrl}
        staticImageMapUrl={listingsMetadata?.staticImageMapUrl ?? ''}
        filteredListings={listingsMetadata?.listings}
        unfilteredListings={unfilteredListings}
      >
        <NonGuestListEventPage
          {...presenterProps}
          eventMetadata={eventMetadata}
          listingsMetadata={listingsMetadata}
        />
      </MapProvider>
    );
};
