import type { Path } from 'react-router-dom';
import type { RegionFilterState } from '../../components/organisms/RegionFilter';
import { formatDateToApiDate, handleTagFilterForSearch } from '../../lib/util';
import type { Account } from '../auth/types';
import { getTodaysDate } from '../date/utils';
import type { CategoryMenuItemState } from '../navigation/Navigation.types';
import { updateCategoryMenuItemStates } from '../navigation/Navigation.utils';
import type { SearchSuggestionsQueryParams, TopNavPerformersResponse } from '../partnership';
import type { PathHistory, TopNavMenuTypeEnum } from './TopNavContext.types';

export const getSetPathHistoryFunc = (params: {
  path: Path;
}): ((prevPathHistory: PathHistory | undefined) => PathHistory) => {
  const { path } = params;

  return (prevPathHistory: PathHistory | undefined): PathHistory => {
    // If path name changed then save the previous path details
    if (prevPathHistory && prevPathHistory.currentPath.pathname !== path.pathname) {
      return {
        previousPath: prevPathHistory.currentPath,
        currentPath: path,
      };
    }

    return {
      previousPath: prevPathHistory?.previousPath,
      currentPath: path,
    };
  };
};

/**
 * Updates and retrieves category menu item states based on top navigation performers response and home page region filter state.
 * @returns {CategoryMenuItemState[]} An array of updated category menu item states.
 */
export const getCategoryMenuItemStates = (params: {
  /** Response containing top navigation performers */
  topNavPerformersResponse: TopNavPerformersResponse | undefined;
  /** State of the home page region filter */
  homePageRegionFilterState: RegionFilterState;
}): CategoryMenuItemState[] => {
  const {
    topNavPerformersResponse,
    homePageRegionFilterState,
  } = params;

  return topNavPerformersResponse
    ? updateCategoryMenuItemStates({
      topNavPerformersResponse,
      regionFilterState: homePageRegionFilterState,
    })
    : [];
};

/**
 * Checks if search suggestions are enabled based on the active menu type and search query.
 * @returns {boolean} True if search suggestions are enabled, otherwise false.
 */
export const checkAreSearchSuggestionsEnabled = (params: {
  /** Active top navigation menu type */
  activeTopNavMenuType: TopNavMenuTypeEnum | undefined;
  /** Search query string */
  searchQuery: string;
}): boolean => {
  const {
    activeTopNavMenuType,
    searchQuery,
  } = params;

  return activeTopNavMenuType === 'SearchMenu' && !!searchQuery.trim();
};

/**
 * Retrieves search suggestions query parameters if search suggestions are enabled.
 * @returns {SearchSuggestionsQueryParams | undefined} An object containing search suggestions query parameters or undefined if search suggestions are not enabled.
 */
export const getSearchSuggestionsQueryParams = (params: {
  /** Indicates whether search suggestions are enabled */
  areSearchSuggestionsEnabled: boolean;
  /** Search query string */
  searchQuery: string;
  /** Account object */
  account: Account | undefined;
}): SearchSuggestionsQueryParams | undefined => {
  const {
    areSearchSuggestionsEnabled,
    searchQuery,
    account,
  } = params;

  if (!areSearchSuggestionsEnabled) {
    return undefined;
  }

  return {
    q: searchQuery.trim(),
    tag_filter: handleTagFilterForSearch(account),
    date_start: formatDateToApiDate(getTodaysDate(), '2-digit'),
  };
};
