import { useEffect, useMemo, useState } from 'react';
import type { OverlayProps } from 'react-bootstrap';
import { usePopper, type Modifier } from 'react-popper';
import { formatNumberToLocaleString } from '../../../lib/util';
import { getPopperConfig } from '../../atoms/ToolTip';
import type { MapTooltipPresenterProps, MapTooltipProps } from './MapTooltip.types';
import { generateGetBoundingClientRect } from './MapTooltip.utils';

const virtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect(),
};

export const usePresenter = (props: MapTooltipProps): MapTooltipPresenterProps => {
  const { minPriceTooltipData } = props;

  const popperConfig: OverlayProps['popperConfig'] = useMemo(() => getPopperConfig(), []);

  const [popperElement, updatePopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, updateArrowElement] = useState<HTMLDivElement | null>(null);

  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(
    virtualElement as Element,
    popperElement,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(popperConfig?.modifiers ?? []) as Array<Partial<Modifier<any, any>>>,
      ],
      placement: 'top',
    },
  );

  useEffect(() => {
    if (update && minPriceTooltipData) {
      const { tooltipPosition: { x, y } } = minPriceTooltipData;
      virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
      void update();
    }
  }, [update, minPriceTooltipData]);

  const formattedMinPrice: string = useMemo(() => {
    return minPriceTooltipData?.minPrice
      ? formatNumberToLocaleString({ num: minPriceTooltipData.minPrice, unitName: 'dollars', shouldIncludeUnitName: false })
      : '';
  }, [minPriceTooltipData?.minPrice]);

  const shouldShowMapTooltip: boolean = useMemo(
    () => !!minPriceTooltipData && !!formattedMinPrice,
    [minPriceTooltipData, formattedMinPrice],
  );

  return {
    ...props,
    shouldShowMapTooltip,
    formattedMinPrice,
    updatePopperElement,
    updateArrowElement,
    popperStyles,
    attributes,
  };
};
