import { useCallback, useMemo } from 'react';
import { REGION_PARAM } from '../../../lib/constants';
import { useQueryParamFromUrl } from '../../../lib/util';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import type { Region } from '../../../modules/partnership';
import type { RegionFilterState } from './RegionFilter.types';
import { getRegionFilterStateFromUrl } from './RegionFilter.utils';

/**
 * Hook to extract region filter state from query parameters in the current URL.
 * Examples:
 * - /all-events?region=currentRegion
 * - /all-events?region=123
 */
export const useRegionFilterStateFromUrl = (params: {
  /** Map of allowed regions where key is region.id and value is region */
  regionsMap: Record<string, Region> | undefined;
}): RegionFilterState | undefined => {
  const { regionsMap } = params;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const clearRegionParamInUrl = useCallback(() => {
    appendQueryParamsToUrl({ [REGION_PARAM]: '' }, { replace: true });
  }, [appendQueryParamsToUrl]);

  const regionParam: string | undefined = useQueryParamFromUrl(REGION_PARAM);

  const regionFilterStateFromUrl: RegionFilterState | undefined = useMemo(
    () => getRegionFilterStateFromUrl({ regionsMap, regionParam, clearRegionParamInUrl }),
    [regionsMap, regionParam, clearRegionParamInUrl],
  );

  return regionFilterStateFromUrl;
};
