import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackScreenViewEvent } from '../../../modules/analytics/util';
import styles from './TermsAndPrivacy.module.scss';
import type { TermsAndPrivacyProps } from './types';

const TermsAndPrivacy: React.FC<TermsAndPrivacyProps> = ({
  modalBodyContent = '',
  handleClose,
  show,
}) => {
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (show) {
      trackScreenViewEvent(
        trackEvent,
        'Terms and Privacy',
      );
    }
  }, [show, trackEvent]);

  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className={styles.modalHeader}
        closeButton
        closeLabel=''>
      </Modal.Header>
      <div dangerouslySetInnerHTML={{ __html: modalBodyContent }}></div>
    </Modal>
  );
};

export default TermsAndPrivacy;
