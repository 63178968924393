import cx from 'classnames';
import React from 'react';

import { CATEGORIES_WITH_NO_SUB_CATEGORIES } from '../../../modules/navigation/Navigation.constants';
import Divider from '../../atoms/Divider';
import SportsMenuTabList from '../../molecules/SportsMenuTabList';
import SubmenuWithoutTabs from '../SubmenuWithoutTabs';
import SubmenuWithTabs from '../SubmenuWithTabs';
import styles from './SubmenuWithDoubleTabs.module.scss';
import useSubmenuWithDoubleTabsPresenter from './SubmenuWithDoubleTabs.presenter';
import getProps from './SubmenuWithDoubleTabs.props';
import { SubmenuWithDoubleTabsCombinedProps } from './types';

const SubmenuWithDoubleTabs: React.FC<SubmenuWithDoubleTabsCombinedProps> = (props) => {
  const {
    className,
    classes,
    sportsMenuTabList,
    dividerOne,
    // MANUAL OVERRIDE STARTS
    activeMenu,
    submenuWithTabs,
    submenuWithoutTabs,
    // MANUAL OVERRIDE ENDS
  } = useSubmenuWithDoubleTabsPresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithDoubleTabs;

  // MANUAL OVERRIDE STARTS
  const isCategoryWithoutSubcategories = CATEGORIES_WITH_NO_SUB_CATEGORIES.includes(String(activeMenu?.id));

  const activeMenuView = isCategoryWithoutSubcategories ? <SubmenuWithoutTabs {...submenuWithoutTabs} /> : <SubmenuWithTabs {...submenuWithTabs} />;
  // MANUAL OVERRIDE ENDS

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <SportsMenuTabList
            className={cx(styles.sportsMenuTabList, classes?.sportsMenuTabList)}
            {...internalProps.sportsMenuTabList}
            {...sportsMenuTabList} />
        </div>
        <Divider
          className={cx(styles.dividerOne, classes?.dividerOne)}
          {...internalProps.dividerOne}
          {...dividerOne} />
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          {activeMenuView}
        </div>
      </div>
    </div>
  );
};

export default SubmenuWithDoubleTabs;
