import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryMenuItemOnBackButton, getInitialCategoryItems, getSubCategoriesOnBackButton, populateWithChildren } from '../../../components/organisms/NavMenuList/NavMenuList.utils';
import { SPORTS_ROUTES, URLs } from '../../../lib/constants';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import categoryEvents from '../../../modules/navigation/CategoryEventsLegacy.json';
import { EXCLUSIVE_ID } from '../../../modules/navigation/Navigation.constants';
import type { CategoryMenuItemState, MobileMenuItem, MobileMenuState } from '../../../modules/navigation/Navigation.types';
import type { TabCombinedProps } from '../../atoms/Tab/types';
import type { NavMenuItemCombinedProps } from '../../molecules/NavMenuItem/types';
import type { NavMenuListCombinedProps } from './types';

const usePresenter = (props: NavMenuListCombinedProps): NavMenuListCombinedProps => {
  const { openDropdownId, closeSearchBlock } = props;
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  const data: CategoryMenuItemState[] = JSON.parse(JSON.stringify(categoryEvents));
  const initialMenuState: MobileMenuState = { items: getInitialCategoryItems(data) };
  const [menuState, setMenuState] = useState(initialMenuState);

  const onItemClick = (id: string) => {
    // check for case when selecting to reverse
    if (id === menuState.selected?.id) {
      if (menuState.selected?.type === 'subCategory') {
        return setMenuState({
          selected: getCategoryMenuItemOnBackButton(menuState, data),
          items: getSubCategoriesOnBackButton(data, menuState),
        });
      }
      if (menuState.selected?.type === 'category') {
        return setMenuState(initialMenuState);
      }
    }

    const selectedItem = menuState.items.find((item) => item.id === id);
    let items: MobileMenuItem[] | undefined;
    if (selectedItem) {
      // display the submenu items for particular category or league
      items = populateWithChildren(data, selectedItem.type, id, menuState);
    }
    setMenuState({ selected: selectedItem, items: items ? items : [] });
  };

  // construct the desktop navigation tabs
  const headerTabs: TabCombinedProps[] = data?.map((category) => ({
    id: category.id,
    type: openDropdownId === category.id ? 'Active' : 'Default',
    text: {
      value: category.name,
    },
  })) || [];
  const sortedTabs = headerTabs.sort((x, y) => { return x.id === EXCLUSIVE_ID ? -1 : y.id === EXCLUSIVE_ID ? 1 : 0; });

  // construct the mobile navigation links
  const menuListItems: NavMenuItemCombinedProps[] = [];
  if (menuState.selected) {
    // push item that allows user to go back
    menuListItems.push({
      type: 'Back',
      title: {
        value: menuState.selected.title,
      },
      onClick: () => {
        if (menuState.selected) {
          onItemClick(menuState.selected.id);
        }
      },
    });
    const {
      selected: { id, title, type },
    } = menuState;
    // add extra menu list item for certain leagues with 'All' prefix
    if (type === 'subCategory' && SPORTS_ROUTES.includes(id)) {
      menuListItems.push({
        type: 'Next',
        title: {
          value: `${t('search.all')} ${title}`,
        },
        linkPath: `${id}`,
      });
    }
  }

  menuState.items.forEach((item) => {
    menuListItems.push({
      type: 'Next',
      title: {
        value: item.title,
      },
      onClick:
        item.type === 'item'
          ? () => {
            if (item.id.startsWith(URLs.ExclusiveEventsPage) && item.title !== 'Presales') {
              trackEvent('select_content_c1x_navigation-menu');
            }

            setMenuState(initialMenuState);
            if (closeSearchBlock) closeSearchBlock();
          }
          : () => {
            onItemClick(item.id);
          },
      newTab: item.title === 'Presales',
      linkPath: item.type === 'item' ? item.id : undefined,
    });
  });

  return {
    ...props,
    tabs: sortedTabs,
    data,
    navMenuItems: menuListItems,
  };
};

export default usePresenter;
