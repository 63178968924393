import type { OverlayProps } from 'react-bootstrap';
import type { MobileDeviceInfo } from '../../../lib/types';
import { getMobileDeviceInfo } from '../../../lib/util';

// TODO: AK: Add unit tests
export const getPopperConfig = (): OverlayProps['popperConfig'] => {
  const { hostname } = location;

  const mobileDeviceInfo: MobileDeviceInfo | undefined = getMobileDeviceInfo();
  if (mobileDeviceInfo && (hostname.includes('.com') || hostname.includes('.dev'))) {
    const { isIOS, isIPad, isAndroid } = mobileDeviceInfo;

    let offsetY: number = 0;

    // TODO: Investigate why only on production and only on mobile devices the tooltips are offset vertically by 55px on Android, 63px on iPads and 80px on other iOS.
    // Only on production and only on mobile devices the tooltips are offset vertically by 55px on Android, 63px on iPads and 80px on other iOS.
    // Fix it by adding a negative offset.
    if (isAndroid) {
      offsetY = -55;
    } else if (isIPad) {
      offsetY = -63;
    } else if (isIOS) {
      offsetY = -80;
    }

    return {
      modifiers: [
        { name: 'offset', enabled: true, options: { offset: [0, offsetY] } },
      ],
    };
  }

  return undefined;
};
