import cx from 'classnames';
import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapToolTip } from 'react-bootstrap';
import { usePresenter } from './ToolTip.presenter';
import './ToolTip.scss';
import type { ToolTipPresenterProps, ToolTipProps } from './ToolTip.types';

const renderToolTip = (props: ToolTipPresenterProps) => {
  const { toolTipId, textKey, text, ariaLabelKey, ariaLabel, popperConfig, theme, className, ...otherToolTipProps } = props;

  return (
    <BootstrapToolTip
      id={toolTipId}
      role='tooltip'
      aria-label={ariaLabel}
      className={cx(`${theme}-tooltip`, className)}
      {...otherToolTipProps}
    >
      {text}
    </BootstrapToolTip>
  );
};

export const ToolTip: React.FC<ToolTipProps> = (props) => {
  const presenterProps: ToolTipPresenterProps = usePresenter(props);

  const {
    toolTipId,
    text,
    ariaLabel,
    placement,
    popperConfig,
    children,
  } = presenterProps;

  return (
    <OverlayTrigger
      key='bottom'
      placement={placement}
      transition={false}
      overlay={renderToolTip(presenterProps)}
      popperConfig={popperConfig}
    >
      {({ ...toolTipProps }) => (
        <button
          aria-label={ariaLabel}
          aria-describedby={toolTipId}
          {...toolTipProps}
        >
          {children}
          <div
            id={toolTipId}
            className='tooltip-accessibility-content'
          >
            {text}
          </div>
        </button>
      )}
    </OverlayTrigger>
  );
};
