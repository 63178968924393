import type { HostedFieldsTokenizePayload } from 'braintree-web';
import { useCallback, useContext, useEffect, useRef, useState, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IS_DEV } from '../../../lib/config';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { BraintreeContext } from '../../../modules/braintree';
import { binArray, dummyLastFour } from './CreditCardInfo.constants';
import type { CreditCardInfoPresenterProps, CreditCardInfoProps } from './CreditCardInfo.types';

export const usePresenter = (props: CreditCardInfoProps): CreditCardInfoPresenterProps => {
  const { accountCardLastFourDigits, setCreditCardData, isCreditCardInfoValidRef } = props;

  const { instance, isLoading } = useContext(BraintreeContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  useEffect(() => {
    if (instance) {
      instance.clear('number');
      instance.clear('expirationDate');
      instance.clear('cvv');
    }
  }, [instance]);

  const ioBlackBoxRef = useRef<HTMLInputElement>(null);

  const [isCreditCardInfoValid, setIsCreditCardInfoValid] = useState<boolean>(true);

  const onCardTokenized = useCallback((payload: HostedFieldsTokenizePayload | null, hasValidationError: boolean) => {
    trackSelectContentEvent(
      trackEvent,
      'CheckoutPage',
      'Card Details',
      t('cardInfo.button'),
    );

    if (hasValidationError) {
      return;
    }

    setCreditCardData({
      payload,
      ioBlackBox: ioBlackBoxRef.current?.value || null,
    });
  }, [t, trackEvent, setCreditCardData]);

  const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();

      if (!instance) {
        return;
      }

      const tokenPayload: HostedFieldsTokenizePayload = await instance.tokenize({
        // TODO: Confirm if we need to pass these fields in:
        // cardholderName: '',
        // billingAddress: {},
      });

      if (tokenPayload) {
        if ((tokenPayload.details?.lastFour !== accountCardLastFourDigits && (!IS_DEV && !dummyLastFour.includes(tokenPayload.details?.lastFour))) || !binArray.includes(tokenPayload.details?.bin)) {
          isCreditCardInfoValidRef.current = false;
          setIsCreditCardInfoValid(false);
          onCardTokenized(null, true);
        } else {
          isCreditCardInfoValidRef.current = true;
          setIsCreditCardInfoValid(true);
          onCardTokenized(tokenPayload, false);
        }
      }
    } catch (err) {
      isCreditCardInfoValidRef.current = false;
      setIsCreditCardInfoValid(false);
    }
  }, [instance, accountCardLastFourDigits, isCreditCardInfoValidRef, onCardTokenized]);

  return {
    ...props,
    isLoading,
    ioBlackBoxRef,
    isCreditCardInfoValid,
    onSubmit,
  };
};
