import type { TFunction } from 'i18next';
import { useEffect, useMemo, useState, type ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { TextColourEnum } from '../components/atoms/Text';
import type { Account } from '../modules/auth/types';
import { getTodaysDate } from '../modules/date/utils';
import type { DeliveryOption, Event, EventsQueryParams, Listing, ListingDetails, ListingsQueryParams, Venue } from '../modules/partnership';
import { BREAKPOINTS, C1_BROKER_ID, EVENT_TAGS, EXCLUSIVE_CATEGORY, IsIntegerRewardUnit, MAX_INTEGER, MIN_INTEGER } from './constants';
import { checkAreTicketsLow, checkIsEventExclusive, checkIsEventSoldOut } from './eventUtils';
import type { EventStatus, HtmlElementSize, ListingDetailMetadata, MobileDeviceInfo, ScrollOffset, UnitDisplaySettings, WindowSize } from './types';

export interface TicketListingMapping {
  ticket_listing: string;
  ticket_details: string;
  delivery_method: string;
  confimation_page: string[];
  requiresShippingInfo?: string;
}

/**
 * Retrieves two-digit country code for the given three-digit country code.
 * If the provided country code is not found in the predefined map, it returns the original code.
 *
 * @param {string} code Three-digit country code.
 * @returns {string} Two-digit country code or the original code if not found.
 */
export const getTwoDigitsCountryCode = (code: string): string => {
  /** Map for converting three-digit country codes to two-digit country codes */
  const twoDigitsCountryCodeMap: Record<string, string> = {
    USA: 'US',
    CAN: 'CA',
  };

  // Return the corresponding two-digit code or the original code if not found
  return twoDigitsCountryCodeMap[code.toUpperCase()] || code;
};

/**
 * Checks if the provided country code corresponds to the US.
 *
 * @param {string} countryCode Country code to check (either 'USA' or 'US').
 * @returns {boolean} True if the country code matches 'USA' or 'US', false otherwise.
 */
export const handleCountryCodeUS = (countryCode: string): boolean => {
  // Check if the country code (in uppercase) matches 'USA' or 'US'
  return ['USA', 'US'].includes(countryCode.toUpperCase());
};

// TODO: ND: Add unit tests
/**
 * Trims the postal code to five digits if the country code corresponds to the US.
 * If the country code is not 'USA' or 'US', the original postal code is returned.
 * @returns {string} Truncated postal code (if US) or the original postal code.
 */
export const getFiveDigitsZipCode = (params: {
  /** Country code to determine if truncation is necessary */
  countryCode: string;
  /** Postal code to potentially truncate */
  postalCode: string;
}): string => {
  const { countryCode, postalCode } = params;

  // If the country code corresponds to the US, return the first 5 digits of the postal code
  if (handleCountryCodeUS(countryCode)) {
    return postalCode.substring(0, 5);
  }

  // Return the full postal code for non-US countries
  return postalCode;
};

export const getQueryParams = (url: string): Record<string, string> => {
  const results: Record<string, string> = {};
  const queryParams = url.split('?');
  if (queryParams.length > 1) {
    const params = queryParams[1].split('&');

    for (let i = 0, len = params.length; i < len; i += 1) {
      const parts = params[i].split('=');
      if (parts.length > 1) {
        const [key, value] = parts;
        results[key] = value;
      }
    }
  }
  return results;
};

export const removeIgnoredParams = (params: Record<string, string>, ignoredParams: string[]): Record<string, string> => {
  ignoredParams.forEach(param => {
    params[param] = '';
  });

  return params;
};

export const mapQueryString = (payload: Record<string, unknown>, options?: { skipEmptyParams: boolean; }): string => {
  // By default do not skip empty params
  const { skipEmptyParams = false } = options ?? {};

  const payloadEntries: [string, unknown][] = skipEmptyParams
    ? Object.entries(payload).filter(([key, value]) => key && value)
    : Object.entries(payload);

  return payloadEntries
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const addQueryParam = (
  url: string,
  payload: Record<string, string>,
  options?: { skipEmptyParams: boolean; },
): string => {
  let queryParams = getQueryParams(url);
  queryParams = {
    ...queryParams,
    ...payload,
  };
  const queryString = mapQueryString(queryParams, options);
  const baseUrl = url.split('?')[0];
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};

export type DatePartsType = {
  [key in Intl.DateTimeFormatPartTypes]?: string;
};

export const formatDateToMonthDay = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}`;
};

export const formatDateToApiDate = (date: Date, format: 'numeric' | '2-digit' = 'numeric'): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: format,
    month: format,
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
};

export const formatDateToMonthDayYear = (date: Date, format: 'numeric' | '2-digit' = 'numeric'): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: format,
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}, ${dateParts.year}`;
};

export const getLocalDate = (time: string): string => {
  const localDate = new Date(time).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  return localDate;
};

export const getLocalTime = (time: string): string => {
  const localTime = new Date(time).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  return localTime;
};

export enum DateFormats {
  // example: Fri, Jul 19 at 7:30pm
  TICKETINFO = 'ticketInfoFormat',
  // example: Jul 18, 2020
  CONFIRMATION = 'confirmationFormat',
}

/**
 * Formats a date to one of the following formats:
 * TICKETINFO:
 * Fri, Jul 19 at 7:30pm
 * CONFIRMATION:
 * Jul 18, 2020
 */
export function formatDate(
  dateAndTime: Date,
  t: TFunction,
  format: DateFormats,
): string {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  })
    .formatToParts(dateAndTime)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return t(`ticketInfo.dateTemplate.${format}`, {
    day_week: dateParts.weekday,
    month: dateParts.month,
    day: dateParts.day,
    hour: dateParts.hour,
    minute: dateParts.minute,
    period: dateParts.dayPeriod?.toLocaleLowerCase(),
    year: dateParts.year,
  });
}

export const isValidUrl = (input: string): boolean => {
  let url;
  try {
    url = new URL(input);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const handleDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}`;
};

export const handleTimeFormat = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};

export const setEventDescription = (event: Event, mobile = false): string => {
  const eventDate = handleDateFormat(event.local_date);
  const eventTime = handleTimeFormat(event.local_date);

  if (mobile) {
    return ` ${eventDate} at ${eventTime} \n ${event.venue.name}\n ${event.venue.city} ${event.venue.state_code}`;
  }
  return ` ${eventDate} at ${eventTime} - ${event.venue.name}, ${event.venue.city} ${event.venue.state_code}`;
};

export const getWeekday = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(new Date(date));
};

export const getWeekdayAndTime = (date: Date): string => {
  return `${getWeekday(date)} ${getLocalTime(date.toString())}`;
};

export const getYear = (date: Date): string => {
  return String(new Date(date).getFullYear());
};

export enum CheckoutSteps {
  CUSTOMERINFO = 'customerInfo',
  BILLINGINFO = 'billingInfo',
  SHIPPINGINFO = 'shippingInfo',
  PAYMENTINFO = 'paymentInfo',
  CONFIRMATION = 'confirmation',
}

/**
 * Gets loyalty unit tag for account's loyalty program, e.g. C1_MILES, C1_CASH_REWARDS
 * @returns {string} Loyalty unit tag for account's loyalty program, e.g. C1_MILES, C1_CASH_REWARDS
 */
export const getLoyaltyUnitTag = (account: Account | undefined): string | undefined => {
  switch (account?.loyalty_program?.loyalty_unit_name?.toLowerCase().replace(/ /g, '_')) {
    case 'miles': return 'C1_MILES';
    case 'cash':
    case 'cash_rewards':
    case 'rewards_cash': return 'C1_CASH_REWARDS';
    // TODO: AK: Confirm that loyalty unit tag for Points is C1_POINTS
    case 'points': return 'C1_POINTS';
    default: return undefined;
  }
};

/**
 * Gets program type tag for account's loyalty program, e.g. VENTURE
 * @returns {string} Program type tag for account's loyalty program, e.g. VENTURE
 */
export const getProgramTypeTag = (account: Account | undefined): string | undefined => {
  return account?.loyalty_program?.program_type_tag
    || account?.loyalty_program?.program_type?.trim().toUpperCase().replace(/\s/g, '_')
    || undefined;
};

/**
 * Gets processing network tag for account's loyalty program, e.g. VISA
 * @returns {string} Processing network tag for account's loyalty program, e.g. VISA
 */
export const getProcessingNetworkTag = (account: Account | undefined): string | undefined => {
  return account?.loyalty_program?.processing_network?.toUpperCase() || undefined;
};

export const capitalize = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export const titleCase = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};

/**
 * Function that appends tag and secondary tag filter parameters for /events API request.
 * @param {EventsQueryParams} params - Input parameters to append tag and secondary tag filter parameters to.
 * @param {Account} account - User account.
 * @param {boolean=} includeSecondaryTagFilter - (Optional) If true, it adds 'NOT C1_EXCLUSIVE' secondary tag filter.
 * @param {boolean=} isC1XLandingPage - (Optional) If true, for Sports only it adds 'NOT COLLAPSED' tag filter.
 * @param {boolean=} shouldExcludeCollapsedEvents - (Optional) If true, it adds 'NOT COLLAPSED' tag to instruct the back end to exclude events that should not be displayed on the front end.
 *                                                  E.g. it is required on the Home Page (Exclusive Events section) to suppress a potentially large number of exclusive MLB events.
 *                                                  So that the user could see other types of exclusive events.
 * @returns {EventsQueryParams} Input parameters with appended tag and secondary tag filter parameters.
 */
export const handleTagFilter = (
  params: EventsQueryParams,
  account: Account,
  includeSecondaryTagFilter?: boolean,
  isC1XLandingPage?: boolean,
  shouldExcludeCollapsedEvents?: boolean,
): EventsQueryParams => {
  // Add default tags to tag_filter
  const tags: string[] = [EVENT_TAGS.Exclusive];

  const loyaltyUnitTag: string | undefined = getLoyaltyUnitTag(account);
  if (loyaltyUnitTag) {
    tags.push(loyaltyUnitTag);
  }

  const programTypeTag: string | undefined = getProgramTypeTag(account);
  if (programTypeTag) {
    tags.push(programTypeTag);
  }

  const processingNetworkTag: string | undefined = getProcessingNetworkTag(account);
  if (processingNetworkTag) {
    tags.push(processingNetworkTag);
  }

  if (shouldExcludeCollapsedEvents) {
    tags.push(EVENT_TAGS.NotCollapsed);
  }

  let shouldClearCategoryParam: boolean = false;

  for (const param in params) {
    const paramValue = params[param] as string;
    switch (paramValue) {
      case EXCLUSIVE_CATEGORY:
        shouldClearCategoryParam = true;
        break;
      case EVENT_TAGS.CultureArts:
      case EVENT_TAGS.Dining:
      case EVENT_TAGS.Music:
      case EVENT_TAGS.PromotedProduction:
      case EVENT_TAGS.Travel:
        shouldClearCategoryParam = true;
        tags.push(paramValue);
        break;
      case EVENT_TAGS.Sports:
        shouldClearCategoryParam = true;
        tags.push(paramValue);
        // Do not add NOT COLLAPSED tag twice
        if (isC1XLandingPage && !shouldExcludeCollapsedEvents) {
          tags.push(EVENT_TAGS.NotCollapsed);
        }
        break;
      default:
        break;
    }
  }

  const updatedParams: EventsQueryParams = {
    ...params,
    tag_filter: tags.join(' AND '),
    ...(shouldClearCategoryParam ? { category: '' } : {}),
    ...(includeSecondaryTagFilter ? { secondary_tag_filter: EVENT_TAGS.NotExclusive } : {}),
    ...(!params.date_start ? { date_start: formatDateToApiDate(getTodaysDate(), '2-digit') } : {}),
  };

  return updatedParams;
};

export const handleTagFilterForListings = (
  listingsQueryParams: ListingsQueryParams,
  account: Account,
): ListingsQueryParams => {
  const programTypeTag: string = getProgramTypeTag(account) || '';

  return {
    ...listingsQueryParams,
    tag_filter: programTypeTag,
  };
};

export const handleTagFilterForSearch = (account: Account | undefined): string => {
  const tags: string[] = [];

  const loyaltyUnitTag: string | undefined = getLoyaltyUnitTag(account);
  if (loyaltyUnitTag) {
    tags.push(loyaltyUnitTag);
  }

  const programTypeTag: string | undefined = getProgramTypeTag(account);
  if (programTypeTag) {
    tags.push(programTypeTag);
  }

  const processingNetworkTag: string | undefined = getProcessingNetworkTag(account);
  if (processingNetworkTag) {
    tags.push(processingNetworkTag);
  }

  return tags.join(' AND ');
};

export enum RewardUnit {
  MILES = 'MILES',
  CASH_REWARDS = 'REWARDS CASH',
  CASH = 'CASH',
  POINTS = 'POINTS',
}

export const isValidPhoneNumber = (value: string): boolean => {
  return /^\d{10}$/.test(value);
};

export const removeWhiteSpacesFromString = (str: string): string => {
  return str.replace(/\s/g, '');
};

/**
 * Adjusts a given string value for decimal display.
 * If `useDecimals` is true, ensures the value has two decimal places.
 *
 * @param value - The value to format. Assumes it is already a string.
 * @param useDecimals - Boolean flag indicating whether to use decimal places.
 * @returns The formatted value as a string.
 *
 * !! Potential issue with inputs like '100.0.1'
 */
export const handleDecimalValuesForDisplay = (value: string, useDecimals: boolean): string => {
  const trimmedValue: string = value.trim();

  if (!useDecimals) {
    return trimmedValue;
  }

  const splitValues: Array<string | undefined> = trimmedValue.split('.');

  // Dot may appear maximum once
  if (![1, 2].includes(splitValues.length)) {
    return trimmedValue;
  }

  const firstPart: string = splitValues[0] || '0';

  // In case of integer input the second part will be undefined
  let secondPart: string = splitValues[1]?.substring(0, 2) || '00';

  if (secondPart.length === 1) {
    secondPart += '0';
  }

  return `${firstPart}.${secondPart}`;
};

/**
 * Formats cash price by adding 2 decimal places to non-whole dollar amounts.
 * For whole dollar amounts it returns value as an integer.
 * @param {string | number | undefined} cashPrice Cash price to be formatted.
 * @returns {string | undefined} Formatted cash price.
 * @throws If input cash price cannot be converted to a number.
 */
export const formatCashPrice = (cashPrice: string | number | undefined): string | undefined => {
  if (cashPrice === undefined || (typeof cashPrice === 'string' && !cashPrice.trim())) {
    return undefined;
  }

  const cashPriceAsNumber = Number(cashPrice);

  if (isNaN(cashPriceAsNumber)) {
    throw new Error('Invalid cash price');
  }

  // For whole dollar amounts do not add decimal places
  return cashPriceAsNumber % 1 === 0
    ? cashPriceAsNumber.toFixed(0)
    : cashPriceAsNumber.toFixed(2);
};

export const handleDecimalValuesForDisplayReturnFreeIfZero = (value: string, useDecimals: boolean, t: TFunction, displayFree?: boolean): string => {
  const retVal = handleDecimalValuesForDisplay(value, useDecimals);
  if (displayFree && retVal === '$0.00') {
    return t('breakdown.deliveryFeeFree');
  }
  return retVal;
};

/**
 * Formats a given value to a locale-specific string representation
 * Optionally, trims decimal values if `trimDecimals` is set to true.
 * @param value
 * @param trimDecimals
 * @returns Formatted value as a string.
 */
export const handleAmountFormattingString = (value: number | string | undefined, trimDecimals = false): string => {
  let valueAsNumber = Number(value);

  if (isNaN(valueAsNumber)) {
    return '';
  }

  if (trimDecimals) {
    valueAsNumber = Math.floor(valueAsNumber);
  }

  return valueAsNumber.toLocaleString();
};

/**
 * To remove commas from formattedNumber
 * @param formattedNumber
 * @returns number string without commas
 */
export const handleFormattedNumberToNumberString = (formattedNumber: string): string => {
  // removed commas from string
  return formattedNumber.replace(/[^0-9.]/g, '');
};

/**
 * Retrieves the display name for the loyalty unit from an account.
 * If `unit_display_name` is unavailable, falls back to `loyalty_unit_name`.
 *
 * @param account - The account containing loyalty program information. Can be undefined.
 * @returns The display unit name as a string.
 */
export const handleDisplayUnitName = (account: Account | undefined): string => {
  return account?.loyalty_program?.unit_display_name
    || account?.loyalty_program?.loyalty_unit_name
    || '';
};

export const getFaceValue = (listing: Listing | undefined, t: TFunction): string => {
  if (listing && listing?.face_value && listing.face_value > 0) {
    return t('ticketInfo.orderTotal.faceValuePresent', { amount: String(listing?.face_value) });
  }
  return t('ticketInfo.orderTotal.faceValueAbsent');
};

export const isEventGuestList = (tags: string[], stockType: string, deliveryId: number) => {
  return (tags.includes(EVENT_TAGS.GuestList)
    && stockType.toUpperCase() === 'HARD'
    && deliveryId === 8);
};

export const getTicketListingTranslationKey = (
  listingDetail: ListingDetails | ListingDetailMetadata | undefined,
  fieldName: string,
): string => {
  if (!listingDetail) {
    return '';
  }

  const event: Event = 'event' in listingDetail ? listingDetail.event : listingDetail.eventMetadata;
  const listing: Listing = 'listing' in listingDetail ? listingDetail.listing : listingDetail.listingMetadata;
  const { pricing, delivery_options: deliveryOptions } = listingDetail;

  if (isEventGuestList(event.tags || [], listing.stock_type.value, pricing.delivery.id)) {
    return 'preCheckoutDetails.guestListTicketNotes';
  }

  const deliveryMethod: string = deliveryOptions.some((deliveryOption: DeliveryOption) => deliveryOption.id === pricing.delivery.id)
    ? pricing.delivery.id.toString()
    : '';

  return `deliveryInformation.${listing.stock_type.value.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

export const getOrderDetailsTranslationKey = (
  stockType: string,
  deliveryMethod: string,
  fieldName: string,
): string => {
  return `deliveryInformation.${stockType && stockType.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

/**
 * Returns unit display settings based on the provided loyalty unit name.
 *
 * @param loyaltyUnitName - Lower cased loyalty unit name, e.g. 'miles', 'points', etc.
 * @returns The unit display settings as an object.
 */
export const getUnitDisplaySettings = (loyaltyUnitName: string | undefined): UnitDisplaySettings => {
  const isMilesLoyaltyUnit: boolean = loyaltyUnitName?.toLowerCase() === RewardUnit.MILES.toLowerCase();
  return {
    rewardSign: isMilesLoyaltyUnit ? '' : '$',
    useDecimals: !isMilesLoyaltyUnit,
  };
};

export const setUpdatedPhoneNumber = (phoneNumber: string): void => {
  window.sessionStorage.setItem('updatedPhoneNumber', phoneNumber);
};

export const getUpdatedPhoneNumber = (): string => {
  return window.sessionStorage.getItem('updatedPhoneNumber') || '';
};

/**
 * Safety check to ensure that switch statement has all possible cases defined.
 * If the code does not build here then it means that there are missing cases in the switch statement.
 */
export const getNonExhaustiveCasesInSwitchStatementError = (value: never): Error => {
  return new Error(`Non-exhaustive cases in a switch statement. Case is not defined for "${value}"`);
};

// TODO: AK: Add unit tests
/**
 * Rounds number up or down.
 * Accepts arguments for 'amount', 'rounding' and either 'unitName' or 'isInteger'.
 */
export const round = (params: {
  amount: number;
  rounding: 'half-up' | 'up' | 'down';
} & ({
  /**
   * Lower cased unit name, e.g. 'miles', 'points', etc.
   * Miles and points are rounded to integers. All other units are rounded to 2 decimal places.
   */
  unitName: string | undefined;
} | {
  /** If true then the amount is rounded to integer. If false then the amount is rounded to 2 decimal places. */
  isInteger: boolean;
})): number => {
  const { amount, rounding } = params;

  /** If true then the amount is rounded to integer. If false then the amount is rounded to 2 decimal places. */
  const isInteger: boolean = 'isInteger' in params
    ? params.isInteger
    : !!params.unitName && IsIntegerRewardUnit[params.unitName.toLowerCase()]; // True only for miles and points units

  const multiplier: number = isInteger ? 1 : 100;
  const amountTimesMultiplier: number = amount * multiplier;

  switch (rounding) {
    case 'half-up': return Math.round(amountTimesMultiplier) / multiplier;
    case 'up': return Math.ceil(amountTimesMultiplier * (1 - Number.EPSILON)) / multiplier;
    case 'down': return Math.floor(amountTimesMultiplier * (1 + Number.EPSILON)) / multiplier;
  }
};

export const handleOrderTotalMiles = (amount: number, loyaltyUnitName?: string): number => {
  return round({ amount, rounding: 'half-up', unitName: loyaltyUnitName });
};

export const isValidDate = (date: Date = new Date()): boolean => {
  return isNaN(date.getTime()) ? false : true;
};

export const handleLocation = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export const handleLocationShort = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    setSourceLoaded(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

export const useProgressiveFonts = (): boolean => {
  const [webFontsLoaded, setWebFontsLoaded] = useState(false);
  useEffect(() => {
    const areFontsReady = async () => {
      await document.fonts.ready;
      setWebFontsLoaded(true);
    };
    void areFontsReady();
  });

  return webFontsLoaded;
};

export const shouldShowTermsAndConditionsCheckbox = (ticket: ListingDetails): boolean => {
  // price breakdown is returned only when feature flag
  // is enabled, no other mechanism exists to check for
  // feature flags in this repo, at this point
  const hasCp1BrokerId: boolean = ticket?.listing.broker_id === C1_BROKER_ID;
  const isCp1Exclusive: boolean | undefined = checkIsEventExclusive(ticket?.event) && hasCp1BrokerId;
  const isNY = ticket?.event?.venue?.state_code === 'NY';
  const hasPriceBreakdown = !!ticket?.listing?.price_breakdown;

  return isNY && hasPriceBreakdown && !isCp1Exclusive;
};

const getWindowSize = (): WindowSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: window.innerWidth < BREAKPOINTS.desktop,
  isTablet: window.innerWidth >= BREAKPOINTS.tablet && window.innerWidth < BREAKPOINTS.desktop,
  isDesktop: window.innerWidth >= BREAKPOINTS.desktop,
});

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize());

    window.addEventListener('resize', handleResize);

    // Remove listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const getMobileDeviceInfo = (): MobileDeviceInfo | undefined => {
  // iPhone
  // userAgent: "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1"
  // platform: "iPhone"
  // maxTouchPoints: 5

  // iPad 13+
  // userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Safari/605.1.15"
  // platform: "MacIntel"
  // maxTouchPoints: 5

  // Desktop Safari
  // userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15"
  // platform: "MacIntel"
  // maxTouchPoints: 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isMsStream: boolean = (window as any).MSStream;

  const isIPad: boolean = (/iPad/i.test(navigator.userAgent) || (/Mac/i.test(navigator.platform) && navigator.maxTouchPoints > 0)) && !isMsStream;
  const isOtherIOS: boolean = /iPhone|iPod/i.test(navigator.userAgent) && !isMsStream;
  if (isIPad || isOtherIOS) {
    return { isIOS: true, isIPad, isAndroid: false };
  }

  const isAndroid: boolean = /Mobi|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (isAndroid) {
    return { isIOS: false, isIPad: false, isAndroid: true };
  }

  return undefined;
};

export const useScrollOffset = (): ScrollOffset => {
  const { scrollX, scrollY } = window;

  const [scrollOffset, setScrollOffset] = useState<ScrollOffset>(() => ({ scrollX, scrollY }));

  useEffect(() => {
    const onScroll = () => {
      setScrollOffset({
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      });
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return scrollOffset;
};

export const useHtmlElementSize = (params: {
  htmlElement: HTMLElement | null;
  extraDependency?: unknown;
}): HtmlElementSize => {
  const { htmlElement, extraDependency } = params;

  const [htmlElementSize, setHtmlElementSize] = useState<HtmlElementSize>(() => ({ width: 0, height: 0 }));

  useEffect(() => {
    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === htmlElement && entry.borderBoxSize.length) {
          const { inlineSize: width, blockSize: height } = entry.borderBoxSize[0];
          setHtmlElementSize({ width, height });
          break;
        }
      }
    });

    // Start observing HTML element
    if (htmlElement) {
      resizeObserver.observe(htmlElement);
    } else {
      setHtmlElementSize({ width: 0, height: 0 });
    }

    // Cleanup
    return () => {
      if (htmlElement) {
        resizeObserver.unobserve(htmlElement);
        resizeObserver.disconnect();
      }
    };
  }, [htmlElement, extraDependency]);

  return htmlElementSize;
};

export const getEventStatus = (event: Event, t: TFunction, shouldCheckForExclusiveEvents = false): EventStatus => {
  let statusColor: TextColourEnum | undefined = undefined;
  let status: string | undefined;

  if (checkIsEventSoldOut({ event, shouldCheckForExclusiveEvents })) {
    status = t('eventCard.soldOut');
    statusColor = 'Negative';
  } else if (checkAreTicketsLow(event)) {
    status = t('eventCard.lowTickets');
    statusColor = 'Warning';
  }
  return {
    statusLabel: status,
    statusColor,
  };
};

export const handleEventCardDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    minute: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}, ${dateParts.year} at ${dateParts.hour}:${dateParts.minute}${dateParts.dayPeriod?.toLowerCase()}`;
};

export const removeHtmlTags = (value?: string) => {
  const htmlTagRegex = /<[^>]*>/g;
  return value ? value.replace(htmlTagRegex, '') : '';
};

export const isVividEvent = (listing?: ListingDetails): boolean => {
  return !(listing?.listing?.broker_id === C1_BROKER_ID) || false;
};

export const isNYEvent = (listing?: ListingDetails): boolean => {
  return listing?.event?.venue?.state_code === 'NY';
};

export const displayMaintenanceWindow = (): boolean => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();
  const milliseconds = now.getUTCMilliseconds();
  const nowUtc = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
  // Check the docs before setting the date, month 0 -> January 11 -> December and time is in UTC
  const maintenanceStart = new Date(Date.UTC(2024, 8, 15, 4, 30));  // September 15 2024, 12:30 AM ET
  const maintenanceEnd = new Date(Date.UTC(2024, 8, 15, 7, 0));    // September 15 2024, 03:00 AM ET
  return nowUtc >= maintenanceStart && nowUtc <= maintenanceEnd;
};

const getUserAgenet = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const isAndroidDevice = (): boolean => {
  const userAgent = getUserAgenet();
  return userAgent.indexOf('android') > -1;
};

export const isSafariBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export const isFirefoxBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /firefox/i.test(userAgent);
};

/**
 * Function that converts a function into an intervalled function.
 * An intervalled function is a function that can be invoked at most once within the specified interval.
 *
 * Examples where interval is 5 seconds and the function was last invoked at 10:00:00:
 * - If function is invoked again at 10:00:03 (less than 5 seconds since the last invocation) then the function execution will be delayed by 2 seconds (i.e. 10:00:05 - 10:00:03 = 2 seconds).
 * - If function is invoked again at 10:00:07 (more than 5 seconds since the last invocation) then the function will be executed without delay. The next earliest invocation time will be 10:00:12 (i.e. 10:00:07 + 5 seconds = 10:00:12).
 *
 * @param func Function to be converted into an intervalled function.
 * @param intervalMs Minimum interval between function invocations (in milliseconds).
 * @returns Intervalled function.
 */
export const getIntervalledFunc = <TFunc extends ((...args: unknown[]) => void)>(func: TFunc, intervalMs: number): TFunc => {
  const getCurrentTime = () => new Date().getTime();

  let earliestAllowedExecutionTime: number = getCurrentTime() + intervalMs;
  let timer: NodeJS.Timeout | undefined;

  const intervalledFunc = ((...args: unknown[]) => {
    if (earliestAllowedExecutionTime <= getCurrentTime()) {
      func(...args);
      earliestAllowedExecutionTime = getCurrentTime() + intervalMs;
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func(...args);
        earliestAllowedExecutionTime = getCurrentTime() + intervalMs;
      }, Math.max(0, earliestAllowedExecutionTime - getCurrentTime()));
    }
  }) as TFunc;

  return intervalledFunc;
};

/** Function that removes focus from the currently focused element on the page */
export const blurFocusedElement = (): void => {
  (document.activeElement as HTMLElement | undefined)?.blur?.();
};

export const isNotNullOrUndefined = <TValue>(value: TValue): value is NonNullable<TValue> => {
  return value !== null && value !== undefined;
};

/** Returns true only for valid numbers */
export const isNumber = (num: unknown): num is number => {
  return typeof num === 'number' && !isNaN(num);
};

/**
 * Parses provided value and returns it as a number if it can be parsed as a number. Otherwise returns undefined.
 * @param {string | number | null | undefined} value Value to be parsed. Could be string, number, null or undefined.
 * @returns {number | undefined} Parsed value as a number or undefined if provided value cannot be parsed as a number.
 */
export const parseNumber = (
  value: string | number | null | undefined,
  options?: {
    minValue?: number | null;
    maxValue?: number | null;
    allowDecimals?: boolean;
  },
): number | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }

  let parsedNumber: number | undefined;

  if (typeof value === 'number') {
    parsedNumber = value;
  } else if (typeof value === 'string') {
    const trimmedValue: string = value.trim();
    if (trimmedValue) {
      parsedNumber = Number(trimmedValue);
    }
  }

  // Check that value is a valid number
  if (!isNumber(parsedNumber)) {
    return undefined;
  }

  // Check that value is equal or greater than min value
  const minValue: number = typeof options?.minValue === 'number' ? Math.max(MIN_INTEGER, options.minValue) : MIN_INTEGER; // -2,147,483,648
  if (parsedNumber < minValue) {
    return undefined;
  }

  // Check that value is equal or less than max value
  const maxValue: number = typeof options?.maxValue === 'number' ? Math.min(MAX_INTEGER, options.maxValue) : MAX_INTEGER; // 2,147,483,647
  if (parsedNumber > maxValue) {
    return undefined;
  }

  // If needed, check that value is a valid integer
  if (!options?.allowDecimals && (parsedNumber % 1) !== 0) {
    return undefined;
  }

  return parsedNumber;
};

/** Hook to extract a query parameter by its name from the current URL and trim its value */
export const useQueryParamFromUrl = <TValue extends string = string>(
  queryParamName: string,
  options?: {
    allowedValues: TValue[];
  },
): TValue | undefined => {
  const { search } = useLocation();

  const queryParams: Record<string, string> = useMemo(() => getQueryParams(search), [search]);
  const queryParam: string | undefined = useMemo(() => queryParams[queryParamName]?.trim(), [queryParams, queryParamName]);

  // If allowed values are provided then validate that query parameter value is one of allowed values.
  // Return undefined if query parameter value is not allowed.
  if (queryParam && options?.allowedValues && !options.allowedValues.includes(queryParam as TValue)) {
    return undefined;
  }

  return queryParam as TValue;
};

/** Hook to extract a numeric query parameter by its name from the current URL */
export const useNumericQueryParamFromUrl = (
  queryParamName: string,
  options?: {
    minValue?: number | null;
    maxValue?: number | null;
    allowDecimals?: boolean;
    shouldRemoveIfInvalid?: boolean;
  },
): number | undefined => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParam: string | undefined = useQueryParamFromUrl(queryParamName);

  const numericQueryParam: number | undefined = useMemo(
    () => parseNumber(queryParam, { minValue: options?.minValue, maxValue: options?.maxValue, allowDecimals: options?.allowDecimals }),
    [queryParam, options?.minValue, options?.maxValue, options?.allowDecimals],
  );

  // Remove invalid numeric query parameter from the current URL
  useEffect(() => {
    if (queryParam && (options?.shouldRemoveIfInvalid ?? true) && numericQueryParam === undefined) {
      const newQueryString: string = addQueryParam(search, { [queryParamName]: '' }, { skipEmptyParams: true });
      navigate({ search: newQueryString }, { replace: true });
    }
  }, [queryParam, options?.shouldRemoveIfInvalid, numericQueryParam, search, queryParamName, navigate]);

  return numericQueryParam;
};

/**
 * Formats the provided number so that:
 * 1. If number is integer then this number is formatted as locale formatted string, e.g. 123456 => '123,456'.
 * 2. If number is not integer then this number is trimmed to exactly 2 decimal points and then formatted as locale formatted string, e.g. 123456.7 => '123,456.70', 123456.789 => '123,456.79'.
 *
 * If unit name is not in ['noUnits', 'miles', 'points'] then the result is prepended with '$', e.g. '$123,456.78', '$123,456.78 rewards cash'.
 *
 * If unit name is not in ['noUnits', 'dollars', 'dollarsWithCents'] then the unit name is appended to the result, e.g. '123,456 miles', '123,456 points'.
 *
 * @returns {string} Formatted number as locale formatted string.
 */
export const formatNumberToLocaleString = (params: {
  /** Number to be formatted */
  num: number;
  /** Unit name, e.g. 'noUnits', 'dollars', 'dollarsWithCents', 'miles', 'points', etc. */
  unitName: 'noUnits' | 'dollars' | 'dollarsWithCents' | (string & {}) | undefined;
  /** Indicates if unit name should be included in the result */
  shouldIncludeUnitName: boolean;
}): string => {
  const { num, unitName = 'noUnits', shouldIncludeUnitName } = params;

  /** True only for miles and points units */
  const isIntegerRewardUnit: boolean = IsIntegerRewardUnit[unitName.toLowerCase()] ?? false;

  /** Miles and points are not dollar amounts so do not prepend '$' sign */
  const isCurrencyUnitName: boolean = unitName !== 'noUnits' && !isIntegerRewardUnit;

  let numberFormatOptions: Intl.NumberFormatOptions = isCurrencyUnitName
    ? { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }
    : {};

  // Unit name 'dollarsWithCents' instructs to always add 2 decimal points
  // Miles and points must always be rounded to integer values
  const isInteger: boolean = unitName !== 'dollarsWithCents' && (isIntegerRewardUnit || num % 1 === 0);
  const decimalPoints: 0 | 2 = isInteger ? 0 : 2;

  numberFormatOptions = {
    ...numberFormatOptions,
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
  };

  const formattedNumber: string = num.toLocaleString('en-US', numberFormatOptions);

  // 'noUnits', 'dollars' and 'dollarsWithCents' unit names should not be displayed
  const formattedUnitName: string = !shouldIncludeUnitName || ['noUnits', 'dollars', 'dollarsWithCents'].includes(unitName) ? '' : unitName.toLowerCase();

  return `${formattedNumber} ${formattedUnitName}`.trim();
};

/**
 * Processes raw HTML content:
 * 1. If content is string then handles new line characters, double quotes and list items <li>.
 * 2. In all other cases returns initial content as-is.
 * @returns {ReactNode} Processed HTML content content.
 */
export const processRawHtmlContent = (params: {
  /** Initial raw HTML content that may contain HTML tags */
  initialRawHtmlContent: ReactNode;
}): ReactNode => {
  const { initialRawHtmlContent } = params;

  if (typeof initialRawHtmlContent === 'string') {
    return initialRawHtmlContent
      .replace(/\\n/g, '\n') // Handle new line characters
      .replace(/\\"/g, '"') // Handle double quotes
      .replace(/<li>[ ]*●[ ]*/g, '<li>'); // Remove dots from list items <li> because <li> adds "list-style: disc"
  }

  return initialRawHtmlContent;
};

// TODO: AK: Add unit tests
export const toHtmlId = (value: string): string => {
  return `id-${value.toLowerCase().replace(/[^a-zA-Z0-9\-_]/g, '-')}`;
};

/**
 * Checks if the given array of numbers forms a continuous sequence without duplicates or gaps.
 * @param {number[]} numbers - The array of numbers to check.
 * @returns {boolean} True if the numbers form a continuous sequence; otherwise, false.
 */
export const checkAreNumbersInSequence = (numbers: number[]): boolean => {
  // Iterate through the array up to the second to last element
  for (let i = 0; i < numbers.length - 1; i++) {
    if (numbers[i] !== (numbers[i + 1] - 1)) {
      return false; // Return false if a duplicate or gap is found
    }
  }

  // If no gaps or duplicates are found, return true
  return true;
};

export const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
