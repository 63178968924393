import cx from 'classnames';
import React from 'react';

import { getClickAccessibilityProps } from '../../../legacy/organisms/TopNav/util';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './SearchReturnItem.module.scss';
import usePresenter from './SearchReturnItem.presenter';
import getProps from './SearchReturnItem.props';
import { SearchReturnItemCombinedProps } from './types';

const SearchReturnItem: React.FC<SearchReturnItemCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    text,
    description,
    divider,
    nextButton,
    divider1,
    // MANUAL OVERRIDE STARTS
    onClick,
    onEsc,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`searchReturnItem${variantName}`];

  // MANUAL OVERRIDE
  const accessibilityProps = getClickAccessibilityProps({ onClick: onClick, onEsc: onEsc }, true);

  let componentView: JSX.Element;

  switch (variantName) {
    case 'DarkBackground': {
      componentView = (
        // MANUAL OVERRIDE - added accessibility props
        <div className={cx(currentStyle, className)} onClick={onClick} {...accessibilityProps}>
          {/* MANUAL OVERRIDE - moved divider above the content to match with legacy component */}
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text} />
            <Text
              className={cx(styles.description, classes?.description)}
              {...internalProps.description}
              {...description} />
          </div>
        </div>
      );
      break;
    }
    case 'LightBackground': {
      componentView = (
        // MANUAL OVERRIDE - added accessibility props
        <div className={cx(currentStyle, className)} onClick={onClick} {...accessibilityProps}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider} />
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.info, classes?.info)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text} />
              <Text
                className={cx(styles.description, classes?.description)}
                {...internalProps.description}
                {...description} />
            </div>
            <Button
              className={cx(styles.nextButton, classes?.nextButton)}
              {...internalProps.nextButton}
              {...nextButton} />
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SearchReturnItem;
