import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { addQueryParam } from '../../lib/util';
import type { AppendQueryParamsToUrlFunc, AppendQueryParamsToUrlOptions, UseAppendQueryParamsToUrlResponse } from './Navigation.types';

/** Hook to append new query parameters to the URL (current URL by default) */
export const useAppendQueryParamsToUrl = (): UseAppendQueryParamsToUrlResponse => {
  const navigate = useNavigate();

  const response: UseAppendQueryParamsToUrlResponse = useMemo(() => {
    const appendQueryParamsToUrl: AppendQueryParamsToUrlFunc = (queryParams: Record<string, string>, options?: AppendQueryParamsToUrlOptions) => {
      const { url: inputUrl, ...navigateOptions } = options ?? {};

      // Fallback to the current URL if no URL is provided
      const url: string = inputUrl ?? window.location.search;

      // Amend query string and navigate
      navigate(addQueryParam(url, queryParams, { skipEmptyParams: true }), navigateOptions);
    };

    return { appendQueryParamsToUrl };
  }, [navigate]);

  return response;
};
