import type { ListingDetailMetadata } from '../../../lib/types';
import { getFiveDigitsZipCode, getTicketListingTranslationKey, getTwoDigitsCountryCode } from '../../../lib/util';
import i18n from '../../../modules/locale/i18n';
import type { EventPerformer, InventoryIntegrationDetail, OrderAddress } from '../../../modules/partnership';
import { MLB_INTEGRATION_ID, type MarketingOptinMlbProps } from '../../molecules/MarketingOptinMlb';
import { getFormattedPhoneNumber } from '../CheckoutPage';
import type { DeliveryNote } from './OrderConfirmationPage.types';

// TODO: AK: Add unit tests
export const getDeliveryNotes = (params: {
  listingDetailMetadata: ListingDetailMetadata | undefined;
  email: string | undefined;
  billingAddress: OrderAddress | undefined;
  shippingAddress: OrderAddress | undefined;
}): DeliveryNote[] => {
  const { listingDetailMetadata, email, billingAddress, shippingAddress } = params;

  const confirmationPagePath: string = getTicketListingTranslationKey(listingDetailMetadata, 'confirmation_page');

  const isGuestList: boolean = confirmationPagePath === 'preCheckoutDetails.guestListTicketNotes';

  // For guest list return a static list of delivery notes
  if (isGuestList) {
    return [
      {
        description: i18n.t('orderConfirmation.guestListEventOrderNotes.title'),
        isBulletPoint: false,
      },
      {
        description: i18n.t('orderConfirmation.guestListEventOrderNotes.email', { email }),
        isBulletPoint: true,
      },
      {
        description: i18n.t('orderConfirmation.guestListEventOrderNotes.name'),
        isBulletPoint: true,
      },
      {
        description: i18n.t('orderConfirmation.guestListEventOrderNotes.onSiteEvent'),
        isBulletPoint: true,
      },
      {
        description: i18n.t('orderConfirmation.guestListEventOrderNotes.virtualEvent'),
        isBulletPoint: true,
      },
    ];
  }

  // Non-guest list from here

  const deliveryNotes: DeliveryNote[] = [];

  // Add delivery note header, e.g. 'Mobile Access', etc.
  if (listingDetailMetadata) {
    deliveryNotes.push({
      description: i18n.t(getTicketListingTranslationKey(listingDetailMetadata, 'delivery_header')),
      isBulletPoint: false,
    });
  }

  const {
    phone_number: phoneNumber = '',
  } = billingAddress ?? {};

  const {
    address_line_1: shippingAddressLine1 = '',
    address_line_2: shippingAddressLine2 = '',
    city: shippingCity = '',
    region: shippingStateCode = '',
    postal_code: shippingPostalCode = '',
    country: shippingCountryCode = '',
  } = shippingAddress ?? {};

  const deliveryNoteOptions: string[] = i18n.t(confirmationPagePath, {
    customerEmail: email,
    customerMobilePhone: getFormattedPhoneNumber(phoneNumber),
    customerShippingAddress: i18n.t(
      'deliveryInformation.ticketDelivery.customerAddressTemplate',
      {
        address: `${shippingAddressLine1}${shippingAddressLine2 ? `, ${shippingAddressLine2}` : ''}`,
        city: shippingCity,
        state: shippingStateCode,
        postalCode: getFiveDigitsZipCode({ countryCode: shippingCountryCode, postalCode: shippingPostalCode }),
        country: getTwoDigitsCountryCode(shippingCountryCode),
      },
    ),
    returnObjects: true,
  });

  // Add dynamic delivery notes based on stock type and delivery Id
  if (Array.isArray(deliveryNoteOptions)) {
    deliveryNoteOptions.forEach((deliveryNoteOption: string) => {
      deliveryNotes.push({
        description: deliveryNoteOption,
        isBulletPoint: true,
      });
    });
  }

  return deliveryNotes;
};

// TODO: AK: Add unit tests
export const getMarketingOptinMlbProps = (params: {
  orderId: number | undefined;
  email: string | undefined;
  performers: readonly EventPerformer[] | undefined;
  hasIntegratedInventory: boolean | null | undefined;
  inventoryIntegrationDetails: InventoryIntegrationDetail | null | undefined;
}): MarketingOptinMlbProps | undefined => {
  const {
    orderId,
    email,
    performers,
    hasIntegratedInventory,
    inventoryIntegrationDetails,
  } = params;

  if (!orderId || !email || !performers) {
    return undefined;
  }

  const shouldShowMarketingOptinMlb: boolean = !!hasIntegratedInventory && inventoryIntegrationDetails?.integration_id === MLB_INTEGRATION_ID;
  if (!shouldShowMarketingOptinMlb) {
    return undefined;
  }

  const teamName: string | undefined = performers.find(({ is_primary }) => is_primary)?.name;
  if (!teamName) {
    return undefined;
  }

  return {
    orderId,
    email,
    teamName,
  };
};
