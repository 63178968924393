import { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import { SubmenuWithTabsCombinedProps } from './types';

const useInteractor = (props: SubmenuWithTabsCombinedProps): CategoryMenuItemState | undefined => {
  const { data } = props;
  const sportsData = data?.find((el) => el.id === 'sports');
  return sportsData;
};

export default useInteractor;
