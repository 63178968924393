import { useMemo } from 'react';
import type { BaseComponentProps } from '../../../lib/types';
import type { ImageCombinedProps } from '../../atoms/Image';
import type { ListingCardProps } from '../../molecules/ListingCard';
import { getListingCardProps } from '../EventPage';
import type { GuestListEventPagePresenterProps, GuestListEventPageProps } from './GuestListEventPage.types';
import { getCarouselImageProps } from './GuestListEventPage.utils';

export const usePresenter = (props: GuestListEventPageProps): GuestListEventPagePresenterProps => {
  const {
    eventMetadata: {
      carouselImageUrls,
      isPayWithRewardsOnly,
    },
    listingsMetadata: {
      listings,
    } = {},
  } = props;

  /** Array of image props for rendering the carousel of event images */
  const carouselImageProps: Array<BaseComponentProps & ImageCombinedProps> = useMemo(
    () => getCarouselImageProps({ carouselImageUrls }),
    [carouselImageUrls],
  );

  /** Array of listing card props for rendering the listings */
  const listingCardProps: ListingCardProps[] = useMemo(
    () => getListingCardProps({
      listings,
      isPayWithRewardsOnly,
      shouldShowAipOverride: false,
    }),
    [listings, isPayWithRewardsOnly],
  );

  // TODO: AK: Check where this should go
  // // event tracking
  // useEffect(() => {
  //   trackEvent('view_item', {
  //     ecommerce: {
  //       content_type: 'view_production',
  //       items: [],
  //     },
  //   });
  // }, [trackEvent]);

  return {
    ...props,
    carouselImageProps,
    listingCardProps,
  };
};
