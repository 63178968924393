import { useCallback, useContext } from 'react';
import { TopNavContext } from '../../../modules/topNav';
import type { SubmenuTabItemCombinedProps } from './types';

const usePresenter = (props: SubmenuTabItemCombinedProps): SubmenuTabItemCombinedProps => {
  const { linkPath, onItemClicked: initialOnItemClicked } = props;

  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const onItemClicked = useCallback(() => {
    if (linkPath) {
      setActiveTopNavMenuType(undefined);
    }

    initialOnItemClicked?.();
  }, [linkPath, initialOnItemClicked, setActiveTopNavMenuType]);

  return {
    ...props,
    onItemClicked,
  };
};

export default usePresenter;
