import React, { type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../atoms/Backdrop';
import Button from '../../atoms/Button';
import SubmenuWithDoubleTabs from '../../organisms/SubmenuWithDoubleTabs';
import styles from './ExploreButton.module.scss';
import { usePresenter } from './ExploreButton.presenter';
import type { ExploreButtonProps } from './ExploreButton.types';

export const ExploreButton: React.FC<ExploreButtonProps> = (props) => {
  const {
    exploreButtonIconAsset,
    onExploreMenuToggled,
    isExploreMenuExpanded,
  } = usePresenter(props);

  const { t } = useTranslation();

  const exploreButton: ReactNode = (
    <Button
      type='IconTextIcon'
      style='Contained'
      size='Medium'
      text={{ value: t('topnav.explore') }}
      icon={{ asset: exploreButtonIconAsset }}
      className={styles.exploreButton}
      classes={{ icon: styles.exploreButtonIcon }}
      // Need to pass in an empty handler
      // But the toggle of explore menu is handled by dropdown below
      onClick={() => { }}
    />
  );

  return (
    <Dropdown
      show={isExploreMenuExpanded}
      onToggle={onExploreMenuToggled}
      className={styles.dropdown}
    >
      <Dropdown.Toggle as='div' className={styles.dropdownToggle} aria-expanded={isExploreMenuExpanded}>
        {exploreButton}
      </Dropdown.Toggle>
      {isExploreMenuExpanded && (<>
        <Dropdown.Menu className={styles.dropdownMenu}>
          <SubmenuWithDoubleTabs />
        </Dropdown.Menu>
        <Backdrop onClose={onExploreMenuToggled} />
      </>)}
    </Dropdown>
  );
};
