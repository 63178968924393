import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TERMS_MODAL } from '../../../lib/constants';
import { displayMaintenanceWindow } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import { TopNavContext } from '../../../modules/topNav';
import { termsHtml } from '../../../resources/termsAndPrivacy/terms';
import type { MainLayoutPresenterProps, MainLayoutProps } from './MainLayout.types';
import { checkShouldShowUpdatedTopNav } from './MainLayout.utils';

export const usePresenter = (props: MainLayoutProps): MainLayoutPresenterProps => {
  const {
    pathname,
    search,
    hash,
    state: locationMaintenance,
    state: errorBlock,
  } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const navigate = useNavigate();

  const { account, refetchAccount, loading: isAccountLoading = false } = useContext(AuthContext);

  const { shouldShowTopNav, shouldShowFooter } = useContext(TopNavContext);

  useEffect(() => {
    if (!hash) {
      // Using 'auto' behaviour to scroll instantly without scroll transition
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }, [pathname, hash]);

  const inMaintenanceMode: boolean = displayMaintenanceWindow();

  const modalQueryParam: string | null = useMemo(() => query.get('modal'), [query]);

  const [isTermsAndPrivacyModalOpen, setIsTermsAndPrivacyModalOpen] = useState<boolean>(false);

  const termsAndPrivacyModalContent: string = useMemo(() => modalQueryParam === TERMS_MODAL ? termsHtml : '', [modalQueryParam]);

  const closeTermsAndPrivacyModal = useCallback(() => {
    if (modalQueryParam) {
      query.delete('modal');
      navigate({
        search: query.toString(),
      }, { replace: true });
    }
    setIsTermsAndPrivacyModalOpen(false);
  }, [modalQueryParam, query, navigate]);

  useEffect(() => {
    setIsTermsAndPrivacyModalOpen(modalQueryParam === TERMS_MODAL);
  }, [modalQueryParam]);

  const [isOnBoardingModalOpen, setIsOnBoardingModalOpen] = useState<boolean>(false);

  const closeOnBoardingModal = useCallback(() => {
    refetchAccount();
    setIsOnBoardingModalOpen(false);
  }, [refetchAccount]);

  useEffect(() => {
    if (account?.new_user) {
      setIsOnBoardingModalOpen(account.new_user);
    }
  }, [account?.new_user]);

  const shouldShowUpdatedTopNav: boolean = useMemo(() => {
    return !isOnBoardingModalOpen && checkShouldShowUpdatedTopNav(pathname);
  }, [isOnBoardingModalOpen, pathname]);

  return {
    ...props,
    isAccountLoading,
    shouldShowUpdatedTopNav,
    shouldShowTopNav,
    shouldShowFooter,
    inMaintenanceMode,
    locationMaintenance,
    isTermsAndPrivacyModalOpen,
    termsAndPrivacyModalContent,
    closeTermsAndPrivacyModal,
    isOnBoardingModalOpen,
    closeOnBoardingModal,
    errorBlock,
  };
};
