import { SVG_MAP_ID } from './MapContext.constants';
import type { TooltipPosition } from './MapContext.types';

export const getTransform = (
  /** E.g. 'm1,0,0,1,0,0' */
  transform: string = '',
): string => {
  const transformSplits: string[] = transform.split('m');
  return transformSplits[1] ? `matrix(${transformSplits[1]})` : transform;
};

export const getTooltipPositionFromDimensions = (params: {
  /** X coordinate of element's top left corner */
  x: number;
  /** Y coordinate of element's top left corner */
  y: number;
  /** Element's width */
  width: number;
  /** Element's height */
  height: number;
}): TooltipPosition => {
  const { x, y, width, height } = params;

  // For the majority of map elements the best placement for the tooltip is at the top of the section being hovered.
  // But tooltips for map elements that wrap around the map should be centered in the middle of the map.
  const bestY: number = height > 255 ? y + height / 2 : y;
  return { x: x + width / 2, y: bestY };
};

/** Gets CSS selector for provided sections, e.g. #svg-map path#id-123, #svg-map path#id-234 */
export const getCssSelectorForSections = (params: {
  sectionHtmlIds: Array<string | undefined>;
}): string | undefined => {
  const { sectionHtmlIds } = params;

  const nonEmptySectionHtmlIds: string[] = sectionHtmlIds.filter((sectionHtmlId: string | undefined) => !!sectionHtmlId) as string[];

  if (!nonEmptySectionHtmlIds.length) {
    return undefined;
  }

  return nonEmptySectionHtmlIds.map((sectionHtmlId: string) => `#${SVG_MAP_ID} path#${sectionHtmlId}`).join(', ');
};
