import type { Key, MutableRefObject, ReactNode, RefObject } from 'react';
import type { TextColourEnum } from '../components/atoms/Text';
import type { Event, Listing, ListingDetails, ListingsResponse } from '../modules/partnership';

export type RefPropType<T> = RefObject<T> | MutableRefObject<T> | ((element: T) => void);

export type TicketDeliveryInfo = {
  ticketListing: string;
  ticketDetails: string;
  deliveryMethod: string;
  confirmation_page: string;
};

export type UnitDisplaySettings = {
  rewardSign: '$' | '';
  useDecimals: boolean;
};

export enum AriaRoles {
  ALERT = 'alert',
}

export enum AriaLiveRegions {
  ASSERTIVE = 'assertive',
  OFF = 'off',
  POLITE = 'polite',
}

export interface Props {
  children: ReactNode;
}

export type WindowSize = {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export type MobileDeviceInfo = {
  isIOS: boolean;
  isIPad: boolean;
  isAndroid: boolean;
};

export type ScrollOffset = {
  scrollX: number;
  scrollY: number;
};

export type HtmlElementSize = {
  width: number;
  height: number;
};

export type UseGetEventStatus = {
  getEventStatus: (event: Event) => EventStatus;
};

export type EventStatus = {
  status?: 'soldOut' | 'lowTickets';
  statusLabel?: string;
  statusColor?: TextColourEnum;
};

export type EventDateTimeVenueDetails = {
  shortWeekDay: string;
  shortDate: string;
  longDate: string;
  time: string;
  venueName: string;
  venueCity: string;
  venueStateCode: string;
};

export type EventMetadata = Event & {
  eventUrl: string;
  eventName: string;
  eventDescription: string | undefined;
  eventModalMobileImageUrl: string;
  eventModalDesktopImageUrl: string;
  eventHeroMobileImageUrl: string;
  eventHeroDesktopImageUrl: string;
  eventDateTimeAndVenueDetails: EventDateTimeVenueDetails;
  /** Indicates if the event is exclusive, i.e. it contains C1_EXCLUSIVE tag */
  isExclusiveEvent: boolean;
  isGuestListEvent: boolean;
  isSoldOut: boolean;
  isSoldOutWithExclusiveCheck: boolean;
  shouldShowGuestListEventPage: boolean;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  carouselImageUrls: string[];
  /** Maximum number of tickets that can be purchased for the event. Undefined if there is no limit. */
  eventMaxPurchaseLimit: number | undefined;
};

export type ListingsMetadata = ListingsResponse & {
  /** (Optional) SVG map JSON URL, e.g. https://d3pz2kc9e7xd94.cloudfront.net/fc95dc65-8d37-4976-bf37-02a9e0f515a8.json */
  svgMapJsonUrl: string | undefined;
  /** Static image map URL, e.g. https://d2o50i5c2dr30a.cloudfront.net/44871641-2b79-41dc-8fce-370d4bbeaeb7.jpg */
  staticImageMapUrl: string;
  /** Minimum listing price */
  minPrice: number;
  /** Maximum listing price */
  maxPrice: number;
};

export type ListingMetadata = Listing & {
  /**
   * - If true then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are AIP prices.
   * - If false then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are non-AIP prices.
   */
  shouldShowAip: boolean;
  /** Non-AIP cash price per unit as number, e.g. 123456 */
  nonAipCashPrice: number | undefined;
  /** AIP cash price per unit as number, e.g. 123456 */
  aipCashPrice: number | undefined;
  /** Cash price per unit as number that is dependent on shouldShowAip flag, e.g. 123456 */
  cashPrice: number | undefined;
  /** Formatted cash price per unit that is dependent on shouldShowAip flag, e.g. $123,456 */
  formattedCashPrice: string | undefined;
  /** Non-AIP loyalty price per unit as number, e.g. 123456 */
  nonAipLoyaltyPrice: number | undefined;
  /** AIP loyalty price per unit as number, e.g. 123456 */
  aipLoyaltyPrice: number | undefined;
  /** Loyalty price per unit as number that is dependent on shouldShowAip flag, e.g. 123456 */
  loyaltyPrice: number | undefined;
  /** Formatted loyalty price without unit that is dependent on shouldShowAip flag, e.g. 123,456 (for miles or points), $123,456 (for cash rewards) */
  formattedLoyaltyPrice: string | undefined;
  /** Lower cased loyalty unit name, e.g. 'miles', 'points', etc. */
  loyaltyUnitName: string | undefined;
  /** Section name to display */
  sectionName: string;
  /** Row name to display */
  row: string;
  /** Optional seller notes */
  sellerNotes: string | undefined;
  /** Total number of available tickets, e.g. 123456 */
  ticketsTotal: number;
  /** Formatted total number of available tickets, e.g. '123,456' */
  formattedTicketsTotal: string;
  /** Array of allowed ticket quantities */
  ticketQuantities: readonly number[];
  /** Indicates if the listing has CP1 broker Id 29014 */
  hasCp1BrokerId: boolean;
};

export type ListingDetailMetadata = Omit<ListingDetails, 'event' | 'listing'> & {
  eventMetadata: EventMetadata;
  listingMetadata: ListingMetadata;
  seats: string | undefined;
  deliveryMethod: string;
  isShippingAddressRequired: boolean;
  faceValue: string | undefined;
};

/**
 * Listing min prices per section as an object where key is section HTML Id and value is min price for the section.
 * This is used to display tooltips with min prices when user hovers over SVG map.
 */
export type ListingMinPricesBySection = Record<string, number>;

export type TotalPrices = {
  /** Formatted total cash price (with cents) for selected quantity in Quantity dropdown, e.g. $123,456.00 */
  formattedTotalCashPrice: string | undefined;
  /** Formatted total loyalty price for selected quantity in Quantity dropdown, e.g. 123,456 (for miles or points), $123,456 (for cash rewards) */
  formattedTotalLoyaltyPrice: string | undefined;
};

/** Names of custom CSS variables */
export enum CssVariableNameEnum {
  topNavHeight = '--top-nav-height',
  topDisclaimerHeight = '--top-disclaimer-height',
  topFixedHeight = '--top-fixed-height',
}

/** Base component props that must be implemented by all components that are rendered as part of paginated lists and/or carousels */
export type BaseComponentProps = JSX.IntrinsicAttributes & {
  /** Mandatory React key */
  key: Key;
  /** Optional class name for CSS customisation */
  className?: string;
};
