import { useCallback, useContext, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { RegionContext } from '../../../modules/region';
import type { FilterOption } from '../../atoms/BaseFilter';
import type { RegionFilterOptionValue, RegionFilterPresenterProps, RegionFilterProps, RegionFilterState } from './RegionFilter.types';
import { getDisplayValue as getDisplayValueUtil, getRegionFilterOptions, selectFilterOption } from './RegionFilter.utils';

export const usePresenter = (props: RegionFilterProps): RegionFilterPresenterProps => {
  const {
    regions,
    regionFilterState,
    canSearchRegion,
    canUseCurrentRegion,
  } = props;

  const { currentRegion } = useContext(RegionContext);

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const getDisplayValue = useCallback((displayRegionFilterState: RegionFilterState): string => {
    return getDisplayValueUtil({ displayRegionFilterState, currentRegion });
  }, [currentRegion]);

  /** Ref to a search field for region filtering */
  const searchFieldRef = useRef<HTMLInputElement>(null);

  /** Search query for region filtering */
  const [searchQuery, setSearchQuery] = useState<string>('');

  /** Function to update search query for region filtering */
  const onSearchQueryChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const filterOptions: FilterOption<RegionFilterOptionValue>[] = useMemo(() => {
    return getRegionFilterOptions({
      regionFilterState,
      canUseCurrentRegion,
      canSearchRegion,
      searchQuery,
      regions,
    });
  }, [regionFilterState, canUseCurrentRegion, canSearchRegion, searchQuery, regions]);

  const onFilterOptionSelected = useCallback((regionFilterOptionValue: RegionFilterOptionValue | undefined) => {
    selectFilterOption({ regionFilterOptionValue, appendQueryParamsToUrl });
  }, [appendQueryParamsToUrl]);

  /**
   * Clears search query when region filter dropdown closes.
   * Auto focuses search field when region filter dropdown opens.
   */
  const onFilterDropdownToggled = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setSearchQuery('');
    } else {
      setTimeout(() => searchFieldRef.current?.focus(), 0);
    }
  }, []);

  // Clear search query when region filter state changes
  useEffect(() => {
    setSearchQuery('');
  }, [regionFilterState]);

  return {
    ...props,
    getDisplayValue,
    filterOptions,
    onFilterOptionSelected,
    onFilterDropdownToggled,
    searchFieldRef,
    searchQuery,
    onSearchQueryChanged,
  };
};
