import { checkIsEventVenueInStates } from '../../../lib/eventUtils';
import type { ListingDetailMetadata } from '../../../lib/types';
import { formatNumberToLocaleString, isNotNullOrUndefined } from '../../../lib/util';
import i18n from '../../../modules/locale/i18n';
import type { TextDropdownOption } from '../TextDropdown';
import type { TicketsByLogo } from './PreCheckoutDetails.types';

/**
 * Gets an array of quantity dropdown options sorted by quantity values in ascending order
 * @returns {TextDropdownOption[]} Array of quantity dropdown options sorted by quantity values in ascending order
 */
export const getQuantityDropdownOptions = (params: {
  /** Array of numeric quantities */
  quantities: readonly number[];
}): TextDropdownOption[] => {
  const { quantities } = params;

  // Use .slice not to mutate the original array of quantities
  const sortedQuantities: number[] = quantities.slice().sort((a, b) => a - b);

  return sortedQuantities.map((quantity: number) => ({
    value: quantity.toString(),
    label: i18n.t('preCheckoutDetails.tickets', { quantity, count: quantity }),
  }));
};

/**
 * Gets logo to display in 'tickets by' section (capitalOne or vividSeats)
 * @returns {TicketsByLogo} Logo to display in 'tickets by' section (capitalOne or vividSeats)
 */
export const getTicketsByLogo = (params: {
  /** Indicates if the listing has CP1 broker Id 29014 */
  hasCp1BrokerId: boolean;
}): TicketsByLogo => {
  return params.hasCp1BrokerId ? 'capitalOne' : 'vividSeats';
};

// TODO: AK: Add unit tests
/**
 * Gets a displayed text for listing service fees:
 * - For non-AIP:
 *   - Undefined for exclusive events
 *   - 'Plus fees' for non-exclusive events
 * - For AIP:
 *   - 'Includes fees' if event venue is in California or Maryland
 *   - 'Includes $X.XX in fees' if event is in other locations
 */
export const getServiceFeeText = (params: {
  /**
   * - If true then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are AIP prices.
   * - If false then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are non-AIP prices.
   */
  shouldShowAip: boolean;
  /** Detailed information about event listing detail */
  listingDetailMetadata: ListingDetailMetadata;
  /** Validated quantity */
  quantity: number;
}): string | undefined => {
  const { shouldShowAip, listingDetailMetadata, quantity } = params;
  const { eventMetadata, listingMetadata } = listingDetailMetadata;
  const { isExclusiveEvent } = eventMetadata;

  if (!shouldShowAip) {
    // If event is exclusive then show nothing
    // Otherwise show 'Plus fees'
    return isExclusiveEvent ? undefined : i18n.t('preCheckoutDetails.plusFees');
  }

  const isEventVenueInCaliforniaOrMaryland: boolean = checkIsEventVenueInStates(eventMetadata, ['CA', 'MD']);
  const serviceFee: number | null | undefined = listingMetadata.price_breakdown?.service_fee;

  // If event venue is in California or Maryland then show 'Includes fees'
  // Otherwise show 'Includes $X.XX in fees'
  return isEventVenueInCaliforniaOrMaryland || !isNotNullOrUndefined(serviceFee)
    ? i18n.t('preCheckoutDetails.includesFees')
    : i18n.t('preCheckoutDetails.includesXInFees', { fees: formatNumberToLocaleString({ num: quantity * serviceFee, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }) });
};
