import cx from 'classnames';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import AccountMenu from '../../molecules/AccountMenu';
import NavMenuItem from '../../molecules/NavMenuItem';
import NavMenuList from '../../organisms/NavMenuList';
import styles from './AccountMenuBlock.module.scss';
import usePresenter from './AccountMenuBlock.presenter';
import customStyles from './Custom.module.scss';
import type { AccountMenuBlockCombinedProps } from './types';

// This component will be rendered on mobile devices whereas AccountMenu is rendered on desktop
const AccountMenuBlock: React.FC<AccountMenuBlockCombinedProps> = (props) => {
  const {
    accountMenu,
    className,
    navMenuList,
    isModalOpen,
    isSelectedNavMenuItem,
    myAccount,
    logOut,
    myTickets,
  } = usePresenter(props);

  const bottomNavMenuListView = !isSelectedNavMenuItem ? (
    <div className={styles.profileMenuList} role='menu'>
      <NavMenuItem
        className={styles.myAccount}
        {...myAccount} />
      <NavMenuItem
        className={styles.myTickets}
        {...myTickets} />
      <NavMenuItem
        className={styles.logOut}
        {...logOut} />
    </div>
  ) : null;

  const pageTopRef = useRef<HTMLDivElement>(null);

  return (
    <Modal className={cx(styles.accountMenuBlock, customStyles.accountMenuBlock, className)}
      dialogClassName={customStyles.modalDialog}
      contentClassName={customStyles.modalContent}
      show={isModalOpen}
    >
      <Modal.Header className={customStyles.modalHeader}>
        <div className={cx(styles.topContent, styles.stickyTopContent)}>
          <AccountMenu
            className={styles.accountMenu}
            {...accountMenu}
            renderType='Element' />
        </div>
        <div ref={pageTopRef} className={cx(styles.topContent, 'topContentSpacer')} />
      </Modal.Header>
      <Modal.Body className={customStyles.modalBody}>
        <NavMenuList
          className={styles.navMenuList}
          {...navMenuList}
          pageTopRef={pageTopRef} />
        {bottomNavMenuListView}
      </Modal.Body>
    </Modal>
  );
};

export default AccountMenuBlock;
