import type { NavigateFunction } from 'react-router-dom';
import { PRIVACY_URL } from '../../../lib/config';
import { TERMS_MODAL, URLs } from '../../../lib/constants';
import { addQueryParam } from '../../../lib/util';
import i18n from '../../../modules/locale/i18n';
import type { ButtonCombinedProps } from '../../atoms/Button';
import type { TextColourEnum } from '../../atoms/Text';
import { FooterButtonTextColoursByFooterTheme } from './Footer.constants';
import type { FooterTheme } from './Footer.types';

/**
 * Returns dark theme for Exclusive Events or other event pages. For all other pages returns light theme.
 * @returns {FooterTheme} Footer theme.
 */
export const getFooterTheme = (params: {
  /** Current path name */
  pathname: string;
}): FooterTheme => {
  const { pathname } = params;
  return pathname.startsWith(URLs.ExclusiveEventsPage) || pathname.startsWith('/events/') ? 'dark' : 'light';
};

/**
 * Adds a modal type to the current search parameters and navigates to the updated URL.
 *
 * @param {string} modalType - The type of modal to be rendered.
 * @param {string} search - The current search string from the URL.
 * @param {NavigateFunction} navigate - The navigation function to change the URL.
 */
const renderModal = (modalType: string, search: string, navigate: NavigateFunction) => {
  // Add the modal type as a query parameter to the current search string
  const newQueryString: string = addQueryParam(search, { 'modal': modalType });

  // Navigate to the new URL with updated search parameters
  navigate({ search: newQueryString });
};

/**
 * Generates an array of footer button props for rendering the footer buttons.
 * @returns {ButtonCombinedProps[]} Array of footer button props for rendering the footer buttons.
 */
export const getFooterButtons = (params: {
  /** If true then function returns empty array. */
  shouldExcludeFooterButtons: boolean;
  /** Light or dark theme. */
  theme: FooterTheme;
  /** The current search string from the URL. */
  search: string;
  /** The navigation function to change the URL. */
  navigate: NavigateFunction;
}): ButtonCombinedProps[] => {
  const { shouldExcludeFooterButtons, theme, search, navigate } = params;

  if (shouldExcludeFooterButtons) {
    return [];
  }

  // Determine the text color based on the footer theme
  const textColour: TextColourEnum = FooterButtonTextColoursByFooterTheme[theme];

  // Common properties for all buttons
  const commonButtonProps: Partial<ButtonCombinedProps> = {
    type: 'Text',
    style: 'TextWhite',
    size: 'Small',
  };

  return [
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.help'),
        colour: textColour,
      },
      href: '/help',
    },
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.legal'),
        colour: textColour,
      },
      onClick: () => renderModal(TERMS_MODAL, search, navigate),
    },
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.privacy'),
        colour: textColour,
      },
      href: PRIVACY_URL,
      target: '_blank',
    },
  ];
};
