import { useCallback, useContext, useEffect, useMemo, useRef, useState, type ChangeEvent, type FocusEvent } from 'react';
import type { ListingDetailMetadata } from '../../../../lib/types';
import { getFiveDigitsZipCode, getTwoDigitsCountryCode, handleCountryCodeUS } from '../../../../lib/util';
import { AuthContext } from '../../../../modules/auth';
import type { IconAssetEnum } from '../../../atoms/Icon';
import type { TextDropdownOption } from '../../../molecules/TextDropdown';
import { CountryOptions, DefaultShippingAddressChanges, DefaultShippingAddressErrors, NON_US_POSTAL_CODE_MAX_LENGTH, StateProvinceOptionsByCountryCode, US_POSTAL_CODE_MAX_LENGTH, type BillingAddress, type CountryCode } from '../../../organisms/AddressInfo';
import { INVALID_INTEGER_CHARS_REGEX } from '../CheckoutPage.constants';
import type { CheckoutAddressInfoProps } from '../CheckoutPage.types';
import { checkIsPOBoxZipCode, checkIsShippingAddressFieldValid } from '../CheckoutPage.utils';

export const useCheckoutAddressInfo = (params: {
  listingDetailMetadata: ListingDetailMetadata | undefined;
  currentPhoneNumber: string;
}): CheckoutAddressInfoProps => {
  const {
    listingDetailMetadata: {
      isShippingAddressRequired = false,
    } = {},
    currentPhoneNumber,
  } = params;

  // Access account details from the AuthContext
  const { account } = useContext(AuthContext);

  /** Billing address that is set from account details. Missing required fields are set to empty strings. */
  const billingAddress: BillingAddress = useMemo(
    () => ({
      firstName: account?.first_name || '',
      lastName: account?.last_name || '',
      streetAddress: account?.address?.address_line1 || '',
      streetAddressLine2: account?.address?.address_line2 || '',
      city: account?.address?.city || '',
      state: account?.address?.state_code || '',
      postalCode: getFiveDigitsZipCode({ countryCode: account?.address?.country_code || '', postalCode: account?.address?.postal_code || '' }),
      country: getTwoDigitsCountryCode(account?.address?.country_code || ''),
      phoneNumber: currentPhoneNumber,
    }),
    [account, currentPhoneNumber],
  );

  /** State to track whether the shipping address is the same as the billing address */
  const [isSameAsBillingAddress, setIsSameAsBillingAddress] = useState<boolean>(false);

  /** Icon asset for "Same as billing address" checkbox */
  const sameAsBillingAddressCheckboxIconAsset: IconAssetEnum = useMemo(
    () => isSameAsBillingAddress ? 'CheckboxFilled' : 'CheckboxEmpty',
    [isSameAsBillingAddress],
  );

  /**
   * Toggles whether the shipping address should be the same as the billing address.
   * If enabled, it copies billing address details to the shipping address state and resets all shipping address changes and errors.
   */
  const toggleIsSameAsBillingAddress = useCallback(() => {
    setIsSameAsBillingAddress((prevIsSameAsBillingAddress: boolean) => !prevIsSameAsBillingAddress);
  }, []);

  /** Shipping address that can be updated via changes to input fields or dropdowns */
  const [shippingAddress, setShippingAddress] = useState<BillingAddress>(() => ({
    firstName: '',
    lastName: '',
    streetAddress: '',
    streetAddressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
  }));

  /** Shipping address errors as an object where key is address field name and value is true if input is invalid */
  const [shippingAddressErrors, setShippingAddressErrors] = useState<Record<keyof BillingAddress, boolean>>(DefaultShippingAddressErrors);

  /** State for tracking changes to shipping address. An object where key is address field name and value is true if input has been changed. */
  const [shippingAddressChanges, setShippingAddressChanges] = useState<Record<keyof BillingAddress, boolean>>(DefaultShippingAddressChanges);

  /** True if shipping address is invalid */
  const isInvalidShippingAddressError: boolean = useMemo(
    () => Object.values(shippingAddressErrors).some((isError: boolean) => isError),
    [shippingAddressErrors],
  );

  /** True if shipping address postal code is one of PO box postal codes */
  const [isInvalidPostalCodeError, setIsInvalidPostalCodeError] = useState<boolean>(false);

  // Copy billing address details to the shipping address state and reset all shipping address changes and errors
  useEffect(() => {
    if (isSameAsBillingAddress) {
      // Need to remove non-numeric characters from the phone number
      setShippingAddress({ ...billingAddress, phoneNumber: billingAddress.phoneNumber.replace(INVALID_INTEGER_CHARS_REGEX, '') });
      setShippingAddressErrors(DefaultShippingAddressErrors);
      setShippingAddressChanges(DefaultShippingAddressChanges);
    }
  }, [isSameAsBillingAddress, billingAddress]);

  /** Array of TextDropdown options to render countries */
  const countryOptions: TextDropdownOption[] = CountryOptions;

  /** Array of TextDropdown options to render states/provinces */
  const stateProvinceOptions: TextDropdownOption[] = useMemo(
    () => StateProvinceOptionsByCountryCode[shippingAddress.country as CountryCode] ?? [],
    [shippingAddress.country],
  );

  // If country changes then the list of states/provinces will be updated.
  // If state/province is selected but it is no longer present in the list of states/provinces then reset it.
  useEffect(() => {
    if (shippingAddress.state && !stateProvinceOptions.some(({ value }) => value === shippingAddress.state)) {
      setShippingAddress((prevShippingAddress: BillingAddress) => ({ ...prevShippingAddress, state: '' }));
      setShippingAddressErrors((prevShippingAddressErrors: Record<keyof BillingAddress, boolean>) => ({ ...prevShippingAddressErrors, state: false }));
      setShippingAddressChanges((prevShippingAddressChanges: Record<keyof BillingAddress, boolean>) => ({ ...prevShippingAddressChanges, state: false }));
    }
  }, [stateProvinceOptions, shippingAddress.state]);

  /** Handles change event for shipping address fields */
  const onShippingAddressChanged = useCallback((event: ChangeEvent<{ value: string; name?: string; }>) => {
    const fieldName = event.target.name as (keyof BillingAddress) | undefined;
    if (fieldName) {
      // Update shipping address field that corresponds to the input field name
      setShippingAddress((prevShippingAddress: BillingAddress) => ({
        ...prevShippingAddress,
        [fieldName]: event.target.value,
      }));

      // Reset error for the shipping address field
      setShippingAddressErrors((prevShippingAddressErrors: Record<keyof BillingAddress, boolean>) => ({
        ...prevShippingAddressErrors,
        [fieldName]: false,
      }));

      // Mark shipping address field as changed
      setShippingAddressChanges((prevShippingAddressChanges: Record<keyof BillingAddress, boolean>) => ({
        ...prevShippingAddressChanges,
        [fieldName]: true,
      }));

      // Uncheck the "Same as billing address" checkbox whenever any shipping address field changes
      setIsSameAsBillingAddress(false);
    }
  }, []);

  const validateShippingAddressField = useCallback((newParams: {
    fieldName: keyof BillingAddress;
    shouldOverrideIsChangedCheck: boolean;
  }): boolean => {
    const { fieldName, shouldOverrideIsChangedCheck } = newParams;

    // Proceed with validation only if the field has been updated by user or an override flag is provided.
    // Override flag is provided when user clicks on Continue button to enforce validation.
    if (shouldOverrideIsChangedCheck || shippingAddressChanges[fieldName]) {
      const isShippingAddressFieldValid: boolean = checkIsShippingAddressFieldValid({ fieldName, shippingAddress });

      // Set error for the shipping address field if field value is invalid
      setShippingAddressErrors((prevShippingAddressErrors: Record<keyof BillingAddress, boolean>) => ({
        ...prevShippingAddressErrors,
        [fieldName]: !isShippingAddressFieldValid,
      }));

      // Mark shipping address field as changed
      if (!shippingAddressChanges[fieldName]) {
        setShippingAddressChanges((prevShippingAddressChanges: Record<keyof BillingAddress, boolean>) => ({
          ...prevShippingAddressChanges,
          [fieldName]: true,
        }));
      }

      // Show error if shipping address postal code is one of PO box postal codes
      if (fieldName === 'postalCode') {
        const isPOBoxZipCode: boolean = checkIsPOBoxZipCode(shippingAddress.postalCode);
        setIsInvalidPostalCodeError(isPOBoxZipCode);
        return isShippingAddressFieldValid && !isPOBoxZipCode;
      }

      // If country is changed and postal code has been previously modified then re-validate postal code as well.
      // The length of the postal code depends on the country.
      if (fieldName === 'country' && !!shippingAddressChanges.postalCode) {
        return validateShippingAddressField({ fieldName: 'postalCode', shouldOverrideIsChangedCheck: false }) && isShippingAddressFieldValid;
      }

      return isShippingAddressFieldValid;
    }

    return true;
  }, [shippingAddressChanges, shippingAddress]);

  /**
   * Flag to enable validation of shipping address fields only when Continue button is clicked at least once.
   * After that validation of shipping address fields is performed on blur event.
   */
  const isShippingAddressValidationEnabledRef = useRef<boolean>(false);

  /** Handles blur event for shipping address fields */
  const onShippingAddressBlurred = useCallback((event: FocusEvent<{ value: string; name?: string; }>) => {
    // Validation of shipping address fields is enabled only when Continue button is clicked at least once
    if (!isShippingAddressValidationEnabledRef.current) {
      return;
    }

    const fieldName = event.target.name as (keyof BillingAddress) | undefined;
    if (fieldName) {
      // Proceed with validation only if the field has been updated by user
      validateShippingAddressField({ fieldName, shouldOverrideIsChangedCheck: false });
    }
  }, [validateShippingAddressField]);

  /** Max length of the shipping address postal code. 5 for US, 6 for all other countries. */
  const postalCodeMaxLength = useMemo(
    () => handleCountryCodeUS(shippingAddress.country) ? US_POSTAL_CODE_MAX_LENGTH : NON_US_POSTAL_CODE_MAX_LENGTH,
    [shippingAddress.country],
  );

  /** Function to check if the address information is valid */
  const checkIsAddressInfoValid = useCallback(() => {
    // Enable validation of shipping address fields only when Continue button is clicked at least once
    isShippingAddressValidationEnabledRef.current = true;

    let isAddressInfoValid: boolean = true;

    if (isShippingAddressRequired) {
      for (const fieldName of Object.keys(shippingAddress)) {
        // Override flag is provided when user clicks on Continue button to enforce validation.
        isAddressInfoValid = validateShippingAddressField({ fieldName: fieldName as keyof BillingAddress, shouldOverrideIsChangedCheck: true }) && isAddressInfoValid;
      }
    }

    return isAddressInfoValid;
  }, [isShippingAddressRequired, shippingAddress, validateShippingAddressField]);

  return {
    billingAddress,
    isShippingAddressRequired,
    isSameAsBillingAddress,
    sameAsBillingAddressCheckboxIconAsset,
    toggleIsSameAsBillingAddress,
    shippingAddress,
    countryOptions,
    stateProvinceOptions,
    onShippingAddressChanged,
    onShippingAddressBlurred,
    isInvalidShippingAddressError,
    isInvalidPostalCodeError,
    shippingAddressErrors,
    postalCodeMaxLength,
    checkIsAddressInfoValid,
  };
};
