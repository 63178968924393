import { useMemo, useState } from 'react';
import type { OverlayProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import type { ToolTipPresenterProps, ToolTipProps } from './ToolTip.types';
import { getPopperConfig } from './ToolTip.utils';

export const usePresenter = (props: ToolTipProps): ToolTipPresenterProps => {
  const {
    textKey,
    ariaLabelKey,
    placement = 'top',
    theme = 'dark',
  } = props;

  const [toolTipId] = useState<string>(() => uuidv4());

  const { t } = useTranslation();

  const text: string = t(textKey);
  const ariaLabel: string = t(ariaLabelKey);

  const popperConfig: OverlayProps['popperConfig'] = useMemo(() => getPopperConfig(), []);

  return {
    ...props,
    toolTipId,
    text,
    ariaLabel,
    placement,
    popperConfig,
    theme,
  };
};
