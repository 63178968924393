import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { useModalState } from '../../../modules/modals';
import { TopNavContext } from '../../../modules/topNav';
import type { EventInfoHeaderPresenterProps, EventInfoHeaderProps } from './EventInfoHeader.types';

export const usePresenter = (props: EventInfoHeaderProps): EventInfoHeaderPresenterProps => {
  const navigate = useNavigate();

  const { pathHistory } = useContext(TopNavContext);

  const { trackEvent } = useAnalyticsManager();

  const { t } = useTranslation();

  const {
    isModalOpen: isInfoModalShown,
    openModal: onInfoButtonClick,
    closeModal: closeInfoModal,
  } = useModalState();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('common.goBack'),
    );

    // Previous path will be defined if user has visited at least one more page in the application prior to the current page
    // In this case go back
    // Otherwise, navigate to the home page
    if (pathHistory?.previousPath) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [t, pathHistory?.previousPath, trackEvent, navigate]);

  return {
    ...props,
    isInfoModalShown,
    onInfoButtonClick,
    closeInfoModal,
    onBackButtonClick,
  };
};
