import { useCallback, useMemo } from 'react';
import { useQueryParamFromUrl } from '../../../lib/util';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { getMultiToggleStateFromUrl } from './MultiToggle.utils';

export const useMultiToggleStateFromUrl = <TId extends string | number>(params: {
  /** Query parameter in the URL that will be used for managing the state of the multi toggle component */
  queryParamName: string;
  /** Array of allowed multi toggle option Ids */
  multiToggleOptionIds: readonly TId[];
}): TId | undefined => {
  const { queryParamName, multiToggleOptionIds } = params;

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const clearMultiToggleParamInUrl = useCallback(() => {
    appendQueryParamsToUrl({ [queryParamName]: '' }, { replace: true });
  }, [queryParamName, appendQueryParamsToUrl]);

  const multiToggleParam: string | undefined = useQueryParamFromUrl(queryParamName);

  const multiToggleStateFromUrl: TId | undefined = useMemo(
    () => getMultiToggleStateFromUrl({ multiToggleOptionIds, multiToggleParam, clearMultiToggleParamInUrl }),
    [multiToggleOptionIds, multiToggleParam, clearMultiToggleParamInUrl],
  );

  return multiToggleStateFromUrl;
};
